import React, { Component } from "react";
import axios from "axios"
import { ApiList } from '../../DataOperation/apilink'
import { MetaClient } from "../../ibs-meta-data/FieldNames";
import "./ContactDetailComCSS.css"
import ComboboxV1 from '../../component/combobox/ComboboxV1'
import '../policy/policybasic.css'
import {TitleList} from '../../ibs-constant/TitleList'


class ContactDetailComp extends Component {
    constructor() {
        super()
        this.state = {
            occupationData: [],
          //  occupation_id:0

        }
        //   this.autoCompleteHandleChange=this.autoCompleteHandleChange().bind(this);
        //  this.setInputValuesData= this.setInputValuesData.bind(this);

    }

    componentDidMount() {
        this.getDataById();
        this.getInsurerList();
    }





    getInsurerList() {
        axios.post(ApiList.md_policy_md, {
            user_id: 1
        })
            .then((response) => {
                this.setState({
                   
                    occupationData: response.data.occupation,
                  

                }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });
    }
    getDataById() {

        //  console.log(ApiList.pd_md_nic_list);
        axios.post(ApiList.pd_md_nic_list, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1
        })
            .then((response) => {



                let array_nic_list = [];

          //      response.data.nic_list.map(x => {

           //         console.log(array_nic_list.push(x.insured_nic_no));
           //     });


                console.log(array_nic_list);
                this.setState({

                    nic_list: array_nic_list


                }, () => {
                    // console.log("load data ", this.state)

                })
            }, (error) => {
                console.log(error);
            });

    }


    setInputValuesData(_param_id) {

        //  console.log(ApiList.pd_md_nic_list);
        axios.post(ApiList.pd_md_insured_by_id, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1,
            param_id: _param_id
        })
            .then((response) => {

                console.log("response", response);


            }, (error) => {
                console.log(error);
            });

    }




    render() {

        const policy_details_data = {          
            occupation_id: this.state.occupation_id     
        }
        const titleData =TitleList;
       
        const yesNoData = [
            { value: '0', label: 'No' },
            { value: '1', label: 'Yes' }
        ]
        //   const titleData1 = [ 'Mr',  'Mrs',  'Miss' ]
        //pd_md_nic_list
        const isIndividual = (this.props.insuredType == "individual");

        return (
            <div> <div className="css_section_title">  {this.props.legend_title} </div>
                <div className="cls-np-main-container">

                    <table>
                        <tbody>
                            <tr style={{ display: isIndividual ? 'none' : '' }}>
                                <td>Business Reg.No </td>
                                <td><input className="ibsinput uppercase" name={'business_reg_no'}
                                    onChange={(event) => this.props.hadlechangeUD(event, 'business_reg_no')}
                                    value={this.props.data.business_reg_no}
                                    type="text" /></td>
                            </tr>





                            <tr style={{ display: isIndividual ? '' : 'none' }}>


                                <td>{MetaClient.NIC_OR_PASSPORT}</td>
                                <td >
                              
                                  
                                    <div >
                                        <input className="ibsinput" name={this.props.name + '_nic_no'}
                                            onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_nic_no')}
                                            value={this.props.data.nic_no}
                                            required={(isIndividual?true:false)}
                                            type="text" />
                                    </div>
                                </td>

                            </tr>

                            <tr style={{ display: isIndividual ? '' : 'none' }}>
                                <td>Tittle</td>
                                <td>
                                    <ComboboxV1
                                        hadlechangeUD={this.props.hadlechangeUD}

                                        selectedValue={this.props.data.title}
                                        options={titleData}
                                        name={this.props.name + '_title'}
                                    ></ComboboxV1>

                                </td>
                            </tr>
                            <tr style={{ display: isIndividual ? '' : 'none' }}>
                                <td>Initials </td>
                                <td><input className="ibsinput  uppercase" name={this.props.name + '_initials'}
                                    onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_initials')}
                                    value={this.props.data.initials}
                                    required={(isIndividual?true:false)}
                                    type="text" /></td>
                            </tr>
                            <tr style={{ display: this.props.name == 'insured' ? 'contents' : 'none' }}>
                                <td>
                                    {isIndividual ? 'Surname' : 'Company Name'}
                                </td>
                                <td><input className="ibsinput  uppercase" name={this.props.name + '_surname'}
                                    onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_surname')}
                                    value={this.props.data.surname}
                                    required={true}
                                    type="text" /></td>
                            </tr>






                            <tr style={{ display: this.props.name != 'insured' ? 'contents' : 'none' }}  >
                                <td >Name</td>
                                <td><input className="ibsinput  uppercase" name={this.props.name + '_name'}
                                    onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_name')}
                                    value={this.props.data.name}
                                    type="text" /></td>
                            </tr>

                            <tr>
                                <td>Address</td>
                                <td><input className="ibsinput  uppercase" name={this.props.name + '_address_no'} onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_address_no')}
                                    type="text"
                                    required={true}
                                    value={this.props.data.address_no}
                                    placeholder="No" /></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><input className="ibsinput  uppercase" name={this.props.name + '_address_road'}
                                    value={this.props.data.address_road}
                                    required={true}
                                    type="text" placeholder="Road" onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_address_road')} /></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><input name={this.props.name + '_address_city'} className="ibsinput  uppercase"
                                    type="text"
                                    required={true}
                                    placeholder="City"
                                    
                                    value={this.props.data.address_city}
                                    onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_address_city')} />
                                </td>
                            </tr>

                            <tr>
                                    <td>
                                        Occupation
            </td>
                                    <td >
                                        <ComboboxV1
                                            hadlechangeUD={this.props.hadlechangeUD}
                                            options={this.state.occupationData}
                                            selectedValue={this.props.data.occupation_id}
                                            name='occupation_id'
                                        ></ComboboxV1>
                                    </td>
                                </tr>

                            <tr>
                                <td>Email</td>
                                <td><input className="ibsinput"
                                    name={this.props.name + '_email'}
                                  
                                 
                                    onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_email')}
                                    value={this.props.data.email}
                                    type="text"/></td>
                            </tr>
                            <tr>
                                <td>Mobile No</td>
                                <td><input className="ibsinput" name={this.props.name + '_contact_no'}
                                  required={ isIndividual ? true : '' }
                                    value={this.props.data.contact_no}
                                    onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_contact_no')}
                                    type="text" /></td>
                            </tr>


                            <tr style={{ display: isIndividual ? 'none' : '' }}>
                                <td>SVAT Registered</td>
                                <td>
                                <ComboboxV1
                                    hadlechangeUD={this.props.hadlechangeUD}

                                    selectedValue={this.props.data.is_svat_registered}
                                    options={yesNoData}
                                    name={'is_svat_registered'}
                                ></ComboboxV1>

                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }


}

export default ContactDetailComp