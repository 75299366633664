import React, { useState, useEffect } from "react";
import CheckboxIndex from "../../../component/CheckBox/CheckBoxIndex";
import CoverDataService from "../../../DataApiService/CoverDataService";

export default function ({setCoverList,coverListData}) {
    const [dataSource, setDataSource] = useState([]);  
    const coverService = new CoverDataService();


    useEffect(() => {
        coverService.getCoverList({ insuredType: 'insuredType' }).then(data => {  setDataSource(data); });
      
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (<div>  
             
        <CheckboxIndex dataSource={dataSource}   coverListData={coverListData}   setCoverList={setCoverList}></CheckboxIndex>
    </div>
    );
}