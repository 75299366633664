import React, { Component } from 'react'
import { MenuItems } from "../../../MenuData/MenuItemsPolicyDetails"
import { MenuItemsNewPolicy } from "../../../MenuData/MenuItemsPolicyDetails"
import { PolicySearchMenuItems } from "../../../MenuData/MenuItemsPolicyDetails"
import ButtonNavigator from "../../../component/TabController/ButtonNavigator"
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import PolicySearch from '../PolicySearchPopup'
import PolicySearchPopupTransaction from '../PolicySearchPopupTransaction'

class PolicyDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      policy_class_type: 0,
      policy_action: ''
    }
    this.handleModal = this.handleModal.bind(this);
  }


  handleModal(itemtittle, policy_action) {
    this.setState({ show: !this.state.show, policy_class_type: itemtittle, policy_action: policy_action })
  }

  render() {
    return (
      <div>
        <ButtonNavigator headerName="Search Policy" policy_action="View" handleModal={this.handleModal} MenuItems={PolicySearchMenuItems}></ButtonNavigator>
        <ButtonNavigator headerName="New Policy" policy_action="New" MenuItems={MenuItemsNewPolicy}></ButtonNavigator>
        <ButtonNavigator headerName="Renewal" policy_action="Renewal" handleModal={this.handleModal} MenuItems={MenuItems}></ButtonNavigator>
        <ButtonNavigator headerName="Endorsement" policy_action="Endorsement" handleModal={this.handleModal} MenuItems={MenuItems}></ButtonNavigator>
        <ButtonNavigator headerName="Edit Policy" policy_action="Edit" handleModal={this.handleModal} MenuItems={MenuItems}></ButtonNavigator>
        <Modal show={this.state.show} onHide={() => this.handleModal('hide')} >
          <Modal.Header closeButton>Policy Details</Modal.Header>
          <Modal.Body>
            {(this.state.policy_class_type == "Transaction") ?
              <PolicySearchPopupTransaction policy_class_type={this.state.policy_class_type} policy_action={this.state.policy_action}></PolicySearchPopupTransaction> :
              <PolicySearch policy_class_type={this.state.policy_class_type} policy_action={this.state.policy_action}></PolicySearch>
            }
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col>  <Button onClick={() => this.handleModal("Save")}>Ok</Button>   </Col>
              <Col></Col>
              <Col> <Button onClick={() => this.handleModal("Close")}>Cancel</Button>   </Col>
            </Row>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}


export default PolicyDetails;