export const MenuItems = [
    {
        tittle: 'General',
        url: '',
        cName: 'nav-links bar-alert',
        color:'primary'

    },
    {
        tittle: 'Life',
        url: '',
        cName: 'nav-links bar-alert',
        color:'secondary'

    }

]

export const PolicySearchMenuItems = [
    {
        tittle: 'General',
        url: '',
        cName: 'nav-links bar-alert',
        color:'primary'

    },
    {
        tittle: 'Life',
        url: '',
        cName: 'nav-links bar-alert',
        color:'secondary'

    },
    {
        tittle: 'Transaction',
        url: '',
        cName: 'nav-links bar-alert',
        color:'secondary'

    }

]

export const MenuItemsNewPolicy = [
    {
        tittle: 'General',
        url: '/policy/new-policy/general',
        cName: 'nav-links bar-alert',
        color:'primary'

    },
    {
        tittle: 'Life',
        url: '/policy/new-policy/life',
        cName: 'nav-links bar-alert',
        color:'secondary'

    }

]

export const MenuItemsCommissionUpdate = [

    {
        tittle: 'Upload Commission Data',
        url: '/commission/commission-in/upload-commission-received',
        cName: 'nav-links bar-alert',
        color:'primary'


    },


    {
        tittle: 'General - Individual Update',
        url: '',
        cName: 'nav-links bar-alert',
        color:'primary',
        parameter:'general'

    },
    {
        tittle: 'Life - Individual Update',
        url: '',
        cName: 'nav-links bar-alert',
        color:'secondary',
        parameter:'individual'

    }

]


