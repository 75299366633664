import React, { Component, useState } from "react"
import axios from "axios"
import { ApiList } from "../../../DataOperation/apilink"
import ComboboxV1 from "../../../component/combobox/ComboboxV1"
import Navbar from "../../../component/Navbar/NavbarMasterData"


const titleData = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Miss', label: 'Miss' }
]

class EditAgentService extends Component {


    constructor(props) {
        super(props)
        this.state = { agent_service_name: '', agent_service_title: '', validate_message: '' }
        this.onChange = this.onChange.bind(this);

    }
    onChange(e, val) {
        let i_id = this.props.match.params.id;
        if (e.target != null && e.target.name) {
            this.setState({
                [e.target.name]: e.target.value,
                validate_message: (e.target.value.length > 0 ? '' : '*'),
                user_id: 1,
                pr_key: i_id
            })
        } else {
            //   this.calculateCommissionBrakeDown(e,val)
            this.setState({
                [val]: e, validate_message: (val.length > 0 ? '' : '*'),
                user_id: 1,
                pr_key: i_id
            })
        }
        console.log(this.state);



    }


    componentWillMount() {
        this.getDataById();
    }
    getDataById() {
        let i_id = this.props.match.params.id;
        axios.post(ApiList.pd_agent_service_data_by_id, {
            user_id: 1,
            pr_key: i_id
        })
            .then((response) => {

                console.log(response);
                this.setState({
                    agent_service_title: response.data.data[0].agent_service_title,
                    agent_service_name: response.data.data[0].agent_service_name,

                }, () => {
                    console.log("load data ", this.state)
                })
            }, (error) => {
                console.log(error);
            });

    }

    addItem(newItem, e) {
        axios.post(ApiList.pd_agent_service_update, newItem)
            .then((response) => {


                if (response.data.data != undefined) {
                    e.target.reset();
                    console.log("update return value", response.data);
                    this.props.history.push('/master-data/md-agent-service');

                } else {
                    this.setState({
                        validate_message: response.data.error
                    })
                }


            }, (error) => {
                console.log('error', error);
            });



    }



    onSubmit(e) {

        if (this.state.agent_service_title.length > 0 && this.state.agent_service_name.length > 0) {
            const newItem = this.state;
            console.log(newItem);
            this.addItem(newItem, e);

        }
        else {
            this.setState({

                validate_message: '*'
            })

        }


        e.preventDefault();

    }


    render() {
       

        return (
            <div>
                <Navbar></Navbar>
                <form onSubmit={this.onSubmit.bind(this)}>
                    <li className="list-group-item">
                        <div className="container">
                            <div className="row">
                                <div className="master-data-page-tittle"> <span>Add New {this.props.master_page_tittle}</span></div>
                            </div>
                            <div className="master-data-page-body">
                                <div className="row">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td> <label ></label></td>
                                                <td> <label > Tittle</label></td>
                                                <td>
                                                  
                                                    <ComboboxV1
                                                        hadlechangeUD={this.onChange}
                                                      
                                                        options={titleData}
                                                        name="agent_service_title"
                                                        selectedValue={this.state.agent_service_title}
                                                    ></ComboboxV1>
                                                </td>
                                                <td> <label >Name</label></td>
                                                <td>     <input
                                                    type="text" onChange={this.onChange}
                                                    name="agent_service_name" ref="agent_service_name"
                                                    placeholder="Name"
                                                    value={this.state.agent_service_name}
                                                    id="txt_agent_service_name"></input>
                                                </td>
                                                <td>    <button type="submit" value="save" className="btn btn-info">Update</button></td>
                                                <td> <label ></label></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>    <div className="row">
                                    <div col="col">
                                        <spa>{this.state.validate_message}</spa>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </li>
                </form>
            </div>
        )

    }



}

export default EditAgentService