export const MenuItems = [
    {
        tittle: 'Individual',
        url: '/insured/individual',
        cName: 'nav-links bar-alert',
        color:'primary'

    },
    {
        tittle: 'Cooperate',
        url: '/insured/cooperate',
        cName: 'nav-links bar-alert',
        color:'secondary'

    }

]

export const MenuItemsClientList = [
    {
        tittle: 'Individual',
        url: '/insured-list/individual',
        cName: 'nav-links bar-alert',
        color:'primary'

    },
    {
        tittle: 'Cooperate',
        url: '/insured-list/cooperate',
        cName: 'nav-links bar-alert',
        color:'secondary'

    }

]

