import React from "react"
import Navbar from "../../component/Navbar/Navbar"
import '../../App.css';
import { MenuItems } from "../../component/Navbar/MenuItemsReport";
import TileComponentPro from "../../component/TileNavigator/TileComponentPro"

const  MainReport=()=> {

  return (  <div className="App">
     <Navbar/>

    
<div className="report-main-board">
  <center>
  <h2>Reports</h2>

<TileComponentPro TileSource={MenuItems}></TileComponentPro>


     </center>
     </div>
     </div>
  );

}
export default MainReport;
