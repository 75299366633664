
import React, { Component } from "react"
import NumericTextBox from "react-numeric-textbox"
import RateTextBox from "../RateTextBox/RateTextBox";




class CommissionBrakeDown extends Component {
    constructor(props) {
        super(props)   

        this.state={
            MaxCOMBR_Commision:100,
            MaxCOMSRCC_Commision:100,
            MaxCOMTR_Commision:100
        
        }
    }


    componentWillReceiveProps(nextProps) {

        let  MaxCOMBR_Commision=100
        let  MaxCOMSRCC_Commision=100
        let  MaxCOMTR_Commision=100

if(nextProps.COMBR!=null && nextProps.COMBR.length>0){
    MaxCOMBR_Commision=Math.max.apply(Math, nextProps.COMBR.map(function(o) { return o.value; }));

}
if(nextProps.COMSR!=null && nextProps.COMSR.length>0){
    MaxCOMSRCC_Commision=Math.max.apply(Math, nextProps.COMSR.map(function(o) { return o.value; }));

}
if(nextProps.COMTR!=null && nextProps.COMTR.length>0){
    MaxCOMTR_Commision=Math.max.apply(Math, nextProps.COMTR.map(function(o) { return o.value; }));

}



        this.setState({
            MaxCOMBR_Commision: eval(MaxCOMBR_Commision) ,
            MaxCOMSRCC_Commision: eval(MaxCOMSRCC_Commision) ,
            MaxCOMTR_Commision: eval(MaxCOMTR_Commision) ,
           
        });
    } 

  
    calculatePremiumCommission(premium_basic, premium_basic_rate) {
        return (premium_basic * premium_basic_rate);
    }
 
   


    render() {
        const IsLife = (this.props.insuranceClassType == "L")

        return (
            <div className="cls-np-main-container">
                {this.props.tittle} (%)

                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="div_label">   Basic</div>                                   
                                   

                                </td>
                                <td>
                                  
                                    <RateTextBox
                                        MaxValue={this.state.MaxCOMBR_Commision}
                                        RateBaseLimit= {this.props.premium_basic_rate_comin}
                                        textBoxValue=  {this.props.premium_basic_rate}
                                        hadlechangeUD={this.props.hadlechangeUD}
                                        getInputValidation={this.props.getInputValidation}
                                        Name={this.props.name + '_comm_rate_br_basic'}
                                    ></RateTextBox>
                           
                                </td>
                                <td>

                                    <NumericTextBox

                                        value={this.calculatePremiumCommission(this.props.premium_basic, this.props.premium_basic_rate)}
                                        disabled={true}
                                        className="input-numeric ibsinput"
                                        name={this.props.name + "_br_basic"}
                                        onChange={(event) => this.props.hadlechangeUD(event, this.props.name + "_br_basic")}


                                    >
                                    </NumericTextBox>
                                </td>


                            </tr>
                            <tr style={{ display: ((IsLife) ? 'none' : '') }}>
                                <td>
                                    S.R.C.C
                                </td>
                                <td>
                     

<RateTextBox
                                        MaxValue={this.state.MaxCOMSRCC_Commision}
                                        getInputValidation={this.props.getInputValidation}
                                        RateBaseLimit= {this.props.premium_srcc_rate_comin}
                                        textBoxValue=  {this.props.premium_srcc_rate}
                                        hadlechangeUD={this.props.hadlechangeUD}
                                        Name={this.props.name + '_comm_rate_br_srcc'}
                                    ></RateTextBox>

                                </td>
                                <td>
                                    <NumericTextBox
                                        disabled={true}
                                        className="input-numeric ibsinput"
                                        name={this.props.name + "_br_srcc"}
                                        value={this.calculatePremiumCommission(this.props.premium_srcc, this.props.premium_srcc_rate)}
                                        onChange={(event) => this.props.hadlechangeUD(event, this.props.name + "_br_srcc")}
                                    >
                                    </NumericTextBox>
                                </td>

                            </tr>
                            <tr style={{ display: ((IsLife) ? 'none' : '') }}>
                                <td>
                                    Terrorisam
                                </td>
                                <td>
                             <RateTextBox
                                         MaxValue={this.state.MaxCOMTR_Commision}
                                        getInputValidation={this.props.getInputValidation}
                                        textBoxValue=  {this.props.premium_terror_rate}
                                    RateBaseLimit=  {this.props.premium_terror_rate_comin}
                                        hadlechangeUD={this.props.hadlechangeUD}
                                        Name={this.props.name + '_comm_rate_br_terr'}
                                    ></RateTextBox>

                                </td>
                                <td>
                                    <NumericTextBox
                                        disabled={true}
                                        className="input-numeric ibsinput"
                                        name={this.props.name + "_br_terr"}
                                        value={this.calculatePremiumCommission(this.props.premium_terror, this.props.premium_terror_rate)}

                                        onChange={(event) => this.props.hadlechangeUD(event, this.props.name + "_br_terr")}>
                                    </NumericTextBox>
                                </td>

                            </tr>


                            <tr>
                                <td>

                                </td>
                                <td>
                                    Total
                                </td>
                                <td>
                                    <NumericTextBox
                                        className="input-numeric ibsinput"
                                        value={this.props.premium_commission_total}
                                        disabled={true}
                                        name={this.props.name + "_br_total"}
                                        onChange={(event) => this.props.hadlechangeUD(event, this.props.name + "_br_total")}
                                    >
                                    </NumericTextBox>
                                </td>

                            </tr>
                        </tbody>
                    </table>



                </div>

            </div>
        )
    }
}

export default CommissionBrakeDown