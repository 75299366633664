import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from '@material-ui/core';
import NumericTextBox from "react-numeric-textbox"

class PolicyTransactionList extends Component {
    constructor(props) {
        super(props)
        this.state = { Total: this.props.TotalAmount, rt: 0 }
        this.buttonHadleChange = this.buttonHadleChange.bind(this);

    }
    buttonHadleChange(rowData, s, e) {
        let array = [];
        array = this.props.entry_list;
        let t = 0;
        for (let index = 0; index < array.length; index++) {
            const element = array[index].PaidAmount;
            t += element;
        }
        this.props.myHandleChange(rowData, s, e, (e ? 0 : 0));
    }


    render() {




        const selectionTemplate = (rowData) => {
            return (
                <div>

                    <div style={{ color: 'blue', cursor: 'pointer' }} >
                        <Checkbox
                        disabled={rowData.PaidAmount==0}
                            checked={rowData.IsSelected == true}
                            onChange={(s, e) => {
                                this.buttonHadleChange(rowData, s, e)  
                            }}
                        ></Checkbox>
                    </div>
                </div>);
        }
        const paymentAmountTemplate = (rowData) => {
            return (
                <div >
                    <NumericTextBox 
                    disabled={rowData.PaidAmount==0}
                     className="input-numeric ibsinput"
                        value={rowData.PaidAmount}
                        onChange={(s, e) => this.props.setAmountmyHandleChange(rowData, s)}
                    ></NumericTextBox>

                </div>);
        }



        return (


            <div >
                <div className="card">
                    <DataTable value={this.props.entry_list} paginator rows={4} >
                        <Column header=" " style={{ width: '10em' }} body={selectionTemplate}></Column>
                        <Column field="entry_type" style={{ width: '12em' }} header="Entry" filter={true} filterMatchMode={'contains'}></Column>
                        <Column field="insurer_invoice" style={{ width: '12em' }} header="Invoice No" filter={true} filterMatchMode={'contains'}></Column>
                        <Column field="ibs_entry_id" style={{ width: '20em' }} header="Entry Id" filter={true} filterMatchMode={'contains'}></Column>
                        <Column field="from_date" style={{ width: '10em' }} header="From" filter={true} filterMatchMode={'contains'}></Column>
                        <Column field="to_date" style={{ width: '10em' }} header="To" filter={true} filterMatchMode={'contains'}></Column>
                        <Column field="premium_received_amount" header="Payment " style={{ width: '10em',textAlign:'right' }} ></Column>
                        <Column field="Premium" header="Premium " style={{ width: '10em',textAlign:'right' }} ></Column>
                        <Column field="paid_amount" header="Due " style={{ width: '10em',textAlign:'right' }} body={paymentAmountTemplate}></Column>


                    </DataTable>
                </div>

            </div>

        )
    }
}
export default PolicyTransactionList