import axios from 'axios';
import {ApiList} from '../../DataOperation/apilink'


export default class ReportService {

    getDebitNoteData(requestdata) {
       // return axios.get( a).then(res => res.data.data);
       return  axios.post(ApiList.rp_print_debit_note, { user_id: 1,branch_code:"HO",debitno:requestdata.debitno}).then(res => res.data);
  
    }

   
}