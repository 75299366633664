export const MenuItems = [
    {
        tittle: 'Policy Class',
        url: '/master-data/policyclass',
        cName: 'nav-links'

    },
    
    {
        tittle: 'Policy Type',
        url: '/master-data/md-policy-type',
        cName: 'nav-links'

    },
    
    {
        tittle: 'Covers',
        url: '/master-data/md-policy-cover',
        cName: 'nav-links'

    },
    {
        tittle: 'Insurer',
        url: '/master-data/insurer',
        cName: 'nav-links'

    },
    {
        tittle: 'Occupation',
        url: '/master-data/md-occupation',
        cName: 'nav-links'

    } 
  
    ,
    {
        tittle: 'Agent',
        url: '/master-data/md-agent',
        cName: 'nav-links'

    }
    ,
    {
        tittle: 'Service By',
        url: '/master-data/md-agent-service',
        cName: 'nav-links'

    },
    {
        tittle: 'Commission Rates',
        url: '/master-data/commission-rate',
        cName: 'nav-links'

    }
  

]