export const MenuItems = [
   {
        tittle: 'Home',
        url: '/dashboard',
        cName: 'nav-links'

    }     
    ,
    {
        tittle: 'Contact Us',
        url: '#',
        cName: 'nav-links'

    }
    ,
    {
        tittle: 'Sing In',
        url: '/login',
        cName: 'nav-links-mobile'

    }


]