import React, { Component, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios'
import { ApiList } from '../../DataOperation/apilink'
import AddAgent from './agent-component/AddAgent'
import Navbar from "../../component/Navbar/NavbarMasterData"
import { Link } from "react-router-dom"
import { Modal, Container, Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import CoverList from './cover/CoverList';

class PolicyClass extends Component {

    constructor(props) {
        super(props)
        this.state = {
            masterdata: [],
            show: false,
            selectedId: 0
        }

        this.handleClose = this.handleClose.bind(this);
    }
    componentWillMount() {
        // console.log('will?')
        this.getMasterDataList();
    }


    setSeletedId(id) {
        this.setState({ selectedId: id });
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    getMasterDataList() {
        axios.post(ApiList.pd_policy_class_data, {
            user_id: 1
        })
            .then((response) => {               
                this.setState({ masterdata: response.data.data }, () => {                   
                })
            }, (error) => {
                console.log(error);
            });

    }

    removeItem(inactive_Insurer) {
        axios.post(ApiList.pd_agent_delete, inactive_Insurer)
            .then((response) => {
                if (response.data.data != undefined) {
                    console.log("update return value", response.data);
                    this.getMasterDataList();
                } else {
                    this.setState({
                        validate_message: response.data.error
                    })
                }
            }, (error) => {
                console.log('error', error);
            });
    }

    removeToCollection(key) {
        const InactiveInsurer = { pr_key: key, user_id: 1 }
        console.log(InactiveInsurer);
        this.removeItem(InactiveInsurer);
    }

    render() {
     
        const statusBodyTemplate = (rowData) => {
            return (
                <div>
                    {/*
                   <Link to={`/${master_page_name.toLowerCase()}/edit/${rowData.CoverId}`}>Edit</Link>
                   */}
                    <Link onClick={() => {
                        this.setSeletedId(rowData.CoverId);
                        this.handleShow();

                    }} >Covers</Link>

                </div>);
        }
      

        const list_table_source = this.state.masterdata;



        return (
            <div>
                <Navbar></Navbar>
                        <div className="container">

                
                </div>


                <div className="container">
                    <ul>
                        <div className="ig-table-container">
                        <div className="card">
                            <DataTable value={list_table_source}>

                                <Column field="value" style={{ width: '10em' }} header="Class Code" filter={true}></Column>
                                <Column field="label" style={{ width: '40em' }}  header="Class Name" filter={true}></Column>
                                <Column field="ins_class_type" style={{ width: '40em' }}  header="Class Type" filter={true}></Column>
                                <Column header=" Covers " style={{ width: '10em' }} body={statusBodyTemplate}></Column>


                            </DataTable>
                        </div>
                        </div>
                    </ul></div>

                    <Modal show={this.state.show} onHide={this.handleClose} dialogClassName="cls_modal_small_pop" >
                    <Modal.Header closeButton>
                        <Modal.Title>Covers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {this.state.selectedId}
                    <CoverList></CoverList>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            </div>

        )
    }
}

export default PolicyClass
/*

   const master_page_name='Agent';


        const statusBodyTemplate = (rowData) => {
            return (
                <div>
                    <Link to={`/${master_page_name.toLowerCase()}/edit/${rowData.agent_id}`}>Edit</Link>
                </div>);
        }

        const statusBodyTemplateView = (rowData) => {
            return (
                <div>
                    <Link to={`/${master_page_name.toLowerCase()}/details/${rowData.agent_id}`}>View</Link>
                </div>);
        }

        const statusBodyTemplateDelete = (rowData) => {
            return (
                <div>
                    <Link onClick={() => { if (window.confirm('Delete the item?')) { this.removeToCollection(rowData.agent_id, this) }; }}>Delete</Link>

                </div>);
        }


                                <Column header=" View"style={{ width: '2em', display: "none" }} body={statusBodyTemplateView}></Column>
                                <Column header=" Edit " style={{ width: '10em' }}  body={statusBodyTemplate}></Column>
                                <Column header=" Delete " style={{ width: '10em' }}  body={statusBodyTemplateDelete}></Column>
*/