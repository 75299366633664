import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios'
import { ApiList } from '../../../DataOperation/apilink'
import Button from '@material-ui/core/Button';
import CircularIndeterminate from '../../../component/ProgressBar/CircularProgressBar'
import '../report.css'

class AgeReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
           from_date:'',to_date:'',isLoadong:false
        }

        this.exportCSV = this.exportCSV.bind(this);

    }

  

    exportCSV(selectionOnly) {
        this.dt.exportCSV({ selectionOnly });
    }

    getMasterDataList() {


        this.setState({isLoadong:true});

        axios.post(ApiList.rp_premium_report_with_policy, {
            user_id: 1,
            from_date:this.state.from_date,
            to_date:this.state.to_date
        })
            .then((response) => {

                console.log("master response", response);
                this.setState({ masterdata: response.data.data }, () => {
                    this.setState({isLoadong:false});
                })
            }, (error) => {
                console.log(error);
                this.setState({isLoadong:false});
            });

    }

   


    render() {




        const list_table_source = this.state.masterdata;



        return (
            <div>

             <div className="report-control">
<CircularIndeterminate
IsLoading={this.state.isLoadong}
></CircularIndeterminate>
                    <div >
                 
                        <table><tr>
                            <td>Date Range</td>
                            <td>  <input
                            style={{ width: '150px', height: '35px' }}
                            type="date"
                            name="period_from"
                           // value={this.props.policydata.period_from}
                           // onChange={this.props.hadlechangeUD}
                           onChange={(e)=>{this.setState({from_date:e.target.value})}}
                           >


                        </input></td>
                            <td>     <input style={{ width: '150px', height: '35px' }} type="date"
                            name="period_to"

                            //value={this.props.policydata.period_to}

                           onChange={(e)=>{this.setState({to_date:e.target.value})}}
                            
                            ></input></td>
                            <td>  
                                 <Button variant="contained" color="primary"  style={{width:"200px"}}
                                 onClick={(e)=>{this.getMasterDataList()}}
                                 
                                 >Submit</Button></td>
                            </tr></table>

                      
                   



                    </div>
                 
                   
                </div>


             
                        <div className="ig-table-container">






                            <div className="card">
                            <Button type="button"  color="primary"   onClick={() => this.exportCSV(false)}  className="tb-export-button" data-pr-tooltip="CSV" >Export to CSV</Button>
                                <DataTable  ref={(el) => { this.dt = el; }}    value={list_table_source} id="id_data_report" tableClassName="tbl_css_data" 
                                exportFilename={`Commission_Report_From_${this.state.from_date}_To_${this.state.to_date}`}
                              >

                                   
                                    <Column field="MONTH" style={{ width: '40em' }} header="MONTH" filter={true}></Column>
                                    <Column field="INSURANCE_COMPANY" style={{ width: '40em' }} header="INSURANCE COMPANY" filter={true}></Column>  
                                    <Column field="CLASS" style={{ width: '40em' }} header="CLASS" filter={true}></Column>
                                    <Column field="POLICY_TYPE" style={{ width: '40em' }} header="POLICY TYPE" filter={true}></Column>
                                    <Column field="INSURED" style={{ width: '80em' }} header="CLIENT" filter={true}></Column>  
                                    <Column field="VEHICLE_NO" style={{ width: '40em' }} header="VEHICLE NO" filter={true}></Column>
                                    <Column field="POLICY_NO" style={{ width: '40em' }} header="POLICY NO" filter={true}></Column>
                                    <Column field="INVOICE_NO" style={{ width: '40em' }} header="DEBIT NOTE NO" filter={true}></Column>
                                    <Column field="Gross_Premium" style={{ width: '20em',textAlign:'right' }} header="Gross Premium" filter={true}></Column>
                                    <Column field="SRCC" style={{ width: '40em',textAlign:'right' }} header="SRCC" filter={true}></Column>
                                    <Column field="TC" style={{ width: '20em' ,textAlign:'right'}} header="TC" filter={true}></Column>
                                    <Column field="TOTAL_BEFORE_TAXES" style={{ width: '20em' ,textAlign:'right'}} header=" TOTAL BEFORE TAXES" filter={true}></Column>
                                    <Column field="AGENT" style={{ width: '80em' }} header="AGENT" filter={true}></Column>  
                                    <Column field="GROSS_BROKER" style={{ width: '20em',textAlign:'right' }} header="Broker Commision Gross Premium" filter={true}></Column>
                                    <Column field="SRCC_BROKER" style={{ width: '40em',textAlign:'right' }} header="Broker Commision SRCC" filter={true}></Column>
                                    <Column field="TERR_BROKER" style={{ width: '20em' ,textAlign:'right'}} header="Broker Commisio TC" filter={true}></Column>
                                    <Column field="BROKER_COMMISSION" style={{ width: '40em',textAlign:'right' }} header="BROKER COMMISSION" filter={true}></Column>


                                    <Column field="GROSS_AGENT" style={{ width: '20em',textAlign:'right' }} header="Agent Commission Gross Premium" filter={true}></Column>
                                    <Column field="SRCC_AGENT" style={{ width: '40em',textAlign:'right' }} header="Agent Commission SRCC" filter={true}></Column>
                                    <Column field="TERR_AGENT" style={{ width: '20em' ,textAlign:'right'}} header="Agent Commission TC" filter={true}></Column>
                                    <Column field="AGENT_COMMISSION" style={{ width: '20em' ,textAlign:'right'}} header="AGENT COMMISSION " filter={true}></Column>
                                    {/*
                                    
                                     <Column field="DATE" style={{ width: '50em' }} header="DATE" filter={true}></Column>

                                                                
                            
                                    <Column field="INSURED" style={{ width: '80em' }} header="INSURED" filter={true}></Column>                                    
                                    <Column field="CONTACT_NUMBER" style={{ width: '40em' }} header="CONTACT NUMBER" filter={true}></Column>                                    
                                    <Column field="CLASS" style={{ width: '40em' }} header="CLASS" filter={true}></Column>
                                    <Column field="SERVICE_BY" style={{ width: '40em' }} header="SERVICE BY" filter={true}></Column>
                                    <Column field="VEHICLE_NO" style={{ width: '40em' }} header="VEHICLE NO" filter={true}></Column>     
                              
                                                 
                                    <Column field="Gross_Premium" style={{ width: '20em',textAlign:'right' }} header="Gross Premium" filter={true}></Column>
                              
                                    <Column field="TAXES" style={{ width: '20em',textAlign:'right' }} header="TAXES" filter={true}></Column>

                              

                          
                                    
                                    */}                                   
                                   



                                </DataTable>
                            </div>
                        </div>
                  



            </div>

        )
    }
}

export default AgeReport
/*

<TableToExcel

table="id_data_report"
filename="age report"
sheet="sheet 1"
buttonText="Export"

></TableToExcel>*/