import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../ibs-policy/policynew.css'
import ComboboxV1 from '../../../component/combobox/ComboboxV1'
import NumericTextBox from "react-numeric-textbox"

class CommissionOutVoucher extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.myHandleChange = this.myHandleChange.bind(this);

    }





    myHandleChange(s, e) {

        this.props.valueChange(s, e);


    }

    render() {
        const paymentMode = [
            { value: '0', label: 'CASH' },
            { value: '1', label: 'CHEQUE' },
            { value: '2', label: 'SLIP' },
            { value: '3', label: 'WEBSITE' },
            { value: '3', label: 'DIRECT TO INSURER' }
        ]





        return (
            <div >
  <div className="cls-receipt-container">
                    <form >
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="div_label">
                                            Voucher No
                                        </div>
                                    </td>
                                    <td>
                                        <div >
                                            <input type="text"
                                                className="ibsinput"
                                                onChange={(s, e) => this.myHandleChange(s, e)}
                                                name="receipt_ref_no"
                                                placeholder="Voucher No" ></input>
                                        </div>
                                    </td>
                              
                                    <td>
                                        Voucher Date
                                    </td>
                                    <td>
                                        <input
                                            style={{ width: '150px', height: '35px' }}
                                            type="date"
                                            name="receipt_date"
                                            onChange={this.myHandleChange}>
                                        </input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="div_label">
                                        Voucher Method
                                        </div>
                                    </td>
                                    <td>
                                        <div >
                                            <ComboboxV1
                                                hadlechangeUD={this.myHandleChange}
                                                options={paymentMode}
                                                name={'receipt_method'}
                                            ></ComboboxV1>
                                        </div>
                                    </td>
                            
                                    <td>
                                        <div className="div_label">
                                        Voucher Details
                                        </div>
                                    </td>
                                    <td>
                                        <div >
                                            <textarea type="text"
                                                className="ibsinput-textarea"
                                                onChange={this.myHandleChange}
                                                name="receipt_description"
                                                placeholder="Details" ></textarea>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="div_label">
                                        Voucher Amount
                                        </div>
                                    </td>
                                    <td>
                                        <div  >
                                            <NumericTextBox
                                                disabled={true}
                                                onChange={this.myHandleChange}
                                                name="recipt_total"
                                                value={this.props.TotalReciptValue}
                                                className="input-numeric ibsinput">
                                            </NumericTextBox>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>

                </div>
            </div>
        )

    }
}

export default CommissionOutVoucher;


