import React, { useState, useEffect, Component } from "react";
import ReportService from '../../../ReportPDF/Services/ReportServices'
import { Col, Row, Nav } from "react-bootstrap";
import Combobox from '../../../component/combobox/ComboboxV1'
import NumericTextBox from "react-numeric-textbox"

const PaymentDataUpdate = (props) => {



    const [data, setData] = useState([]);
    const [policyData, setpolicyData] = useState([]);
    const [premiumData, setpremiumData] = useState([]);
    const [premiumAmount,setPremiumAmount]=useState(0);
    const [PolicyEntryNo,setPolicyEntryNo]=useState('');
    const reportService = new ReportService();

    const paymentMode = [
        { value: '1', label: 'Direct' },
        { value: '2', label: 'Our Account' }

    ]
    const paymentMethod = [
        { value: '1', label: 'Cash' },
        { value: '2', label: 'Card' },
        { value: '3', label: 'Cheque' }

    ]

    useEffect(() => {
        reportService.getDebitNoteData({ debitno: props.entry_id }).then(data => {

            console.log("function", data);
            setData(data.data);
            setpolicyData(data.policy);
            setpremiumData(data.premium);
            setPolicyEntryNo(props.entry_id);

            if(data){
                var Amount=data.premium.filter(e=>(e.Amount!=null &&  e.premium_id=="Total"))[0].Amount;
                var decAmount=  parseFloat(Amount.replace(',',''));  
                setPremiumAmount(decAmount);
            }
        });
    }, [props.isClicked]);


    return <div className="css-main-note-payment-update">

        <div>
            <div className="css-dbnote-page" style={{ padding: "20px" }} >
                <div className="report-body" id="GFG">
                    <div className="css-note-type-text"> Payment Details</div>
                    <Row>
                        <Col sm={6}>
                            <Col sm={6}>
                             Entry No :   {PolicyEntryNo}

                            </Col>
                            <Col sm={6}>
                              
                            </Col>
                        </Col>
                    </Row>
<br></br>
                    <Row>
                        <Col sm={6}>
                            <Col sm={6}>
                                Payment Mode

                            </Col>
                            <Col sm={6}>
                                <Combobox
                                    hadlechangeUD={props.hadlechangeUD}
                                  //  selectedValue={props.policydata.payment_mode}
                                    options={paymentMode}
                                    name={'payment_mode'}
                                ></Combobox>
                            </Col>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <Col sm={6}>
                                Payment Method

                            </Col>
                            <Col sm={6}>
                            <Combobox
                                 hadlechangeUD={props.hadlechangeUD}
                                  //  selectedValue={props.policydata.payment_mode}
                                    options={paymentMethod}
                                    name={'payment_method'}
                                ></Combobox>
                            </Col>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <Col sm={6}>
                                Amount

                            </Col>
                            <Col sm={6}>

                            <NumericTextBox
                                        onChange={(event) => props.hadlechangeUD(event,  "PaymentAmount")}
                                        name="PaymentAmount"
                                        value={premiumAmount}
                                        className="input-numeric ibsinput">

                                    </NumericTextBox>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    </div>
}

export default PaymentDataUpdate
