import React from "react";
import { Col, Row } from "react-bootstrap";
import SlideListItem from "./SlideListItem";
import "./slide-list.css"


export default function ({
    Caption,
    SlideListDataSource

}) {


    function renderCheckList(list) {

        const element = list && list.map(e => (
            <SlideListItem
            key={e.TextMember}
            TextMember={e.TextMember}
                ValueMember={e.ValueMember}
            />
             ));
        return element;
    };


    return (
        
        <div className="slide-container">
            <div className="caption">{Caption}</div>
       <hr></hr>
            {renderCheckList(SlideListDataSource)}</div>
        
    )


}