import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { ApiList } from "../../../DataOperation/apilink"


class InsurerDetails extends Component {
    constructor() {
        super();
        this.state = {
            details: ''
        }
    }
    componentWillMount() {
        this.getInsurerList();
    }


    getInsurerList() {
        let i_id = this.props.match.params.id;
        axios.post(ApiList.insurer_details, {
            user_id: 1,
            insurer_id: i_id
        })
            .then((response) => {
                this.setState({ details: response.data.data[0] }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });

    }

    render() {




        return (
            <div>
                <h1>Insurer Details</h1>
                <br />
                <Link to="/master-data/insurer" className="btn btn-success">Back</Link>
                <div className="container">
                    <ul>
                        <h1>{this.state.details.insurer_name}</h1>
                    </ul>
                    <div className="row">
                        <div className="col">
                            <Link className="btn btn-info" to={`/insurer/edit/${this.state.details.insurer_id}`}>Edit</Link>
                        </div>
                        <div className="col">

                            <button className="btn btn-danger ibs-btn">Inactive</button>

                        </div>



                    </div>

                </div>
            </div>

        )
    }
}

export default InsurerDetails