import React, { Component } from 'react'
import NavbarSub from '../../../component/Navbar/NavbarPolicy'
import MUButton from '../../../component/MUButton'

class Endosement extends Component {

    constructor(props) {
        super(props);

    }


    render() {
        let i_id = this.props.match.params.id;
        let m_id=this.props.match.params.mid;
        let ClassType = this.props.ClassType;
        
     
        return (
            <div>
                <NavbarSub pagetitle="New Policy"></NavbarSub>
                <div className="container">
                    <div className="container_title">
                    Endorsement Type
                    </div>
                
                    <MUButton uniqid={i_id}  ClassType={ClassType} masterEntryId={m_id}></MUButton>

                </div>

            </div>

        )

    }

}


export default Endosement;