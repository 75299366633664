import { Checkbox } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";


export default function ({
    Text,
    Checked,
    onChange
}) {

    return (
        <Row>
            <Col>
                <Checkbox checked={Checked}
                    onClick={(e) => onChange(e)}
                >
                </Checkbox>   <span> {Text}</span>
            </Col>
          
        </Row>
    )


}