import React, { Component } from "react"
import ContactDetailComp from "../../component/ContactDetail/AssignInsured"
import NavbarSub from '../../component/Navbar/NavbarPolicy'
import PolicyBasic from '../../component/policy/PolicyDetail'
import InvoiceDetails from '../../component/policy/invoice-details'
import PremiumBrakeDown from '../../component/policy/PremiumBrakeDown'
import CommissionBrakeDown from '../../component/policy/CommissionBreakeDownRateText'
import AgentData from '../../component/policy/AgentDetails'
import 'bootstrap/dist/css/bootstrap.min.css';
import './policynew.css'
import Tab from 'react-bootstrap/Tab'
import { Modal, Col, Row, Nav } from "react-bootstrap";
import axios from "axios"
import { ApiList } from "../../DataOperation/apilink"
import EntryList from './entryList/EntryList'
import EndorsementResonText from "./endorsement/EndorsementResonText"
import TaskFlowService from "../../DataApiService/TaskFlowService"
import TaskConfirmWindow from "../../ibs-taskflow/TaskConfirmWindow"

class NewPolicy extends Component {
    constructor(props) {
        super(props)
        console.log("props", props)
        this.state = {
            insured_id: "0", fields: {}, cover_list: [], coverListData: [], show: false,
            workFlowData: [],
            errors: [],
            workTaskView: false
        }
        this.myHandleChange = this.myHandleChange.bind(this);
        this.setCoverList = this.setCoverList.bind(this);
        this.buttonHadleChange = this.buttonHadleChange.bind(this);
        this.setSVATRegistedValue = this.setSVATRegistedValue.bind(this);
        this.setInsuredSelectedIdValue = this.setInsuredSelectedIdValue.bind(this);
        this.autoCompleteHandleChange = this.autoCompleteHandleChange.bind(this);
        this.getCommissionRateByClass = this.getCommissionRateByClass.bind(this);
        this.getPropertyData = this.getPropertyData.bind(this);
        this.getCoverListData = this.getCoverListData.bind(this);
        this.getInputValidation = this.getInputValidation.bind(this);
        this.checkFormValidation = this.checkFormValidation.bind(this);
        this.setPropretyValues = this.setPropretyValues.bind(this);
        this.setProperListData = this.setProperListData.bind(this);
        this.getWorkFlowData = this.getWorkFlowData.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.showWorkTask = this.showWorkTask.bind(this);
        this.requiredFieldsValidation = this.requiredFieldsValidation.bind(this);


    }

    showWorkTask(showTaskConfirm) {

        this.setState({ workTaskView: showTaskConfirm })
    }

    handleClose() {

        this.setState({ show: !this.state.show })
    }


    setCoverList(coverList) {

        console.log(coverList);
        this.setState({
            cover_list: coverList

        })

    }


    getInputValidation(fieldName, errorMessage) {
        let errors = this.state.errors;  //this.state.errors;
        var result = this.arrayRemove(errors, fieldName);
        if (errorMessage.length > 0) {
            result.push({ fieldName: fieldName, errorMessage: errorMessage })
        }

        if (result.length > 0) {
            this.setState({
                error_message: errorMessage
            });
        } else {
            this.setState({
                error_message: ""
            });

        }


        this.setState({ errors: result });
    }

    arrayRemove(arr, value) {
        return arr.filter(function (ele) {
            return ele.fieldName != value;
        });
    }

    setSVATRegistedValue(val) {
        this.setState({ is_svat_registed: val });
    }
    setInsuredSelectedIdValue(val) {
        this.setState({ insured_id: val, policy_ref_no_enc: '' });
        this.getInsuredDataByIdKeyValue(val);
        this.getInputValidation("insured_id", "");
    }

    setPropretyValues() {


    }

    getInsuredDataByIdKeyValue(_param_id) {
        axios.post(ApiList.pd_md_insured_by_id_key_value, {
            user_id: localStorage.getItem("UserIndex"),
            pr_key: 1,
            branch_id: 1,
            param_id: eval(_param_id),
            page: 'assuser'
        })
            .then((response) => {
                var insuredDataR = response.data.insured_data;
                var insuredDataRD = response.data.insured_data_row;
                if (insuredDataR != undefined && insuredDataRD != undefined) {
                    this.setState({
                        insuredDataKeyVal: insuredDataR,
                    })
                }
            }, (error) => {
                console.log(error);
            });
    }


    componentDidMount() {
        let i_id = this.props.match.params.id;
        if (i_id && i_id.length > 0) {
            this.getDataById();
            this.getPropertyData(i_id);
            this.getCoverListData(i_id);
            this.getWorkFlowData(i_id);
        } else {
            this.getWorkFlowData('i_id');
            this.setState({
                policy_ref_no_enc: '',
                policy_ref_no: '00-000-000-00',
                pd_policy_entry_id: '', user_id: '1', policy_no: '', policy_type_id: '',
                branch_id: 'HO',
                insurer_id: '',
                ins_class_id: '',
                policy_type_id: '',
                period_from: '',
                issue_date:'',
                period_to: '',
                sum_insured: 0,
                vehicle_no: '',
                currency: 'LKR',
                invoice_no: '',
                cover_note_no: '',
                occupation_id: '',
                agent_id: 0,
                agent_service_id: '',
                is_active: '',
                contact_person_id: '',
                insured_title: '',
                insured_name: '',
                insured_id: "0",
                insured_nic_no: '',
                insured_address_no: '',
                insured_address_road: '',
                insured_address_city: '',
                insured_contact_no: '',
                insured_email: '',
                insured_surname: '',
                insured_initials: '',
                contact_person_title: '',
                contact_person_name: '',
                contact_person_nic_no: '',
                contact_person_email: '',
                contact_person_address_no: '',
                contact_person_address_road: '',
                contact_person_address_city: '',
                contact_person_contact_no: '',
                premium_basic: 0, premium_srcc: 0, premium_terror: 0, premium_total: 0, premium_svat: 0,
                commission_comm_rate_br_basic: 0,
                commission_comm_rate_br_srcc: 0,
                commission_comm_rate_br_terr: 0,
                agent_commission_comm_rate_br_basic: 0,
                agent_commission_comm_rate_br_srcc: 0,
                agent_commission_comm_rate_br_terr: 0,
                policy_entry: [],
                policy_com_rate: [],
                error_message: '',
                user_id: 1,
                COMBR: [],
                COMSR: [],
                COMTR: [],
                COMAGBR: [],
                COMAGSR: [],
                COMAGTR: [],
                entry_type: this.props.EntryType,
                note_type: this.props.NoteType,
                SaveMode: this.props.SaveMode,
                Reasons: [],
                Reason: 0,
                Remark: '',
                payment_mode: 0,
                is_svat_registed: false,
                insuredDataKeyVal: [],
                coverListData: [],
                covers: '',
                policy_types: [],
                propertyList: []
            });
        }
    }


    setProperListData(list) {
        this.setState({ propertyList: list });

    }

    getDataById() {
        let i_id = this.props.match.params.id;
        let eid = this.props.match.params.eid;
        const ActionType = this.props.ActionType;
        const ClassType = this.props.ClassType;

        axios.post(ApiList.pd_policy_by_id, {
            user_id: localStorage.getItem("UserIndex"),
            pr_key: i_id,
            branch_id: 1,
            policy_entry_id: eid,//TO DO
            class_type: ClassType,
            ActionType: ActionType,
            Source: 'L237'
        })
            .then((response) => {
                console.log("response.data",response.data)

                var p_data = response.data.data[0];
                this.setInsuredSelectedIdValue(p_data.insured_id);

                var p_data_entry = response.data.data_entry;
                //var p_coverListData= response.data.cover_list_data;
                this.setCoverList(response.data.cover_list_data);

                var p_data_com_rate = response.data.data_rate;
                var p_data_com_rate_basic = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "Basic")]
                var p_data_com_rate_srcc = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "SRCC")]
                var p_data_com_rate_terror = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "Terrorism")]
                var p_data_com_rate_tax = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "Tax")]
                var p_data_com_rate_tax_svat = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "SVAT")]

                var m_premium_total = (
                    p_data_com_rate_basic.premium_debit +
                    p_data_com_rate_srcc.premium_debit +
                    p_data_com_rate_terror.premium_debit +
                    p_data_com_rate_tax.premium_debit +
                    p_data_com_rate_tax_svat.premium_debit

                );

                this.setState({
                    policy_ref_no_enc: this.props.match.params.id,
                    policy_ref_no: p_data.policy_ref_no,
                    pd_policy_entry_id:(this.props.SaveMode=='NEW'?'':eid) ,
                    policy_no: p_data.policy_no,
                    insured_id: p_data.insured_id,
                    branch_id: p_data.branch_id,
                    insurer_id: p_data.insurer_id,
                    ins_class_id: p_data.ins_class_id,
                    policy_type_id: p_data.policy_type_id,
                    period_from: p_data.period_from,
                    issue_date:p_data.issue_date,
                    period_to: p_data.period_to,
                    sum_insured: p_data.sum_insured,
                    vehicle_no: p_data.vehicle_no,
                    currency: p_data.currency,
                    invoice_no: p_data.invoice_no,
                    cover_note_no: p_data.cover_note_no,
                    covers: p_data.covers,
                    occupation_id: p_data.occupation_id,
                    agent_id: p_data.agent_id,
                    agent_service_id: p_data.agent_service_id,
                    is_active: p_data.is_active,
                    contact_person_id: p_data.contact_person_id,

                    insured_title: p_data.insured_title,
                    insured_name: p_data.insured_name,
                    insured_nic_no: p_data.insured_nic_no,
                    insured_address_no: p_data.insured_address_no,
                    insured_address_road: p_data.insured_address_road,
                    insured_address_city: p_data.insured_address_city,
                    insured_contact_no: p_data.insured_contact_no,
                    insured_email: p_data.insured_email,
                    insured_surname: p_data.insured_surname,
                    insured_initials: p_data.insured_initials,

                    contact_person_title: p_data.contact_person_title,
                    contact_person_name: p_data.contact_person_name,
                    contact_person_nic_no: p_data.contact_person_nic_no,
                    contact_person_address_no: p_data.contact_person_address_no,
                    contact_person_address_road: p_data.contact_person_address_road,
                    contact_person_address_city: p_data.contact_person_address_city,
                    contact_person_contact_no: p_data.contact_person_contact_no,
                    contact_person_email: p_data.contact_person_email,

                    policy_entry: p_data_entry,
                    Reason: p_data_com_rate[0].Reason,
                    Remark: p_data_com_rate[0].Remark,

                    policy_com_rate: p_data_com_rate,
                    premium_basic: p_data_com_rate_basic.premium_debit,
                    premium_srcc: p_data_com_rate_srcc.premium_debit,
                    premium_terror: p_data_com_rate_terror.premium_debit,
                    premium_total: m_premium_total,

                    premium_svat: p_data_com_rate_tax_svat.premium_debit,
                    commission_comm_rate_br_basic: p_data_com_rate_basic.com_in_rate,
                    commission_comm_rate_br_srcc: p_data_com_rate_srcc.com_in_rate,
                    commission_comm_rate_br_terr: p_data_com_rate_terror.com_in_rate,
                    agent_commission_comm_rate_br_basic: p_data_com_rate_basic.com_out_rate,
                    agent_commission_comm_rate_br_srcc: p_data_com_rate_srcc.com_out_rate,
                    agent_commission_comm_rate_br_terr: p_data_com_rate_terror.com_out_rate,

                    entry_type: this.props.EntryType,
                    note_type: this.props.NoteType,
SaveMode:this.props.SaveMode


                }, () => {
                    this.getCommissionRateByClass(this.state.ins_class_id);
                })
            }, (error) => {
                console.log(error);
            });

    }


    getPropertyData(enqId) {
        axios.post(ApiList.pd_policy_property_data, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1,
            param_id: enqId,
            page: 'assuser'
        })
            .then((response) => {
                var returnData = response.data.data;

                console.log("returnData", returnData);
                if (returnData != undefined) {
                    this.setState({
                        propertyData: returnData
                    })
                }

            }, (error) => {
                console.log(error);
            });

    }

    getCoverListData(enqId) {
        axios.post(ApiList.pd_cover_data_by_ref_id, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1,
            policy_ref_no: enqId
        })
            .then((response) => {
                var returnData = response.data.data;
                console.log("coverListData", returnData);
                if (returnData != undefined) {
                    this.setState({
                        coverListData: returnData
                    })
                }

            }, (error) => {
                console.log(error);
            });

    }


    getWorkFlowData(enqId) {

        let eid = this.props.match.params.eid;
        const taskFlowService = new TaskFlowService();



        let wk_actionid = this.props.WorkFlowActionId;// ((this.props.EntryType == "ED" || this.props.EntryType == "EC" || this.props.EntryType == "EP") ? ((this.props.EntryType == "RN") ? 2 : 3) : 1);

        this.state.wk_actionid = wk_actionid;

        if (this.props.ActionId) {
            if (this.props.ActionId == "NEW") {
                //     wk_actionid = 1;
            }
        }



        taskFlowService.getPendingWorkForUserByTask({
            user_id: 1,
            pr_key: 1,
            branch_id: 1,
            policy_ref_no: (this.props.SaveMode == "NEW" ? "i_id" : enqId),// ((((this.props.EntryType == "ED" || this.props.EntryType == "EC") && this.props.ActionType == "NewEndosement") || this.props.ActionType =="NewRenewal" )? 'i_id' : enqId),
            policy_entry_id: (this.props.SaveMode == "NEW" ? "" : eid),
            action_id: wk_actionid
        }).then(data => this.setState({ workFlowData: data }), (error) => {
            console.log(error);
        });

    }


    getCommissionRateByClass(ins_class_id) {
        axios.post(ApiList.pd_policy_rates, {
            user_id: 1, ins_class_id: ins_class_id
        })
            .then((response) => {



                let d_commission_comm_rate_br_basic = this.state.commission_comm_rate_br_basic;
                let d_commission_comm_rate_br_srcc = this.state.commission_comm_rate_br_srcc;
                let d_commission_comm_rate_br_terr = this.state.commission_comm_rate_br_terr;
                let d_agent_commission_comm_rate_br_basic = this.state.agent_commission_comm_rate_br_basic;
                let d_agent_commission_comm_rate_br_srcc = this.state.agent_commission_comm_rate_br_srcc;
                let d_agent_commission_comm_rate_br_terr = this.state.agent_commission_comm_rate_br_terr;

                if (this.props.EntryType == "NP") {
                    d_commission_comm_rate_br_basic = (response.data.COMBR[0] != undefined ? response.data.COMBR[0].value : 0);
                    d_commission_comm_rate_br_srcc = (response.data.COMSR[0] != undefined ? response.data.COMSR[0].value : 0);
                    d_commission_comm_rate_br_terr = (response.data.COMTR[0] != undefined ? response.data.COMTR[0].value : 0);

                    d_agent_commission_comm_rate_br_basic = ((response.data.COMAGBR[0] != undefined && this.state.agent_id != 0) ? response.data.COMAGBR[0].value : 0);
                    d_agent_commission_comm_rate_br_srcc = ((response.data.COMAGSR[0] != undefined && this.state.agent_id != 0) ? response.data.COMAGSR[0].value : 0);
                    d_agent_commission_comm_rate_br_terr = ((response.data.COMAGTR[0] != undefined && this.state.agent_id != 0) ? response.data.COMAGTR[0].value : 0);
                }




                this.setState({
                    COMBR: response.data.COMBR,
                    COMSR: response.data.COMSR,
                    COMTR: response.data.COMTR,
                    COMAGBR: response.data.COMAGBR,
                    COMAGSR: response.data.COMAGSR,
                    COMAGTR: response.data.COMAGTR,
                    Reasons: response.data.endorsement_reason,
                    policy_types: response.data.policy_types,
                    commission_comm_rate_br_basic: eval(d_commission_comm_rate_br_basic),
                    commission_comm_rate_br_srcc: eval(d_commission_comm_rate_br_srcc),
                    commission_comm_rate_br_terr: eval(d_commission_comm_rate_br_terr),
                    agent_commission_comm_rate_br_basic: eval(d_agent_commission_comm_rate_br_basic),
                    agent_commission_comm_rate_br_srcc: eval(d_agent_commission_comm_rate_br_srcc),
                    agent_commission_comm_rate_br_terr: eval(d_agent_commission_comm_rate_br_terr),

                }, () => {
                    console.log('lokong rEQ', response)
                })
            }, (error) => {
                //console.log(error);
            });
    }
    buttonHadleChange(e, s) {
        if (this.requiredFieldsValidation()) {
            if (this.checkFormValidation()) {
                this.createPolicy(this.state);
            }
            //why this
            //  this.createPolicy(this.state);

        }

    }

    checkFormValidation() {

        this.requiredFieldsValidation();
        if (this.state.errors.length > 0) {

            return false
        }
        else {
            this.setState({
                error_message: ""
            });
            return true;
        }
    }

    requiredFieldsValidation() {

        if (eval(this.state.insured_id) == 0) {
            this.getInputValidation("insured_id", "Please select the client");
            return false;

        } else {

            return true;
        }


    }


    autoCompleteHandleChange(value, isDataAvailable) {

        if (isDataAvailable) {
            axios.post(ApiList.pd_md_insured_by_id, {
                user_id: 1,
                pr_key: 1,
                branch_id: 1,
                nic_no: value
            })
                .then((response) => {

                    console.log("autoCompleteHandleChange", response)
                    if (response.data.insured_data.length > 0) {
                        this.setState({
                            insured_id: response.data.insured_data[0].insured_id,
                            insured_title: response.data.insured_data[0].insured_title,
                            insured_name: response.data.insured_data[0].insured_name,
                            insured_nic_no: response.data.insured_data[0].insured_nic_no,
                            insured_address_no: response.data.insured_data[0].insured_address_no,
                            insured_address_road: response.data.insured_data[0].insured_address_road,
                            insured_address_city: response.data.insured_data[0].insured_address_city,
                            insured_contact_no: response.data.insured_data[0].insured_contact_no,
                            insured_email: response.data.insured_data[0].insured_email,
                            insured_surname: response.data.insured_data[0].insured_surname,
                            insured_initials: response.data.insured_data[0].insured_initials,
                            is_svat_registed: response.data.insured_data[0].is_svat_registered
                        })
                    }
                    else {
                        this.setState({
                            insured_id: "0",
                            insured_nic_no: value

                        })
                    }
                }, (error) => {
                    //console.log(error);
                });
        } else {
            this.setState({
                insured_id: "0",
                insured_nic_no: value
            })
        }
    }
    refreshPage() {
        window.location.href = window.location.href;
    }

    createPolicy(policy_json) {
     
        let active_api = (this.props.EntryType == "NP" ? ApiList.pd_policy_create_partial :
            (this.props.EntryType == "EP" ? ApiList.pd_policy_update :

                ApiList.pd_policy_endorsement

            )
        )

        let para_policy_json = { ...policy_json, user_id: localStorage.getItem("UserIndex"), Reasons: [], policy_com_rate: [], insuredDataKeyVal: [] }

        axios.post(active_api, para_policy_json)
            .then((response) => {
                if (response.data.status == "1") {
                    if (response.data.data[0]) {
                        this.setState({
                            policy_ref_no: response.data.data[0].pd_policy_ref_no,
                            pd_policy_entry_id: response.data.data[0].pd_policy_entry_id,
                            policy_entry: response.data.entrylist,
                            user_id: 1,
                            error_message: 'Update successfully!'

                        })
                    }

                    this.showWorkTask(true)
                } else {
                    this.setState({
                        error_message: response.data.error
                    });

                }

            }, (error) => {
                this.setState({
                    error_message: error
                });
            });




    }

    myHandleChange(val, e) {
        if (val == null) {
            return;
        }
        if (val.target != null && val.target.name) {
            this.setState({ [val.target.name]: val.target.value, user_id: 1 })
            console.log("val", val.target.name);
            this.setDuration(val);
        } else {
            this.setState({ [e]: val, user_id: 1 })
            if (e == 'ins_class_id') {
                this.getCommissionRateByClass(val);
            }
            if (e == 'agent_id') {
                this.getCommissionRateByClass(this.state.ins_class_id);
            }
        }
    }


    setDuration(val) {
        if (val.target.name == "period_from") {
            if (this.state.ins_class_id == "MAR") {
                this.setState({ period_to: val.target.value })
            }
        }
    }

    render() {


        const invoiceDetailsData = {
            currency: this.state.currency,
            sum_insured: this.state.sum_insured,
            invoice_no: this.state.invoice_no
        }
        const policy_details_data = {
            policy_no: this.state.policy_no,
            policy_type_id: this.state.policy_type_id,
            branch_id: this.state.branch_id,
            insurer_id: this.state.insurer_id,
            ins_class_id: this.state.ins_class_id,
            occupation_id: this.state.occupation_id,
            vehicle_no: this.state.vehicle_no,
            cover_note_no: this.state.cover_note_no,
            period_from: this.state.period_from,
            issue_date:this.state.issue_date,
            period_to: this.state.period_to,
            payment_mode: this.state.payment_mode,
            covers: this.state.covers
        }
        const insuredData = {
            title: this.state.insured_title,
            name: this.state.insured_name,
            nic_no: this.state.insured_nic_no,
            address_no: this.state.insured_address_no,
            address_road: this.state.insured_address_road,
            address_city: this.state.insured_address_city,
            contact_no: this.state.insured_contact_no,
            email: this.state.insured_email,
            surname: this.state.insured_surname,
            initials: this.state.insured_initials
        }
        const insured_Conatact_Data = {
            title: this.state.contact_person_title,
            name: this.state.contact_person_name,
            nic_no: this.state.contact_person_nic_no,
            address_no: this.state.contact_person_address_no,
            address_road: this.state.contact_person_address_road,
            address_city: this.state.contact_person_address_city,
            contact_no: this.state.contact_person_contact_no,
            email: this.state.contact_person_email
        }



        const policy_premium_commission_rate = this.state.policy_com_rate;
        const NoteType = this.props.NoteType;
        const EntryType = this.props.EntryType;
        const TaskType = this.props.TaskType;
        //const IsEndosement = (this.props.EntryType =="RN" ? false: (this.props.EntryType == "ED" || this.props.EntryType == "EC" || this.props.EntryType == "EP"))  
        const IsEndosement = (this.props.ActionType == "Endosement" || this.props.ActionType == "NewEndosement");
        const defaultActiveKey = (IsEndosement ? "third" : "first");
        const policy_entry = this.state.policy_entry;
        const endorsement_reason_data = this.state.Reasons;
        const ResonId = this.state.Reason;
        const Remark = this.state.Remark;

        const TaskConfirmWindowApp = () => {
            if (this.state.workTaskView) {


                return (<TaskConfirmWindow
                    show_popup={true}
                    workFlowData={this.state.workFlowData[0]}
                    pd_policy_entry_id={this.state.pd_policy_entry_id}
                    policy_ref_no={this.state.policy_ref_no}
                ></TaskConfirmWindow>)

            } else {

                return null;
            }



        }

        const viewPolicyPermissionOnly = (!(this.props.ActionType != undefined && this.props.ActionType == "ViewPolicy"))
        const HasPermission = ((this.state.workFlowData != undefined && this.state.workFlowData.length > 0))
        // || this.props.ActionType != "ViewPolicy"

        return (
            <div  >
                <NavbarSub pagetitle="New Policy"></NavbarSub>

                <TaskConfirmWindowApp   ></TaskConfirmWindowApp>


                <Tab.Container id="left-tabs-example" defaultActiveKey={defaultActiveKey}>
                    <Row>
                        <Col sm={3}>


                            <div className="css_task_title"> {TaskType} - {(this.props.ClassType == "L" ? "Life" : "General")}</div>
                            <div className="css_task_title_id"> {this.state.pd_policy_entry_id}</div>
                            <div className="css_task_title_id css_task_title_id_notetype">  {this.state.pd_policy_entry_id != undefined ?
                                                    (this.state.pd_policy_entry_id.substring(0, 2) == "EC" ? "Credit Note" : "Debit Note")
                                                    : ""}</div>
                           
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" >Customer Details</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" >Policy Details</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="third">Rates</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>

                                    <div className="div-tab-button-container">
                                        <div className="div-tab-data-view">
                                            <div className="row"></div>
                                            <div className="row"></div>
                                            <div className="row"></div>
                                            <div className="row"></div>
                                            <div className="row"></div>

                                            <div className="row">Policy Reference Number</div>
                                            <div className="row"> <span>{this.state.policy_ref_no}</span></div>
                                            {this.props.EntryType}
                                            <div className="row">Policy Debit Note Number</div>
                                            <div className="row"><span>{this.state.pd_policy_entry_id}</span>
                                              
                                                <br></br>
                                            </div>

                                            <div className="row">Trasnactions</div>
                                            <div className="row"><EntryList 
                                            CurrentEntry={this.state.pd_policy_entry_id}
                                            EntryList={this.state.policy_entry} 
                                            ClassType={this.props.ClassType}></EntryList></div>

                                            <div className={this.state.error_message == 'update successfully!' ? 'css_lbl_success' : 'css_lbl_error'}>
                                                {this.state.error_message}
                                            </div>
                                        </div>
                                        <Tab.Pane eventKey="third">
                                            <button className="btn btn-success" disabled={!HasPermission} onClick={this.buttonHadleChange}>Save</button>
                                        </Tab.Pane>
                                        <button className="btn btn-info" onClick={this.refreshPage}>Refresh - Clear all data</button>
                                    </div>

                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="flex-container" disabled={!HasPermission || IsEndosement} >

                                        <div className="flex-child magenta">
                                            <ContactDetailComp name="insured"
                                                data={insuredData}
                                                hadlechangeUD={this.myHandleChange}
                                                legend_title="Customer Details"
                                                autoCompleteHandleChange={this.autoCompleteHandleChange}
                                                setSVATRegistedValue={this.setSVATRegistedValue}
                                                setInsuredSelectedIdValue={this.setInsuredSelectedIdValue}
                                                insured_id={this.state.insured_id}
                                                insuredDataKeyVal={this.state.insuredDataKeyVal}
                                            ></ContactDetailComp>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div disabled={!HasPermission} >

                                        {(IsEndosement ? (<EndorsementResonText
                                            selectedValue={ResonId}
                                            data={endorsement_reason_data}
                                            hadlechangeUD={this.myHandleChange}
                                            remark={Remark}
                                        ></EndorsementResonText>) : null)}


                                        < div className="css_section_title"> Policy Details
                                        {JSON.stringify(this.state.propertyData)}
                                         </div>
                                        <PolicyBasic
                                            hadlechangeUD={this.myHandleChange}
                                            PlicyBasicData={this.state.currency}
                                            policydata={policy_details_data}
                                            policyTypes={this.state.policy_types}
                                            policy_type_id={this.state.policy_type_id}
                                            policy_ref_no={this.state.policy_ref_no_enc}
                                            ins_class_id={this.state.ins_class_id}
                                            insuranceClassType={this.props.ClassType}
                                            propertyData={this.state.propertyData}
                                            setCoverList={this.setCoverList}
                                            coverListData={this.state.coverListData}
                                            setProperListData={this.setProperListData}
                                            IsEndosement={IsEndosement}
                                        ></PolicyBasic>

                                        <InvoiceDetails
                                            hadlechangeUD={this.myHandleChange}
                                            legend_title="Invoice Details"
                                            data={invoiceDetailsData}  ></InvoiceDetails>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div disabled={!HasPermission}>

                                        <div >
                                            <div >
                                                <div className="css_section_title">   Premium</div>
                                                <PremiumBrakeDown
                                                    insuranceClassType={this.props.ClassType}
                                                    is_svat_registed={this.state.is_svat_registed}
                                                    hadlechangeUD={this.myHandleChange}
                                                    premium_total={this.state.premium_total}
                                                    premiumData={policy_premium_commission_rate}
                                                    premium_basic={this.state.premium_basic}
                                                    premium_srcc={this.state.premium_srcc}
                                                    premium_terror={this.state.premium_terror}
                                                    premium_svat={this.state.premium_svat}
                                                    premium_due={this.state.premium_basic + this.state.premium_srcc + this.state.premium_terror}
                                                    premium_tax={this.state.premium_total - (this.state.premium_svat + this.state.premium_basic + this.state.premium_srcc + this.state.premium_terror)}
                                                ></PremiumBrakeDown>

                                            </div>
                                            <div >
                                                <Row>
                                                    <Col>    <CommissionBrakeDown
                                                      insuranceClassType={this.props.ClassType}
                                                        tittle="Commission Brakedown"
                                                        name="commission"
                                                        getInputValidation={this.getInputValidation}
                                                        ins_class_id={this.state.ins_class_id}
                                                        premiumData={this.state.policy_com_rate}
                                                        premium_basic={this.state.premium_basic}
                                                        premium_srcc={this.state.premium_srcc}
                                                        premium_terror={this.state.premium_terror}
                                                        premium_basic_rate={this.state.commission_comm_rate_br_basic}
                                                        premium_srcc_rate={this.state.commission_comm_rate_br_srcc}
                                                        premium_terror_rate={this.state.commission_comm_rate_br_terr}
                                                        premium_basic_rate_comin={100}
                                                        premium_srcc_rate_comin={100}
                                                        premium_terror_rate_comin={100}
                                                        COMBR={this.state.COMBR}
                                                        COMSR={this.state.COMSR}
                                                        COMTR={this.state.COMTR}
                                                        COMAGBR={this.state.COMAGBR}
                                                        COMAGSR={this.state.COMAGSR}
                                                        COMAGTR={this.state.COMAGTR}
                                                        premium_commission_total={
                                                            (this.state.premium_basic * this.state.commission_comm_rate_br_basic) +
                                                            (this.state.premium_srcc * this.state.commission_comm_rate_br_srcc) +
                                                            (this.state.premium_terror * this.state.commission_comm_rate_br_terr)}
                                                        hadlechangeUD={this.myHandleChange}
                                                    ></CommissionBrakeDown></Col>
                                                    <Col>   </Col>

                                                </Row>
                                                <AgentData
                                                    hadlechangeUD={this.myHandleChange}
                                                    agent_id={this.state.agent_id}
                                                    servervice_by_id={this.state.agent_service_id}

                                                ></AgentData>

                                                <CommissionBrakeDown
  insuranceClassType={this.props.ClassType}
                                                    premiumData={this.state.policy_com_rate}
                                                    getInputValidation={this.getInputValidation}
                                                    COMBR={this.state.COMAGBR}
                                                    COMSR={this.state.COMAGSR}
                                                    COMTR={this.state.COMAGTR}
                                                    name="agent_commission"
                                                    tittle="Agent Commission Brakedown"
                                                    AgentId={this.state.agent_id}
                                                    premium_basic_rate_comin={this.state.commission_comm_rate_br_basic}
                                                    premium_srcc_rate_comin={this.state.commission_comm_rate_br_srcc}
                                                    premium_terror_rate_comin={this.state.commission_comm_rate_br_terr}
                                                    premium_basic_rate={this.state.agent_commission_comm_rate_br_basic}
                                                    premium_basic={this.state.premium_basic}
                                                    premium_srcc={this.state.premium_srcc}
                                                    premium_srcc_rate={this.state.agent_commission_comm_rate_br_srcc}
                                                    premium_terror_rate={this.state.agent_commission_comm_rate_br_terr}
                                                    premium_terror={this.state.premium_terror}
                                                    premium_commission_total={
                                                        (this.state.premium_basic * this.state.agent_commission_comm_rate_br_basic) +
                                                        (this.state.premium_srcc * this.state.agent_commission_comm_rate_br_srcc) +
                                                        (this.state.premium_terror * this.state.agent_commission_comm_rate_br_terr)}

                                                    hadlechangeUD={this.myHandleChange}></CommissionBrakeDown>
                                            </div>
                                            <div >
                                                <div>



                                                </div>
                                                <div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                {localStorage.getItem("UserIndex") < 3 ?
                    <div>

                        Action Id:
                        {this.state.wk_actionid}/prop :{this.props.ActionId}:{this.props.WorkFlowActionId}
                        <br></br>
                        ActionType  :
                        {this.props.ActionType}  <br></br>
                        HasPermission :
                        {JSON.stringify(HasPermission)} <br></br>
                        work flow :
                        {JSON.stringify(this.state.workFlowData)}
                        <br></br>
                        Save Mode :{this.props.SaveMode}
                        <br></br>
                    </div>
                    : null
                }


                <Modal show={this.state.show} onHide={this.handleClose} dialogClassName="cls_modal_small_pop" >
                    <Modal.Header closeButton>
                        <Modal.Title>Policy Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    </Modal.Body>
                    <Modal.Footer>
                        <div variant="secondary" onClick={this.handleClose}>
                            Close
                        </div>
                    </Modal.Footer>
                </Modal>

            </div>
        )

    }
}

export default NewPolicy;