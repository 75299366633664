import React, { useState, useEffect, Component } from "react";
import ReactToPdf from "react-to-pdf"
import ReportHeader from "./ReportHeader"
import PremiumDetails from './ReportPremiumDetails'
import ReportFooter from './ReportFooter'
import ReportService from '../Services/ReportServices'
import PolicyDetails from "./ReportPolicyData";
import './report.css'
const ref = React.createRef();

const myInvoice = 'Debit Note'
const options = {
    orientation: 'portrait',
    unit: 'in',
    format: [11.7, 8.3]
};

class DebitNote extends Component {

    constructor(props) {
        super(props)



        this.state = { data: [], policyData: [], premiumData: [] }
    }

   


    componentDidMount() {
        let i_id = this.props.match.params.id;
        const reportService = new ReportService();
        reportService.getDebitNoteData(
            { debitno: i_id }
        ).then(data =>
            this.setState({

                data: data.data,
                policyData: data.policy,
                premiumData: data.premium
            },
            )
        );

    
        window.print()
    }




    //reportService.getDebitNoteData().then(data => setReportData(data));


    render() {    
        const ReportData = this.state.data;
        const PolicyData = this.state.policyData;
        const premiumData = this.state.premiumData;
        let NoteType = 'DEBIT NOTE'
        let fileName = 'debitnote';
        let NoteTypeRefNo='';
        if (PolicyData.length > 0) {

            NoteType = PolicyData[0].NoteType
            fileName = PolicyData[0].entry_id           
        }
   

        return (
            <div  className="css-main-note">
                
         <div>
         
                    {/*JSON.stringify(this.state.policyData)*/}    
                    <div style={{display:"none"}}>   
                    <ReactToPdf targetRef={ref} filename={`${fileName}.pdf`} options={options} x={.5} y={.5} scale={0.8}>
                        {({ toPdf }) => (
                            <button onClick={toPdf} className="btn btn-primary" style={{ width: "200px" }}>Download</button>
                        )}
                    </ReactToPdf>                  
                    <br></br>
                    </div>       
                    <div className="css-dbnote-page" style={{ width: 874, height: 1240, background: 'white', padding: "20px" }} ref={ref}>
              
                        <ReportHeader CompanyData={ReportData}></ReportHeader>
                        <div className="report-body" id="GFG">
                            <div className="css-note-type-text"> {NoteType}</div>

                            <PolicyDetails policyData={PolicyData}></PolicyDetails>
<br></br>
                            <PremiumDetails premiumData={premiumData}></PremiumDetails>

                        </div>
                        <ReportFooter  CompanyData={ReportData} fileName={fileName}></ReportFooter>
                    </div>
                    </div>
            </div>


        );



    }
}

export default DebitNote;
