import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import Tab from 'react-bootstrap/Tab'
import { Col, Row, Nav } from "react-bootstrap";
import FlagButton from '../FlagButton/FlagButton'
import ClientType from '../../Ibs-pages/ibs-insured/InsuredType'
import PolicyDetails from "../../Ibs-pages/ibs-policy/PolicyDetails/PolicyDetails";
import PaymentNavPage from '../../Ibs-pages/ibs-payment/main/PaymentNavPage'
import ReportNavPage from "../../Ibs-pages/ibs-report/ReportNavPage";

class TabNavBar extends Component {
    constructor(props) {
        super(props)   
    } 

    render() {
      
        const defaultActiveKey = ("ek_client_data");  
        return (

            <div className="css-main-div-tab-nav-bar">   
                <Tab.Container id="left-tabs-example" defaultActiveKey={defaultActiveKey}>
                    <Row>
                        <Col sm={3}>    
                           <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="ek_client_data">                                        
                                     <FlagButton Name="Client Data"></FlagButton>                                        
                                        </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="ek_policy_details" >
                                    <FlagButton Name="Policy Details"></FlagButton>                                    
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="ek_payment">                                    
                                    <FlagButton Name="Payment"></FlagButton>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="ek_report">
                                    <FlagButton Name="Report"></FlagButton>
                                    </Nav.Link>
                                </Nav.Item>                          
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="ek_client_data">
                                <ClientType></ClientType>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ek_policy_details">
                            
                           <PolicyDetails></PolicyDetails>

                                </Tab.Pane>
                                <Tab.Pane eventKey="ek_payment">
                               
                             <PaymentNavPage></PaymentNavPage>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ek_report">
                           <ReportNavPage></ReportNavPage>
                               </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>



            </div>
        )

    }
}

export default TabNavBar;

