import React, { Component, useState } from "react"
import axios from "axios"
import { ApiList } from "../../../DataOperation/apilink"
import ComboboxV1 from "../../../component/combobox/ComboboxV1"
import NumericTextBox from "react-numeric-textbox"
import Navbar from "../../../component/Navbar/NavbarMasterData"



var commisionrates = [];
var policyclass = [];
class EditCommisionRate extends Component {

    //RateId,RateValue,RateCode,ins_class_id
    constructor(props) {
        super(props)
        this.state = { 
            CommisionRate_name: '',
             commisionrates: '',
              validate_message: '', 
              policyclass: '', 
              CommisionRate: 0,
              RateIndex:0 }
        this.onChange = this.onChange.bind(this);

    }

    componentWillMount() {
              this.getMasterDataList();

        this.  getEditData() ;
    }
    onChange(e, val) {
        console.log(e);
        console.log(val);
        if (e.target != null && e.target.name) {
            this.setState({
                [e.target.name]: e.target.value,
                validate_message: (e.target.value > 0 ? '' : '*'),
                user_id: 1
            })
        } else {
            //   this.calculateCommissionBrakeDown(e,val)
            this.setState({
                [val]: e, validate_message: (val > 0 ? '' : '*'),
                user_id: 1
            })
        }
        console.log(this.state);



    }

    addInsurer(newInsurer, e) {
        axios.post(ApiList.pd_commision_rate_update, newInsurer)
            .then((response) => {

                console.log("update return value", response.data);
                if (response.data.status == 1) {
                    //   this.props.onListChange();
                  
                    this.props.history.push('/master-data/commission-rate');
                    //   e.target.reset();

                } else {
                    this.setState({
                        validate_message: response.data.error
                    })
                }


            }, (error) => {
                console.log('error', error);
            });



    }

    getMasterDataList() {


        axios.post(ApiList.pd_policy_rates_master_data, {
            user_id: 1
        })
            .then((response) => {

                console.log("master response", response);
                commisionrates = response.data.commisionrates;
                policyclass = policyclass = response.data.policyclass;

                this.setState({
                    commisionrates: response.data.commisionrates,
                    policyclass: response.data.policyclass

                }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });

    }

    getEditData() {
        let i_id = this.props.match.params.id;
        axios.post(ApiList.pd_policy_rates_by_id, {
            user_id: 1,
            RateIndex: i_id
        })
            .then((response) => {
                console.log(response)

                this.setState({
                    //RateId,RateValue,RateCode,ins_class_id
                    RateIndex: response.data.data[0].RateIndex,
                    CommisionRateCode: response.data.data[0].RateCode,
                    CommisionRatePolicyType: response.data.data[0].ins_class_id,
                    CommisionRate: (response.data.data[0].RateValue*100)
                  
                    

                }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });

    }
    onSubmit(e) {

        if (this.state.CommisionRateCode.length > 0 && this.state.CommisionRatePolicyType.length > 0) {
            //remove unnessary data
            const newInsurer = {
                RateIndex: this.state.RateIndex,
                CommisionRate: this.state.CommisionRate,
                CommisionRateCode:this.state.CommisionRateCode,
                user_id:1,
                CommisionRatePolicyType:this.state.CommisionRatePolicyType
            };
            console.log(newInsurer);
            this.addInsurer(newInsurer, e);

        }
        else {
            this.setState({

                validate_message: '*'
            })

        }


        e.preventDefault();

    }


    render() {


        return (
            <div>
                <Navbar></Navbar>
                <form onSubmit={this.onSubmit.bind(this)}>
                    <li className="list-group-item">
                        <div className="container">
                            <div className="row">
                                <div className="master-data-page-tittle"> <span>Add New {this.props.master_page_tittle}</span></div>
                            </div>
                            <div className="master-data-page-body">
                                <div className="row">
                                    <table>
                                        <tbody>
                                            <tr>

                                                <td> <label > Commission Component</label></td>
                                                <td>
                                                    <ComboboxV1
                                                        hadlechangeUD={this.onChange}
                                                        isDisabled={true}
                                                        options={commisionrates}
                                                        name="CommisionRateCode"
                                                        selectedValue={this.state.CommisionRateCode}
                                                    ></ComboboxV1>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td> <label > Policy Type</label></td>
                                                <td>
                                                    <ComboboxV1
                                                        hadlechangeUD={this.onChange}
                                                        isDisabled={true}
                                                        options={policyclass}
                                                        name="CommisionRatePolicyType"
                                                        selectedValue={this.state.CommisionRatePolicyType}
                                                    ></ComboboxV1>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <label >Rate</label></td>
                                                <td>     <NumericTextBox
                                                    onChange={(val) => { this.onChange(val, "CommisionRate") }}
                                                    name="CommisionRate" ref="CommisionRate"
                                                    placeholder="0.00"
                                                    value={this.state.CommisionRate}
                                                    id="CommisionRate"></NumericTextBox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> <label ></label></td>
                                                <td>    <button type="submit" value="save" className="btn btn-info">(+)Add</button></td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>    <div className="row">
                                    <div col="col">
                                        <spa>{this.state.validate_message}</spa>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </li>
                </form>
            </div>
        )

    }



}

export default EditCommisionRate