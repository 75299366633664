import React, { Component } from 'react'
import ComboboxV1 from '../../../component/combobox/ComboboxV1'
import './endorsement-test.css'

class EndorsementResonText extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const MetaData = this.props.data;
        return (
            <div className="end-main-div cls-np-main-container">

                <br></br>
                <br></br>
                <table >
                    <tbody>
                        <tr>
                            <td >
                                <div className="div_label"> Endorsement Reason</div>
                            </td>
                            <td  >
                                <div className="div_label_wider">
                                    <ComboboxV1
                                        placeholder="Please select a reason"
                                        hadlechangeUD={this.props.hadlechangeUD}
                                        options={MetaData}
                                        selectedValue={this.props.selectedValue}
                                        name='Reason'
                                    ></ComboboxV1>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <div className="div_label"> Endorsement Remark</div>
                            </td>
                            <td  >
                                <div className="div_label_wider">
                                <input 
                                            name={'Remark'}
                                            ref={'Remark'}
                                           
                                            type="text"
                                            placeholder="Endosement Remark"
                                            onChange={(event) => this.props.hadlechangeUD(event, 'Remark')} 
                                            value={this.props.remark}
                                            />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <br></br>            </div>

        )

    }

}

export default EndorsementResonText;