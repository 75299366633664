import React, { Component } from "react"
import axios from "axios"
import PremiumPaymentDataService from '../../../DataApiService/PremiumPaymentDataService'
import CommissionPaymentUploadValidateGrid from './CommissionPaymentUploadValidateGrid'
import Button from '@material-ui/core/Button';


class PaymentDataUpload extends Component {
  constructor(props) {
    super(props)
    this.state = { documentNo: 'No Document',message: '', status: '', result: [] }
    this.setDocumentNo = this.setDocumentNo.bind(this);
    this.loadValidation=this.loadValidation.bind(this);

    
  }

  setDocumentNo() {
    this.sendFiles();
  }
  sendFiles() {
    var formData = new FormData();
    var imagefile = document.querySelector('#file');
    formData.append("import-excel", imagefile.files[0]);
    axios.post('http://134.209.101.133:3001/import-excel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      this.setState({ documentNo: res.data.document, isPending: false });




      
    }).
    
    
    
    catch(err => console.log(err)).finally(()=>{

      console.log('finale');
    //  this.inputElement.click();
    })
  
  }

  loadValidation() {
    const dataPaymentService = new PremiumPaymentDataService();
    dataPaymentService.validateUploadCommissionInData({documentNo:this.state.documentNo}).then(data => {
        const res_message = (data.status == 1 ? 'Data sucessfully saved!' : data.error)
        this.setState({ message: res_message, status: data.status, result: data }
          //  ,            this.loadPolicyDetails(data.status == 1)
        );
    }
    );  
}


  render() {
    var documentNo=this.state.documentNo;

    return (<div>
      <body>
        <div class="container">
          {this.state.documentNo}
      
          <input
            id="file"
            type="file"
            name="import-excel"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
       
          <div onClick={this.setDocumentNo}  className="btn btn-dark" >Upload File</div>

          <div onClick={this.loadValidation} className="btn btn-warning" >Validate</div>
          <input ref={input => this.inputElement = input} onClick={this.loadValidation} />
          <CommissionPaymentUploadValidateGrid dataSource={this.state.result.data}></CommissionPaymentUploadValidateGrid>
        <hr></hr>
          <Button variant="contained" color="secondary"  href={`/policy/payment/comin/upload-verify/${documentNo}`}>
      Confirm
      </Button>
        </div>
      </body>
    </div>)
  }
}
export default PaymentDataUpload