import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '@material-ui/core/Button';


import TaskFlowService from '../DataApiService/TaskFlowService'

const TaskConfirmWindow = ({ policy_class_type, workFlowData, show_popup, pd_policy_entry_id, policy_ref_no }) => {
    const [products, setProducts] = useState([]);
    const [show, setShow] = useState(show_popup);
    const handleClose = () => setShow(false);
    const taskFlowService = new TaskFlowService();
    const [result, setResult] = useState([]);

    const completeTask = () => {

        let wkRquest = { ...workFlowData, pd_policy_entry_id: pd_policy_entry_id }
        taskFlowService.setTaskComplete(wkRquest).then(data => {
            console.log("Complete response", data);
            setResult(data)
            window.location.href = "/my-dashboard";
        }


        );


    }


    const _page_name = 'policy'

    useEffect(() => {
        taskFlowService.getPendingWorks(policy_class_type).then(data => setProducts(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const policyActionURL = (policy_action, type) => {

        var returnUrl = '/edit/';
        if (policy_action == 'View') {

            returnUrl = '/edit/'
        } else if (policy_action == 'Renewal') {

            returnUrl = '/renewal/'
        } else if (policy_action == 'Endorsement') {

            returnUrl = '/endosement/'
        }
        else if (policy_action == 'Update Payment') {

            returnUrl = '/payment/update-payment/'
        }
        //

        let link_policy_class_type = (type == "G" ? "general" : "life")


        return `/${link_policy_class_type.toLowerCase()}${returnUrl}`;


    }


    return (

        <Modal show={show} onHide={handleClose} dialogClassName="cls_modal_small_pop" >
            <Modal.Header closeButton style={{ backgroundColor: '#239B56', color: 'white' }}>
                <Modal.Title >Work Flow - Task Completion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               


                <table className='wf_table_item_list'>
                    <tr>
                        <td><label> {workFlowData.action_type_name} </label>  </td>
                        <td>   {workFlowData.action_ref_no}</td>
                        <hr></hr>
                    </tr>
                    <tr>
                        <td> <label>Policy Ref No</label> </td>
                        <td>     {policy_ref_no}</td>
                    </tr>
                    <tr>
                        <td>  <label>Entry Id</label></td>
                        <td>{pd_policy_entry_id}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>

                </table>

            </Modal.Body>
            <Modal.Footer>
                <Button color='#0B5345' onClick={completeTask} style={{ backgroundColor: '#0B5345', color: 'white' }}>
                    {workFlowData.action_type_name}
                </Button>


                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

    );
}
export default TaskConfirmWindow