import axios from 'axios';
import { ApiList } from '../DataOperation/apilink';



export default class ReportDataService{


    getPremiumOutstandingData(requsted_data) {
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };       
        return axios.post(ApiList.rp_premium_outstanding_report, request_json).then(res => res.data);
    }
   

}
