import React, { Component } from "react";
import axios from "axios"
import { ApiList } from '../../DataOperation/apilink'
import "./ContactDetailComCSS.css"
import ComboboxV1 from '../combobox/ComboboxV1'
import AutoCompleteText from '../AutoCompleteText/AutoCompleteText'
import '../policy/policybasic.css'
import { Checkbox } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import InsuredSearchPopupData from '../../Ibs-pages/ibs-insured/InsuredSearchPopup'
import ContactDetailComView from "./ContactDetailComView";
import InsuredDataKeyValTemplate from "./InsuredDataKeyValTemplate"

class AssignInsured extends Component {
    constructor(props) {
        super(props)
//alert(props.insured_id)
     
        this.state = {
            occupationData: [],
            insured_id: props.insured_id,
            show: false,
            insuredDataRow: {
                insured_address_city: '',
                insured_address_no: '',
                insured_address_road: '',
                insured_contact_no: '',
                insured_email: '',
                insured_initials: '',
                insured_nic_no: '',
                business_reg_no: '',
                insured_surname: '',
                insured_id: 0


            },
          
            insuredType: '',
            insuredTypeName: '',
            insuredDataKeyVal: []

        }
        //   this.autoCompleteHandleChange=this.autoCompleteHandleChange().bind(this);
        //  this.setInputValuesData= this.setInputValuesData.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.hadlechangeUD = this.hadlechangeUD.bind(this);
        this.handleModalSelect = this.handleModalSelect.bind(this);
        this.getInsuredDataByIdKeyValue = this.getInsuredDataByIdKeyValue.bind(this);


    }

    componentDidMount() {
     //   this.getDataById();
        //   this.getInsurerList();
       // alert(this.props.insured_id);
     //  alert(this.props.insured_id);
     //  this.getInsuredDataByIdKeyValue( eval(this.props.insured_id) );
       // this. getInsuredDataById(eval(this.props.insured_id));
     //  this.getInsuredDataById(this.props.insured_id);
     //  this.getInsuredDataByIdKeyValue(this.props.insured_id);
    }

componentDidUpdate(){

  //  this.getInsuredDataByIdKeyValue( eval(this.props.insured_id) );
}
componentWillMount() {
   // alert(this.props.insured_id);
  }


    getInsuredDataById(_param_id) {
        axios.post(ApiList.pd_md_insured_by_id, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1,
            param_id: _param_id,
            page: 'assuser'
        })
            .then((response) => {
                var insuredDataR = response.data.insured_data[0];
                // console.log("pd_md_insured_by_id", insuredData);
                if (insuredDataR != undefined) {
                    this.setState({
                        insuredData: {
                            insured_address_city: insuredDataR.insured_address_city,
                            insured_address_no: insuredDataR.insured_address_no,
                            insured_address_road: insuredDataR.insured_address_road,
                            insured_contact_no: insuredDataR.insured_contact_no,
                            insured_email: insuredDataR.insured_email,
                            insured_id: insuredDataR.insured_id,
                            insured_initials: insuredDataR.insured_initials,
                            insured_nic_no: insuredDataR.insured_nic_no,
                            business_reg_no: insuredDataR.business_reg_no,
                            insured_surname: insuredDataR.insured_surname
                        }

                    })
                }

            }, (error) => {
                console.log(error);
            });

    }


    getInsuredDataByIdKeyValue(_param_id) {
       // alert(_param_id);
        axios.post(ApiList.pd_md_insured_by_id_key_value, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1,
            param_id: eval(_param_id),
            page: 'assuser'
        })
            .then((response) => {

                var insuredDataR = response.data.insured_data;
                var insuredDataRD = response.data.insured_data_row;      
                this.props.setInsuredSelectedIdValue(_param_id)   
                if (insuredDataR != undefined && insuredDataRD!= undefined) {
                    var insuredDataRowV = response.data.insured_data_row[0]; 
                   this.props.setSVATRegistedValue(insuredDataRowV.is_svat_registered)       


                    this.setState({
                        insuredDataKeyVal: insuredDataR,
                        insuredDataRow: insuredDataRowV
                    })
                }

            }, (error) => {
                console.log(error);
            });

    }




    getInsurerList() {
        axios.post(ApiList.md_policy_md, {
            user_id: 1
        })
            .then((response) => {
                this.setState({
                    occupationData: response.data.occupation,
                }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });
    }
    getDataById() {
        axios.post(ApiList.pd_md_nic_list, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1
        })
            .then((response) => {
                let array_nic_list = []; 
                console.log(array_nic_list);
                this.setState({
                    nic_list: array_nic_list
                }, () => {
                    // console.log("load data ", this.state)
                })
            }, (error) => {
                console.log(error);
            });
    }


    setInputValuesData(_param_id) {
        axios.post(ApiList.pd_md_insured_by_id, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1,
            param_id: _param_id
        })
            .then((response) => {
                console.log("response", response);
            }, (error) => {
                console.log(error);
            });
    }

    handleModal(itemtittle) {
        this.setState({ show: !this.state.show })       
    }


    handleModalSelect(itemtittle) {
        this.setState({ show: !this.state.show, insured_id: itemtittle })
        this.getInsuredDataById(itemtittle);
        this.getInsuredDataByIdKeyValue(itemtittle);

    }
    hadlechangeUD(e) {
        let iName = (e === "I" ? " [Individual]" : " [Cooperate]")
        this.setState({ insuredType: e, insuredTypeName: iName })       
    }

    render() {  
        
        const insuredTypeData = [
            { value: 'I', label: 'Individual' },
            { value: 'C', label: 'Cooperate' }
        ]       
        const isIndividual = (this.props.insuredType == "individual");
        return (
            <div> <div className="css_section_title">  {this.props.legend_title} {this.state.insuredTypeName} </div>

                <div className="cls-np-main-container">
                
                    <table>
                        <tbody>
                            <tr style={{ display: isIndividual ? 'none' : '' }}>
                                <td>
                                <div className="div_label">Client Type</div> </td>
                                <td>
                                <div className="div_label_wide">
                                    <ComboboxV1
                                        hadlechangeUD={this.hadlechangeUD}
                                        //   selectedValue={this.props.data.title}
                                        options={insuredTypeData}
                                        name={'client_type'}
                                    ></ComboboxV1> </div>
                                </td>
                            </tr>
                            <tr >
                                <td></td>
                                <td>
                                    <Button variant="contained"
                                        onClick={() => this.handleModal(this.state)}>
                                        Select the client
                                    </Button>
                                </td>
                            </tr>
                            <tr >
                                <td colSpan={2}>
                              
                                    <InsuredDataKeyValTemplate
                                        insuredData={this.state.insuredData}
                                        insuredDataKeyVal={this.state.insuredDataKeyVal}
                                        insuredDataKeyValProp={this.props.insuredDataKeyVal}

                                    ></InsuredDataKeyValTemplate>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Modal show={this.state.show} onHide={() => this.handleModal('hide')} >
                    <Modal.Header closeButton>Client List</Modal.Header>
                    <Modal.Body>
                        <InsuredSearchPopupData handleModal={this.handleModalSelect} insuredType={this.state.insuredType}></InsuredSearchPopupData>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col>  <Button onClick={() => this.handleModal("Save")}>Ok</Button>   </Col>
                            <Col></Col>
                            <Col> <Button onClick={() => this.handleModal("Close")}>Cancel</Button>   </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }


}

export default AssignInsured