import React from "react"
import Navbar from "../../component/Navbar/NavbarPolicy"
import TileComponent from '../../component/TileNavigator/TileComponent'
import '../../App.css'

const PolicyMain = () => {

  return (<div className="App">
    <Navbar />
    <br></br>
    <h1>Policy</h1>
    <br></br><hr></hr>
    <div>

    </div>
    <TileComponent />
  </div>
  );

}
export default PolicyMain;