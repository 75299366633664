import React, { Component } from "react"
import PaymentMenu from '../../../component/Navbar/Payment/PaymentMenu'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import PolicySearch from '../../ibs-policy/PolicyEntrySearch'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

class PaymentRecipt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      paymentList: []
    }

  }

  handleModal(e) {
    console.log(e);
    this.setState({
      paymentList: [
        {
          policy_ref_no: '090099818988',
          policy_no: '00990098',
          period_from: '12000'

        }, {
          policy_ref_no: '0900219988988',
          policy_no: '00990098',
          period_from: '3000'

        }
        , {
          policy_ref_no: '0900912988988',
          policy_no: '00990098',
          period_from: '600'

        }



      ]
    })
    this.setState({ show: !this.state.show })
  }


  render() {
    const statusBodyTemplate = (rowData) => {
      return (
        <div>
          <input type="text" style={{ textAlign: 'right' }} value={rowData.period_from}></input>
        </div>);
    }

    const paymentListData = this.state.paymentList;

    return (<div>
      <PaymentMenu></PaymentMenu>


      <div>
        <h2 align='center'>Payment Recipt</h2>


        <Container>
          <Row>
            <Col>Date</Col>
            <Col>{new Date().toString()}</Col>
          </Row>
          <Row>
            <Col>Payment Mode</Col>
            <Col>Direct to Insurer</Col>
            <Col>Our Account</Col>
          </Row>
          <Row>
            <Col>  <Button onClick={() => this.handleModal()}>Add Items</Button>  </Col>
            <Col></Col>
            <Col> </Col>
          </Row>
        </Container>



        <div className="modalClass">





          <Modal show={this.state.show} onHide={() => this.handleModal()} >
            <Modal.Header closeButton>Select Policy Details</Modal.Header>
            <Modal.Body>
              <PolicySearch></PolicySearch>


            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Col>  <Button onClick={() => this.handleModal("Save")}>Ok</Button>   </Col>
                <Col></Col>
                <Col> <Button onClick={() => this.handleModal("Close")}>Cancel</Button>   </Col>
              </Row>


            </Modal.Footer>
          </Modal>

        </div>


        <Container>
          <Row>

            <DataTable value={paymentListData} paginator rows={10} >

              <Column field="policy_ref_no" style={{ width: '10em' }} header="Policy Ref No" filter={false}></Column>
              <Column field="policy_no" style={{ width: '10em' }} header="Policy No" filter={false}></Column>
              <Column field="period_from" style={{ width: '10em' }} header="Reference No" filter={false}></Column>
              <Column field="policy_no" style={{ width: '10em' }} header="Amount" filter={false}></Column>
              <Column field="period_from" style={{ width: '10em', textAlign: 'right', padding: '5px' }} header="Paid Amount" filter={false}></Column>

              <Column header=" " body={statusBodyTemplate}></Column>


            </DataTable>
          </Row>
        </Container>
        <Container>

          <Row>
            <Col>  <Button onClick={() => this.handleModal()}>Save</Button>  </Col>
            <Col>    <Button onClick={() => this.handleModal()}>Print</Button>  </Col>
            <Col> </Col>
            <Col> </Col>
            <Col> </Col>
            <Col> </Col>
          </Row>
        </Container>

      </div>
    </div>)
  }

}

export default PaymentRecipt;