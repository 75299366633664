import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
      textAlign:"center",
      padding:"40px",
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function MUButton({uniqid,ClassType,masterEntryId}) {
  const classes = useStyles();
const linkClassType=(ClassType=="G"?"general":"life");
  return (
    <div className={classes.root}>
      
      <Button variant="contained" color="primary"  href={`/policy/${linkClassType}/newendorsement/debit/${uniqid}/${masterEntryId}`}>
        Debit Note
      </Button>


      <Button variant="contained" color="secondary"  href={`/policy/${linkClassType}/newendorsement/credit/${uniqid}/${masterEntryId}`}>
        Credit Note
      </Button>
     
   
    </div>
  );
}
