import React, { Component, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios'
import { ApiList } from '../../DataOperation/apilink'
import InsurerItem from './insurer-component/InsurerItem'
import InsurerAddNew from './insurer-component/AddInsurer'
import Navbar from "../../component/Navbar/NavbarMasterData"
import { Link } from "react-router-dom"
class Insurer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            insurer: []
        }
    }
    componentWillMount() {
        // console.log('will?')
        this.getInsurerList();
    }


    getInsurerList() {


        axios.post(ApiList.insurer_list, {
            user_id: 1
        })
            .then((response) => {

                console.log(response.data.data);
                this.setState({ insurer: response.data.data }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });

    }

    removeInsurance(inactive_Insurer) {
        axios.post(ApiList.insurer_inactive, inactive_Insurer)
            .then((response) => {
                if (response.data.data != undefined) {
                    console.log("update return value", response.data);
                    this.getInsurerList();
                } else {
                    this.setState({
                        validate_message: response.data.error
                    })
                }
            }, (error) => {
                console.log('error', error);
            });
    }

    removeToCollection(key) {
        const InactiveInsurer = { insurer_id: key, user_id: 1 }
        this.removeInsurance(InactiveInsurer);
    }

    render() {


        const statusBodyTemplate = (rowData) => {
            return (
                <div>
                    <Link to={`/insurer/edit/${rowData.value}`}>Edit</Link>
                </div>);
        }

        const statusBodyTemplateView = (rowData) => {
            return (
                <div>
                    <Link to={`/insurer/details/${rowData.value}`}>View</Link>
                </div>);
        }

        const statusBodyTemplateDelete = (rowData) => {
            return (
                <div>
                    <Link onClick={() => { if (window.confirm('Delete the item?')) { this.removeToCollection(rowData.value, this) }; }}>Delete</Link>

                </div>);
        }

        const insurer_list = this.state.insurer.map((insurer, i) => {
            return (<InsurerItem key={insurer.insurer_id} onListChange={this.getInsurerList.bind(this)} item={insurer}></InsurerItem>)

        })

        const insurer_list_table_source = this.state.insurer;



        return (
            <div>
                <Navbar></Navbar>
                        <div className="container">

                    <InsurerAddNew onListChange={this.getInsurerList.bind(this)}></InsurerAddNew>
                </div>


                <div className="container">
                    <ul>
                        <div className="ig-table-container">
                        <div className="card">
                            <DataTable value={insurer_list_table_source}>

                                <Column field="value" style={{ width: '2em', display: "none" }} header="Policy Ref No" filter={true}></Column>
                                <Column field="label" style={{ width: '40em' }}  header="Insurer" filter={true}></Column>

                                <Column header=" View"style={{ width: '2em', display: "none" }} body={statusBodyTemplateView}></Column>
                                <Column header=" Edit " style={{ width: '10em' }}  body={statusBodyTemplate}></Column>
                                <Column header=" Delete " style={{ width: '10em' }}  body={statusBodyTemplateDelete}></Column>

                            </DataTable>
                        </div>
                        </div>
                    </ul></div>




            </div>

        )
    }
}

export default Insurer