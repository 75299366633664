import React, { Component } from "react";
import { MenuItems } from "../Navbar/MenuPolicy"
import Button from '@material-ui/core/Button';




class TileComponent extends Component{





    render() {
        return(
          
               
                <div >
                    {MenuItems.map((item, index) => {
                        return (
                           
                          <div className="tile-row">

                                    <Button variant="contained" color="primary"  href={item.url} style={{width:"200px"}}>
                                    {item.tittle} 
      </Button>

                           </div>
                         
                        )
                    })}
                </div>
              
          
        )
    }
}

export default TileComponent

