import React, { useState, useEffect, Component } from "react";
import ReportService from '../Services/ReportServices'
import PremiumDetails from './ReportPremiumDetails'
import PolicyDetails from "./ReportPolicyData";
import './report.css'


const PolicySchedual = (props) => {

    const [data, setData] = useState([]);
    const [policyData, setpolicyData] = useState([]);
    const [premiumData, setpremiumData] = useState([]);
    // const [NoteType, setNoteType] = useState([]);  
    const reportService = new ReportService();

    useEffect(() => {
        reportService.getDebitNoteData({ debitno: props.entry_id }).then(data => {

            console.log("function", data);
            setData(data.data);
            setpolicyData(data.policy);
            setpremiumData(data.premium);   
        });
    }, [props.isClicked]);


    return <div className="css-main-note-payment-update">

        <div>
            <div className="css-dbnote-page" style={{ width: 874, height: 1240, padding: "20px" }} >
                <div className="report-body" id="GFG">
                    <div className="css-note-type-text"> </div>
                    <PolicyDetails policyData={policyData}></PolicyDetails>
                    <br></br>
                    <PremiumDetails premiumData={premiumData}></PremiumDetails>
                </div>
            </div>
        </div>
    </div>
    }

export default PolicySchedual
