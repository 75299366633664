import React, { Component } from "react"
import NavbarSub from '../../../component/Navbar/NavbarPolicy'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Button } from "react-bootstrap";
import SlideList from "../../../component/SlideList/SlideList";
import PolicyTransactionList from "../../ibs-policy/entryList/PolicyTransactionListCommissionOut";
import PremiumPaymentReceipt from "./CommissionOutVoucher"
import PolicyDetailsDataService from "../../../DataApiService/PolicyDetailsDataService"
import PremiumPaymentDataService from "../../../DataApiService/PremiumPaymentDataService"
import ReceptDetails from "../payment-recipt/VoucherDetails"


class CommissionOutPaidUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            EntryList: [],
            TotalAmount: 0,
            policyDetails: [],
            receiptList: [],
            message: '',
            status: 0
        }

        this.myHandleChange = this.myHandleChange.bind(this);
        this.hadlechangeUD = this.hadlechangeUD.bind(this);
        this.setAmountmyHandleChange = this.setAmountmyHandleChange.bind(this);
        this.setTotalPayment = this.setTotalPayment.bind(this);
        this.savePayment = this.savePayment.bind(this);
        this.saveButtonClick = this.saveButtonClick.bind(this);
        this.valueChange = this.valueChange.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.loadPolicyDetails = this.loadPolicyDetails.bind(this);
        this.clearData = this.clearData.bind(this);
        this.deletePayment=this.deletePayment.bind(this);


    }


    clearData() {

        Array.from(document.querySelectorAll("input,textarea")).forEach(
            input => (input.value = "")
        );

        this.setState({
            receipt_date: '', receipt_ref_no: '', receipt_method: '', TotalAmount: 0
        })

    }

    isFormValid() {

        if (!(this.state.receipt_date && this.state.receipt_ref_no && this.state.receipt_method)) {
            this.setState({ message: 'Pleae fill the required fields', status: 0 });
            return false;
        }
        else if (this.state.TotalAmount == 0) {
            this.setState({ message: 'Pleae check the receipt amount', status: 0 });
            return false;
        }
        else {
            return true;
        }
    }






    valueChange(s, e) {

        if (s.target != null && s.target.name) {
            this.setState({ [s.target.name]: s.target.value, message: '' })
        } else {
            this.setState({ [e]: s, message: '' })
        }
    }

    saveButtonClick() {
        if (this.isFormValid()) {
            this.savePayment();
        }
    }


    savePayment() {
        const dataPaymentService = new PremiumPaymentDataService();
        dataPaymentService.updateCommissionOutPaidVoucherData(this.state).then(data => {
            const res_message = (data.status == 1 ? 'Data sucessfully saved!' : data.error)
            this.setState({ message: res_message, status: data.status, result: data }
                ,
                this.loadPolicyDetails(data.status == 1)
            );
        }
        );
    }
    deletePayment(request_json) {
        const dataPaymentService = new PremiumPaymentDataService();
        dataPaymentService.deleteCommissionOutPaidVoucherData(request_json).then(data => {
            const res_message = (data.status == 1 ? 'Data sucessfully saved!' : data.error)
            this.setState({ message: res_message, status: data.status, result: data }
                ,
                this.loadPolicyDetails(data.status == 1)
            );
        }
        );
    }

    hadlechangeUD(rowData, s, e) {
        console.log(e);
        console.log(rowData);
        const mealsAsStrings = this.state.EntryList;
        const calorieStringTotal = mealsAsStrings.reduce((totalCalories, meal) => totalCalories + parseInt((meal.IsSelected == true ? meal.PaidAmount : 0), 10), 0);
        this.setState({

            TotalAmount: calorieStringTotal
        })
    }


    componentDidMount() {
        this.loadPolicyDetails(true);
    }


    loadPolicyDetails(IsLoad) {
        if (IsLoad) {
            let i_id = this.props.match.params.id;
            let eid = this.props.match.params.eid;
            const dataService = new PolicyDetailsDataService();
            dataService.getPolicyDetailsKeyVal({
                user_id: 1,
                pr_key: i_id,
                branch_id: 1
            }).then(data =>

                this.setState({ policyDetails: data.data, EntryList: data.dataEntryList, TotalAmount: 0, receiptList: data.dataVoucherListCO }, this.clearData)
            );
        }

    }

    myHandleChange(rowData, s, e, q) {
        let currentList = this.state.EntryList;
        let filteredElement = [];
        for (let index = 0; index < currentList.length; index++) {
            if (currentList[index].ibs_entry_id == rowData.ibs_entry_id) {
                filteredElement.push({ ...rowData, IsSelected: e })
            } else {
                filteredElement.push(currentList[index])
            }
        }
        this.setState({ EntryList: filteredElement })
    }


    setAmountmyHandleChange(rowData, val) {
        let currentList = this.state.EntryList;
        let filteredElement = (currentList.length > 0 ? currentList.filter(e => e.ibs_entry_id == rowData.ibs_entry_id) : []);
        if (filteredElement.length > 0) {

            this.myHandleChange({ ...rowData, CommissionOutDue: val }, '', true);
        }
    }

    setTotalPayment() {
        const mealsAsStrings = this.state.EntryList;
        const calorieStringTotal = mealsAsStrings.reduce((totalCalories, meal) => totalCalories + parseFloat((meal.IsSelected == true ? meal.CommissionOutDue : 0), 10), 0);
        this.setState({
            TotalAmount: calorieStringTotal
        })
    }


    render() {
        return (
            <div >
                <NavbarSub pagetitle="New Policy"></NavbarSub>

                <Row style={{ "margin": 0 }}>
                    <Col sm={2}>
                        <SlideList Caption={"Premium Commission Paid Update"} SlideListDataSource={this.state.policyDetails}></SlideList>
                        <div>

                            <div className={this.state.status == 1 ? 'css_lbl_success' : 'css_lbl_error'}>
                                {this.state.message}
                            </div>
                        </div>
                        <hr></hr>
                        <Button style={{ "width": "150px", "height": "30px" }}
                            onClick={() => this.saveButtonClick()}
                            variant="success"

                        >Save Receipt</Button>
                    </Col>
                    <Col sm={10}>
                        <Row>
                            <Col sm={12}>
                                <PolicyTransactionList
                                    entry_list={this.state.EntryList}
                                    policy_action="View"
                                    policy_class_type="General"
                                    myHandleChange={this.myHandleChange}
                                    setAmountmyHandleChange={this.setAmountmyHandleChange}
                                    TotalAmount={this.state.TotalAmount}
                                    hadlechangeUD={this.hadlechangeUD}
                                ></PolicyTransactionList>
                            </Col>
                            <br></br>



                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Button style={{ "width": "150px", "height": "30px" }}
                                    onClick={() => this.setTotalPayment()}
                                    variant="danger"

                                >Apply Amount</Button>
                            </Col>

                        </Row>
                 
                        <Row>
                            <Col sm={12}>
                                <PremiumPaymentReceipt
                                    valueChange={this.valueChange}
                                    TotalReciptValue={this.state.TotalAmount}>
                                </PremiumPaymentReceipt>
                            </Col>
                        </Row>
                        <Row>

                            <Col sm={12}>
                                <ReceptDetails DataSource={this.state.receiptList} OnDelete={this.deletePayment}/>
                            </Col>
                        </Row>
                    </Col>


                </Row>

              


            </div>
        )

    }
}

export default CommissionOutPaidUpdate;


