import React, { Component, useState } from "react"
import axios from "axios"
import { ApiList } from "../../../DataOperation/apilink"
import ComboBranch from '../../../component/combobox/ComboboxV1'

class AddPolicyType extends Component {


    constructor(props) {
        super(props)
        this.state = { policy_type_code: '', validate_message: '', insuranceClassData: [] }
        this.onChange = this.onChange.bind(this);

    }
    onChange(val, e) {
        if (val.target != null && val.target.name) {       
            this.setState({ [val.target.name]: val.target.value, user_id: 1 })       
        } else {
                     this.setState({ [e]: val, user_id: 1 })       
          
        } 
    }

    

    componentWillMount() {
        this.getPolicyClass();

    }
    getPolicyClass() {
        axios.post(ApiList.pd_policy_class_data, {
            user_id: 1,
            insurance_class_type: "G"
        })
            .then((response) => {
                console.log("response pd", response)
                this.setState({
                    insuranceClassData: response.data.data

                }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });
    }


    addInsurer(newInsurer, e) {
        axios.post(ApiList.pd_policy_type_create, newInsurer)
            .then((response) => {


                if (response.data.data != undefined) {
                    this.props.onListChange();
                    e.target.reset();
                    this.setState({
                        validate_message: ''
                    })

                } else {
                    this.setState({
                        validate_message: response.data.error
                    })
                }


            }, (error) => {
                console.log('error', error);
            });



    }



    onSubmit(e) {

        //let m_policy_type_code = this.refs.policy_type_code.value
        let m_policy_type_name = this.refs.policy_type_name.value
        let m_policy_type_code = m_policy_type_name.substring(0, 3);
        if (m_policy_type_code.length > 0 && m_policy_type_name.length > 0) {
            const newInsurer = { 
                policy_type_code: m_policy_type_code, 
                policy_type_name: m_policy_type_name, 
                user_id: 1 ,
                ins_class_id:this.state.ins_class_id
            }
            this.addInsurer(newInsurer, e);

        }
        else {
            this.setState({

                validate_message: '*'
            })

        }


        e.preventDefault();

    }


    render() {


        return (
            <div>
                <form onSubmit={this.onSubmit.bind(this)}>
                    <li className="list-group-item">
                        <div className="container">
                            <div className="row">
                                <div className="master-data-page-tittle"> <span>Add New {this.props.master_page_tittle}</span></div>
                            </div>
                            <div className="master-data-page-body">
                                <div className="row">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td> <label ></label></td>

                                                {/*  <td>     <input
                                                    type="text" onChange={this.onChange}
                                                    name="policy_type_code" ref="policy_type_code"
                                                    placeholder= "Code"                                           
                                                    id="txt_policy_type_code"></input>
                                                </td>
                                                
        */}

                                                <td> <label >Name</label></td>
                                                <td>     <input
                                                    type="text" onChange={this.onChange}
                                                    name="policy_type_name" ref="policy_type_name"
                                                    placeholder="Name"
                                                    id="txt_policy_type_name"></input>
                                                </td>
                                                <td> <label >Class</label></td>
                                                <td>

                                                    <ComboBranch
                                                        hadlechangeUD={this.onChange}
                                                        options={this.state.insuranceClassData}
                                                        name='ins_class_id'

                                                    ></ComboBranch>
                                                </td>


                                                <td>    <button type="submit" value="save" className="btn btn-info">(+)Add</button></td>
                                                <td> <label ></label></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>    <div className="row">
                                    <div col="col">
                                        <spa>{this.state.validate_message}</spa>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </li>
                </form>
            </div>
        )

    }



}

export default AddPolicyType