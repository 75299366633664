import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom"

class ButtonNavigator extends Component {

    constructor(props) {
        super(props);

    }


    render() {

        const ButtonTemplate = ({ item ,policy_action}) => {

            if (item.url != "") {
                return (
                    <ButtonWithClickLink item={item} policy_action={policy_action}></ButtonWithClickLink>
                )
            } else {
                return (
                    <ButtonWithClickFuntion item={item}  policy_action={policy_action}></ButtonWithClickFuntion>
                )
            }
        }





        const ButtonWithClickFuntion = ({ item,policy_action }) => {
            return (
                <Button variant="contained" color={item.color}
                    onClick={() => this.props.handleModal(item.tittle,policy_action)}>
                    {item.tittle}
                </Button>
            )
        }

        const ButtonWithClickLink = ({ item }) => {
            return (
                <>
                <Button variant="contained" color={item.color}>
               
                <Link className='nav-button-link' to={item.url} > {item.tittle} </Link>
                </Button>
            </>
            
            
            )
        }

        const VerticallList = () => {
            return (
                <div>
              
                    {this.props.MenuItems.map((item, index) => {
                        return (
                            <Row>
                                   <br/>
                                <BaseButtonControl item={item}></BaseButtonControl>
                                <br/>
                                <br/>
                                <br/>
                            </Row>
                              
                        )
                    })}
                </div>
            )
        }
        const HorizontalList  = ({policy_action}) => {
            return (
                <Row>
                    {this.props.MenuItems.map((item, index) => {
                        return (

                            <BaseButtonControl item={item} policy_action={policy_action}></BaseButtonControl>

                        )
                    })}
                </Row>
            )
        }

        const BaseButtonControl = ({ item ,policy_action}) => {
            return (
                <div>
                    <Col >
                        <ButtonTemplate item={item}  policy_action={policy_action}></ButtonTemplate>
                    </Col>

                </div>
            )
        }

        const ButtonTemplateContainer = ({ layoutType ,policy_action}) => {
            if (layoutType == "V") {
                return (

                    <VerticallList></VerticallList>
                )
            } else {

                return (<HorizontalList policy_action={policy_action}></HorizontalList>)
            }


        }

        return (
            <div>
                <br></br>
                <label className='header-label-page-nav'>{this.props.headerName}</label>
                <hr></hr>

                <div>

                <ButtonTemplateContainer layoutType={this.props.layoutType} policy_action={this.props.policy_action}></ButtonTemplateContainer>
                </div>
            </div>

        )
    }

}
export default ButtonNavigator;