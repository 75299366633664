import React, { Component } from "react";
import { Link } from "react-router-dom"





class EntryList extends Component {
  constructor(props) {
    super(props)
  }
  onTransactionIdClicked(debitNo) {
  }



  render() {
    const CurrentEntryId=this.props.CurrentEntry;

    const ClassType = (this.props.ClassType == "G" ? "general" : "life");
    const StatusBodyTemplate = ({ Id }) => {
      return (
        <div>
          <Link className="btn btn-primary" to={`/debit-note-print/${Id}`} target="_blank">Print</Link>
        </div>);
    }

    const DebitNote = ({ Id, Eid,EntryType }) => {
      return (
        <div>
          <Link className="btn btn-info" to={`/${EntryType}/policy/${ClassType}/edit/${Id}/${Eid}`} target="_blank">Edit</Link>
        </div>);
    }


    const CreditNote = ({ Id, Eid ,EntryType}) => {
      return (
        <div>
          <Link className="btn btn-info" to={`/${EntryType}/policy/${ClassType}/edit/${Id}/${Eid}`} target="_blank">Edit</Link>
        </div>);
    }

    const ViewOnly = ({ Id, Eid }) => {
      return (
        <div>
          <Link className="btn btn-info" to={`/policy/${ClassType}/view/${Id}/${Eid}`} target="_blank">View</Link>
        </div>);
    }



    return (
      <div style={{ width: "100%", height: "200px", overflowY: "scroll" }}>
        {this.props.EntryList != undefined &&
          <div >
          
            {this.props.EntryList.map((item, index) => {
              return (
                <div className={CurrentEntryId==item.entry_id?'css_selected_entry':'css_deselected_entry'}>

                  
                  <div style={{ display: "inline" }}>
                    <table width="100%" className="css-entry-list-table">
                      <tbody><tr><td>
                        <span >{item.entry_id} </span>
                      </td>

                        <td>
                          {(item.note_type == 'C' ? <CreditNote 
                          Id={item.policy_ref_no} 
                          EntryType={item.entry_type.toLowerCase()}
                          Eid={item.entry_id}>

                          </CreditNote> : 
                          <DebitNote 
                          EntryType={item.entry_type.toLowerCase()}
                          Id={item.policy_ref_no} 
                          Eid={item.entry_id}>

                          </DebitNote>)}

                        </td>
                        <td><ViewOnly Id={item.policy_ref_no} Eid={item.entry_id}></ViewOnly></td>
                        <td>

                          <StatusBodyTemplate Id={item.entry_id}  ></StatusBodyTemplate>
                        </td>
                      </tr>
                        <tr><td colSpan="3"><span className="css_e_time">{item.e_time}</span></td></tr>
                      </tbody></table>
                  </div>

                </div>

              )
            })}
          </div>
        }
      </div>
    )
  }
}

export default EntryList

