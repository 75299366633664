import React, { Component } from "react";
import { MetaClient } from "../../ibs-meta-data/FieldNames";
import "./ContactDetailComCSS.css"
import '../policy/policybasic.css'


class ContactDetailComView extends Component {
    constructor() {
        super()
        this.state = {   
        }     
    }

    componentDidMount() {
        console.log("componentDidMount")    
    }


    render() {

        const isIndividual =  (this.props.insuredType == "individual");

        return (
            <div> <div className="css_section_title">  {this.props.legend_title} </div>
                <div className="cls-np-main-container">
                    <table>
                        <tbody>
                            <tr  >
                                <td>Business Reg.No </td>
                                <td>
                                {this.props.insuredData.business_reg_no}
                                </td>
                            </tr>
                            <tr  >
                                <td>{MetaClient.NIC_OR_PASSPORT}</td>
                                <td>
                                {this.props.insuredData.insured_nic_no}
                                </td>
                            </tr>
                            <tr  >
                                <td>Tittle</td>
                                <td>
                                </td>
                            </tr>
                            <tr  >
                                <td>Initials </td>
                                <td>
                                {this.props.insuredData.insured_initials}
                                </td>
                            </tr>
                            <tr >
                                <td>
                                     Surname /Company Name
                                </td>
                                <td>
                                {this.props.insuredData.insured_surname}
                                </td>
                            </tr>






                       

                            <tr>
                                <td>Address</td>
                                <td>
                                    {this.props.insuredData.insured_address_no}

                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>   {this.props.insuredData.insured_address_road}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                {this.props.insuredData.insured_address_city}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Occupation
                                </td>
                                <td >

                                </td>
                            </tr>

                            <tr>
                                <td>Email</td>
                                <td>
{this.props.insured_email}

                                </td>
                            </tr>
                            <tr>
                                <td>Mobile No</td>
                                <td></td>
                            </tr>


                            <tr  >
                                <td>SVAT Registered</td>
                                <td>


                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }


}

export default ContactDetailComView