import React, { Component } from "react";
import Button from '@material-ui/core/Button';




class TileComponentPro extends Component{




    render() {


const MenuItems=this.props.TileSource;

        return(
          
               
                <div >
                    {MenuItems.map((item, index) => {
                        return (
                           
                          <div className="tile-row">

                                    <Button variant="contained" color="secondary"  href={item.url} style={{width:"200px"}}>
                                    {item.tittle} 
      </Button>

                           </div>
                         
                        )
                    })}
                </div>
              
          
        )
    }
}

export default TileComponentPro

