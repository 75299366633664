import React from "react"
import './pending-work.css'
import Card from 'react-bootstrap/Card'
import PendingWorksGrid from './PendingWorksGrid'
 /*
 https://react-bootstrap.github.io/components/cards/
 */

const PendingWorks = () => {

  return (<div className="ig-card-container">

<Card style={{ width: '100rem' }}>
  <Card.Body>
    <Card.Title>Pending Task List</Card.Title>
    <Card.Subtitle className="mb-2 text-muted">Pending task list for {localStorage.getItem("UserId")}</Card.Subtitle>
    <Card.Text>
 <PendingWorksGrid policy_action={"New"} policy_class_type={"G"}></PendingWorksGrid>
    </Card.Text>
  
  
  </Card.Body>
</Card>
  </div>
  );

}
export default PendingWorks;
