import React from "react";
import Navbar from "../../component/Navbar/Navbar";
import TabNavBar from '../../component/TabController/TabNavBar';
import './App.css';

const DashBoard = () => (<div className="App">
  <Navbar></Navbar>
  <TabNavBar></TabNavBar>
</div>
)
export default DashBoard;
