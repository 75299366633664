import React from "react"
import Navbar from "../../../component/Navbar/Navbar"
import PlacementReport from "./PlacementReport"
import BrokersQuarterlyReturnsReport from "./BrokersQuarterlyReturnsReport"
import RenewalReport from "./RenewalReport"

import '../../../App.css';

const IBSLReportDashBoard = () => {

  return (<div className="App">
    <Navbar></Navbar> 
    
    <PlacementReport></PlacementReport>
    <BrokersQuarterlyReturnsReport></BrokersQuarterlyReturnsReport>
    <RenewalReport></RenewalReport>
  </div>
  );

}
export default IBSLReportDashBoard;
