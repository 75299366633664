import React from "react";
import LoadingImage from './loading.gif'


export default function CircularIndeterminate({IsLoading}) {

    if(IsLoading)


  return (
    <div style={{ display: 'flex',position:"absolute",top:"40%",left:"40%" ,zIndex:"99"}}>
<img src={LoadingImage} alt="loading..."  width="100px"/>
    </div>
  )
  else
  return null
  
  ;
}