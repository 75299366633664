import React, { Component } from "react"
import ContactDetailComp from "../../../component/ContactDetail/AssignInsured"
import NavbarSub from '../../../component/Navbar/NavbarPolicy'
import PolicyBasic from '../../../component/policy/PolicyDetail'
import InvoiceDetails from '../../../component/policy/invoice-details'
import PremiumBrakeDown from '../../../component/policy/PremiumBrakeDown'
import CommissionBrakeDown from '../../../component/policy/CommissionBreakeDown'
import AgentData from '../../../component/policy/AgentDetails'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../ibs-policy/policynew.css'
import Tab from 'react-bootstrap/Tab'
import { Col, Row, Nav } from "react-bootstrap";
import axios from "axios"
import { ApiList } from "../../../DataOperation/apilink"
import EntryListPayment from '../../ibs-policy/entryList/EntryListPayment'
import EndorsementResonText from "../../ibs-policy/endorsement/EndorsementResonText"
import PolicySchedual from "../../../ReportPDF/DebitNote/PolicySchedual"
import PaymentDataUpdate from "./PaymentDataUpdate"

class PaymentUpdatePolicy extends Component {
    constructor(props) {
        super(props)
        this.state = {insured_id:"0",entry_id:'',isClicked:false}
        this.myHandleChange = this.myHandleChange.bind(this);   
        this.buttonHadleChange = this.buttonHadleChange.bind(this);
        this.setSVATRegistedValue = this.setSVATRegistedValue.bind(this);
        this.setInsuredSelectedIdValue = this.setInsuredSelectedIdValue.bind(this);
        this.autoCompleteHandleChange = this.autoCompleteHandleChange.bind(this);
        this.getCommissionRateByClass = this.getCommissionRateByClass.bind(this);
        this.getPropertyData= this.getPropertyData.bind(this);
        this.onTransactionIdClicked = this.onTransactionIdClicked.bind(this);
    }


    onTransactionIdClicked(debitNo) {
        console.log(debitNo);
        this.setState({entry_id: debitNo,isClicked:!this.state.isClicked});
        console.log(this.state.isClicked);
      }
    
setSVATRegistedValue(val){
    this.setState({is_svat_registed: val});
}
setInsuredSelectedIdValue(val){
    this.setState({insured_id: val,   policy_ref_no_enc: ''});
    this.getInsuredDataByIdKeyValue(val);
}

getInsuredDataByIdKeyValue(_param_id) {   
     axios.post(ApiList.pd_md_insured_by_id_key_value, {
         user_id: 1,
         pr_key: 1,
         branch_id: 1,
         param_id: eval(_param_id),
         page: 'assuser'
     })
         .then((response) => {
             var insuredDataR = response.data.insured_data;
             var insuredDataRD = response.data.insured_data_row; 
             if (insuredDataR != undefined && insuredDataRD!= undefined) {
                 this.setState({
                     insuredDataKeyVal: insuredDataR,
                 })
             }
         }, (error) => {
             console.log(error);
         });

 }






    componentDidMount() {
        let i_id = this.props.match.params.id; 
        if (i_id && i_id.length > 0) {
           this.getDataById();
           this.getPropertyData(i_id);
        } else {

            this.setState({
                policy_ref_no_enc: '',
                policy_ref_no: '00-000-000-00',
                pd_policy_entry_id: '000-000-0000-000', user_id: '1', policy_no: '', policy_type_id: '',
                branch_id: 'HO',
                insurer_id: '',
                ins_class_id: '',
                policy_type_id: '',
                period_from: '',
                period_to: '',
                sum_insured: '',
                vehicle_no: '',
                currency: 'LKR',
                invoice_no: '',
                cover_note_no: '',
                occupation_id: '',
                agent_id: '',
                agent_service_id: '',
                is_active: '',
                contact_person_id: '',
                insured_title: '',
                insured_name: '',
                insured_id: "0",
                insured_nic_no: '',
                insured_address_no: '',
                insured_address_road: '',
                insured_address_city: '',
                insured_contact_no: '',
                insured_email: '',
                insured_surname: '',
                insured_initials: '',
                contact_person_title: '',
                contact_person_name: '',
                contact_person_nic_no: '',
                contact_person_email: '',
                contact_person_address_no: '',
                contact_person_address_road: '',
                contact_person_address_city: '',
                contact_person_contact_no: '',
                premium_basic: 0, premium_srcc: 0, premium_terror: 0, premium_total: 0,premium_svat:0,
                commission_comm_rate_br_basic: 0,
                commission_comm_rate_br_srcc: 0,
                commission_comm_rate_br_terr: 0,
                agent_commission_comm_rate_br_basic: 0,
                agent_commission_comm_rate_br_srcc: 0,
                agent_commission_comm_rate_br_terr: 0,
                policy_entry: [],
                policy_com_rate: [],
                error_message: '',
                user_id: 1,
                COMBR: [],
                COMSR: [],
                COMTR: [],
                COMAGBR: [],
                COMAGSR: [],
                COMAGTR: [],
                entry_type: this.props.EntryType,
                note_type: this.props.NoteType,
                Reasons: [],
                Reason: 0,
                Remark:'',
                payment_mode:0,
                is_svat_registed:0,
                insuredDataKeyVal: []
            });
        }
    }

    getDataById() {
        let i_id = this.props.match.params.id;
        let eid = this.props.match.params.eid;
        const ActionType = this.props.ActionType;
        const ClassType=this.props.ClassType;
   
        axios.post(ApiList.pd_policy_by_id, {
            user_id: 1,
            pr_key: i_id,
            branch_id: 1,
            policy_entry_id:  eid,//TO DO
             class_type:ClassType
        })
            .then((response) => { 

               var p_data = response.data.data[0];

            this.setInsuredSelectedIdValue(p_data.insured_id);

                var p_data_entry = response.data.data_entry;
                var p_data_com_rate = response.data.data_rate;
                var p_data_com_rate_basic = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "Basic")]
                var p_data_com_rate_srcc = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "SRCC")]
                var p_data_com_rate_terror = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "Terrorism")]
                var p_data_com_rate_tax = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "Tax")]
                var p_data_com_rate_tax_svat = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "SVAT")]
                var m_premium_total = (
                    p_data_com_rate_basic.premium_debit +
                    p_data_com_rate_srcc.premium_debit +
                    p_data_com_rate_terror.premium_debit +
                    p_data_com_rate_tax.premium_debit+
                    p_data_com_rate_tax_svat.premium_debit

                );

             
                this.setState({
                    policy_ref_no_enc:this.props.match.params.id,
                    policy_ref_no: p_data.policy_ref_no,
                    pd_policy_entry_id: eid,
                    policy_no: p_data.policy_no,
                    insured_id: p_data.insured_id,
                    branch_id: p_data.branch_id,
                    insurer_id: p_data.insurer_id,
                    ins_class_id: p_data.ins_class_id,
                    policy_type_id: p_data.policy_type_id,
                    period_from: p_data.period_from,
                    period_to: p_data.period_to,
                    sum_insured: p_data.sum_insured,
                    vehicle_no: p_data.vehicle_no,
                    currency: p_data.currency,
                    invoice_no: p_data.invoice_no,
                    cover_note_no: p_data.cover_note_no,
                    occupation_id: p_data.occupation_id,
                    agent_id: p_data.agent_id,
                    agent_service_id: p_data.agent_service_id,
                    is_active: p_data.is_active,
                    contact_person_id: p_data.contact_person_id,

                    insured_title: p_data.insured_title,
                    insured_name: p_data.insured_name,
                    insured_nic_no: p_data.insured_nic_no,
                    insured_address_no: p_data.insured_address_no,
                    insured_address_road: p_data.insured_address_road,
                    insured_address_city: p_data.insured_address_city,
                    insured_contact_no: p_data.insured_contact_no,
                    insured_email: p_data.insured_email,
                    insured_surname: p_data.insured_surname,
                    insured_initials: p_data.insured_initials,

                    contact_person_title: p_data.contact_person_title,
                    contact_person_name: p_data.contact_person_name,
                    contact_person_nic_no: p_data.contact_person_nic_no,
                    contact_person_address_no: p_data.contact_person_address_no,
                    contact_person_address_road: p_data.contact_person_address_road,
                    contact_person_address_city: p_data.contact_person_address_city,
                    contact_person_contact_no: p_data.contact_person_contact_no,
                    contact_person_email: p_data.contact_person_email,
                 
                    policy_entry: p_data_entry,
                    Reason: p_data_com_rate[0].Reason,
                    Remark: p_data_com_rate[0].Remark,
                      
                    policy_com_rate: p_data_com_rate,
                    premium_basic: p_data_com_rate_basic.premium_debit,
                    premium_srcc: p_data_com_rate_srcc.premium_debit,
                    premium_terror: p_data_com_rate_terror.premium_debit,
                    premium_total: m_premium_total,
                   

           
                    premium_svat: p_data_com_rate_tax_svat.premium_debit,
 
                    commission_comm_rate_br_basic: p_data_com_rate_basic.com_in_rate,
                    commission_comm_rate_br_srcc: p_data_com_rate_srcc.com_in_rate,
                    commission_comm_rate_br_terr: p_data_com_rate_terror.com_in_rate,
                    agent_commission_comm_rate_br_basic: p_data_com_rate_basic.com_out_rate,
                    agent_commission_comm_rate_br_srcc: p_data_com_rate_srcc.com_out_rate,
                    agent_commission_comm_rate_br_terr: p_data_com_rate_terror.com_out_rate,

                    entry_type: this.props.EntryType,
                    note_type: this.props.NoteType

                }, () => {
                   this.getCommissionRateByClass(this.state.ins_class_id);
                  //  this.setInsuredSelectedIdValue(this.state.insured_id);
                })
            }, (error) => {
                //console.log(error);
            });

    }


    getPropertyData(enqId) {
        axios.post(ApiList.pd_policy_property_data, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1,
            param_id: enqId,
            page: 'assuser'
        })
            .then((response) => {
                var returnData = response.data.data;

                console.log("returnData",returnData);
                if (returnData != undefined) {
                    this.setState({
                        propertyData: returnData
                    })
                }

            }, (error) => {
                console.log(error);
            });

    }



    getCommissionRateByClass(ins_class_id) {
        axios.post(ApiList.pd_policy_rates, {
            user_id: 1, ins_class_id: ins_class_id
        })
            .then((response) => {
                this.setState({
                    COMBR: response.data.COMBR,
                    COMSR: response.data.COMSR,
                    COMTR: response.data.COMTR,
                    COMAGBR: response.data.COMAGBR,
                    COMAGSR: response.data.COMAGSR,
                    COMAGTR: response.data.COMAGTR,
                    Reasons: response.data.endorsement_reason
                }, () => {
                    //   console.log(this.state)
                })
            }, (error) => {
                //console.log(error);
            });
    }
    buttonHadleChange(e, s) {    
        console.log(this.state)   
       // this.createPolicy(this.state);
    }

    autoCompleteHandleChange(value, isDataAvailable) {

        if (isDataAvailable) {
            axios.post(ApiList.pd_md_insured_by_id, {
                user_id: 1,
                pr_key: 1,
                branch_id: 1,
                nic_no: value
            })
                .then((response) => {

                    console.log("autoCompleteHandleChange",response)
                    if (response.data.insured_data.length > 0) {
                        this.setState({
                            insured_id: response.data.insured_data[0].insured_id,
                            insured_title: response.data.insured_data[0].insured_title,
                            insured_name: response.data.insured_data[0].insured_name,
                            insured_nic_no: response.data.insured_data[0].insured_nic_no,
                            insured_address_no: response.data.insured_data[0].insured_address_no,
                            insured_address_road: response.data.insured_data[0].insured_address_road,
                            insured_address_city: response.data.insured_data[0].insured_address_city,
                            insured_contact_no: response.data.insured_data[0].insured_contact_no,
                            insured_email: response.data.insured_data[0].insured_email,
                            insured_surname: response.data.insured_data[0].insured_surname,
                            insured_initials: response.data.insured_data[0].insured_initials,
                            is_svat_registed: response.data.insured_data[0].is_svat_registered
                        })
                    }
                    else {
                        this.setState({
                            insured_id: "0",
                            insured_nic_no: value

                        })
                    }
                }, (error) => {
                    //console.log(error);
                });
        } else {
            this.setState({
                insured_id: "0",
                insured_nic_no: value
            })
        }
    }
    refreshPage() {
        window.location.href =    window.location.href;
    }

    createPolicy(policy_json) {
        let i_id = this.props.match.params.id;
        let active_api = (this.props.EntryType == "NP" ? ApiList.pd_policy_create_partial :
            (this.props.EntryType == "EP" ? ApiList.pd_policy_update : ApiList.pd_policy_endorsement)
        )

        axios.post(active_api, policy_json)
            .then((response) => {           
                if (response.data.status == "1") {
                    if (response.data.data[0]) {
                        this.setState({
                            policy_ref_no: response.data.data[0].pd_policy_ref_no,
                            pd_policy_entry_id: response.data.data[0].pd_policy_entry_id,
                            policy_entry: response.data.entrylist,
                            user_id: 1,
                            error_message: 'update successfully!'                           
                        })
                    }
                } else {
                    this.setState({
                        error_message: response.data.error
                    });

                }

            }, (error) => {
                //console.log(error);
            });

    }

    myHandleChange(val, e) { 

        if (val.target != null && val.target.name) {
       
            this.setState({ [val.target.name]: val.target.value, user_id: 1 })
       
        } else {
                     this.setState({ [e]: val, user_id: 1 })
        
            if (e == 'ins_class_id') {

                this.getCommissionRateByClass(val);
            }
        }     
 }

    render() {

        
        const invoiceDetailsData = {
            currency: this.state.currency,
            sum_insured: this.state.sum_insured,
            invoice_no: this.state.invoice_no
        }
        const policy_details_data = {
            policy_no: this.state.policy_no,
            policy_type_id: this.state.policy_type_id,
            branch_id: this.state.branch_id,
            insurer_id: this.state.insurer_id,
            ins_class_id: this.state.ins_class_id,
            occupation_id: this.state.occupation_id,
            vehicle_no: this.state.vehicle_no,
            cover_note_no: this.state.cover_note_no,
            period_from: this.state.period_from,
            period_to: this.state.period_to,
            payment_mode:this.state.payment_mode
        }
        const insuredData = {
            title: this.state.insured_title,
            name: this.state.insured_name,
            nic_no: this.state.insured_nic_no,
            address_no: this.state.insured_address_no,
            address_road: this.state.insured_address_road,
            address_city: this.state.insured_address_city,
            contact_no: this.state.insured_contact_no,
            email: this.state.insured_email,
            surname: this.state.insured_surname,
            initials: this.state.insured_initials
        }
        const insured_Conatact_Data = {
            title: this.state.contact_person_title,
            name: this.state.contact_person_name,
            nic_no: this.state.contact_person_nic_no,
            address_no: this.state.contact_person_address_no,
            address_road: this.state.contact_person_address_road,
            address_city: this.state.contact_person_address_city,
            contact_no: this.state.contact_person_contact_no,
            email: this.state.contact_person_email
        }



        const policy_premium_commission_rate = this.state.policy_com_rate;
        const NoteType = this.props.NoteType;
        const EntryType = this.props.EntryType;
        const TaskType = this.props.TaskType;
        const IsEndosement = (this.props.EntryType == "ED" || this.props.EntryType == "EC")
        const defaultActiveKey = (IsEndosement ? "third" : "first");
        const policy_entry = this.state.policy_entry;
        const endorsement_reason_data = this.state.Reasons;
        const ResonId = this.state.Reason;
        const Remark=this.state.Remark;
        return (
            <div >
                <NavbarSub pagetitle="New Policy"></NavbarSub>
                <Tab.Container id="left-tabs-example" defaultActiveKey={defaultActiveKey}>
                    <Row>
                        <Col sm={3}>
                           
                            <div className="css_task_title"> {TaskType} - {(this.props.ClassType=="L"?"Life":"General")}</div>
                            <div className="css_task_title_id"> {this.state.pd_policy_entry_id}</div>
                          {this.state.isClicked}

                                    <div className="div-tab-button-container">
                                        <div className="div-tab-data-view">
                                            <div className="row"></div>
                                            <div className="row"></div>
                                            <div className="row"></div>
                                            <div className="row"></div>
                                            <div className="row"></div>

                                            <div className="row">Policy Reference Number</div>
                                            <div className="row"> <span>{this.state.policy_ref_no}</span></div>

                                            <div className="row">Policy Debit Note Number</div>
                                            <div className="row"><span>{this.state.pd_policy_entry_id}</span>
                                                <br></br>
                                            </div>

                                            <div className="row">Trasnactions</div>
                                            <div className="row"><EntryListPayment  onTransactionIdClicked={this.onTransactionIdClicked} EntryList={this.state.policy_entry}></EntryListPayment></div>

                                            <div className={this.state.error_message == 'update successfully!' ? 'css_lbl_success' : 'css_lbl_error'}>
                                                {this.state.error_message}
                                            </div>
                                        </div>

                                        <button className="btn btn-success" onClick={this.buttonHadleChange}>Save</button>

{
    /*
        <button className="btn btn-info" onClick={this.refreshPage}>Add New</button>
    */
}
                                    
                                    </div>

                        </Col>
                        <Col sm={9}>
                            <PaymentDataUpdate    hadlechangeUD={this.myHandleChange}  isClicked={this.state.isClicked}  entry_id={this.state.entry_id}></PaymentDataUpdate>
                           <PolicySchedual isClicked={this.state.isClicked}   entry_id={this.state.entry_id} ></PolicySchedual>
                        </Col>
                    </Row>
                </Tab.Container>



            </div>
        )

    }
}

export default PaymentUpdatePolicy;


