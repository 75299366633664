import React, { Component } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import { ApiList } from "../../../DataOperation/apilink"

class InsurerItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            item: this.props.item
        }
        this.removeToCollection = this.removeToCollection.bind(this);
    }

    removeInsurance(inactive_Insurer) {
        axios.post(ApiList.insurer_inactive, inactive_Insurer)
            .then((response) => {
                if (response.data.data != undefined) {
                    console.log("update return value", response.data);
                    this.props.onListChange();
                } else {
                    this.setState({
                        validate_message: response.data.error
                    })
                }
            }, (error) => {
                console.log('error', error);
            });
    }

    removeToCollection(key) {
       const InactiveInsurer = { insurer_id: key, user_id: 1 }
       this.removeInsurance(InactiveInsurer);
    }

    render() {







        
        return (
            <div>
                <li className="list-group-item">
                    <div className="row">
                        <div className="col col-sm-6">
                            {this.state.item.insurer_name}

                        </div>
                        <div className="col">
                            <Link to={`/insurer/details/${this.state.item.insurer_id}`}>View Details</Link>
                        </div>
                        <div className="col">
                            <Link to={`/insurer/edit/${this.state.item.insurer_id}`}>Edit</Link>
                        </div>
                        <div className="col">
                            <Link onClick={() => { if (window.confirm('Delete the item?')) { this.removeToCollection(this.state.item.insurer_id, this) }; }}>Delete</Link>
                        </div>
                    </div>
                </li>

            </div>
        )

    }
}

export default InsurerItem