import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from "react-router-dom";
import Navbar from "../../component/Navbar/NavbarPolicy"
import "./policysearch.css"
import axios from "axios"
import { ApiList } from "../../DataOperation/apilink"


import ProductService from './service/ProductService'

const DataTableBasicDemo = () => {
    const [products, setProducts] = useState([]);
    const productService = new ProductService();
    const  _page_name='policy'

    useEffect(() => {
        productService.getProductsSmall().then(data => setProducts(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const statusBodyTemplate = (rowData) => {
        return (
            <div>
                <Link to={`/${_page_name.toLowerCase()}/edit/${rowData.ibs_key}`}>Edit</Link>
            </div>);
    }
    const endorsementOpen = (rowData) => {
        return (
            <div>
                <Link to={`/${_page_name.toLowerCase()}/endosement/${rowData.ibs_key}`}>Endorsement</Link>
            </div>);
    }
    const renewalOpen = (rowData) => {
        return (
            <div>
                <Link to={`/${_page_name.toLowerCase()}/renewal/${rowData.ibs_key}`}>Renewal</Link>
            </div>);
    }
    


    const statusBodyTemplateDelete = (rowData) => {
        return (
            <div>
                <Link onClick={() => { if (window.confirm('Are you sure you want to delete this item?')) { removeToCollection(rowData.ibs_key, this) }; }}>Delete</Link>

            </div>);
    }




    
    function removeToCollection(id){
console.log(id);
getInsurerList(id);



    }
    function getInsurerList(_pr_key) {
        axios.post(ApiList.pd_policy_delete,{
            user_id: 1,
            branch_id:1,
            pr_key:_pr_key

        })
            .then((response) => {
              console.log(response);
              productService.getProductsSmall().then(data => setProducts(data));
              
            }, (error) => {
                console.log(error);
            });
    }
    return (


        <div>
            <Navbar></Navbar>
            <div className="card">
                <DataTable value={products} paginator rows={50} >
                    
                    <Column field="policy_ref_no"  header="Policy Ref No" filter={true}></Column>
                    <Column field="policy_no" header="Policy No" filter={true}></Column>
                    <Column field="period_from" header="From" filter={true}></Column>
                    <Column field="period_to" header="To" filter={true}></Column>
                    <Column field="cover_note_no" header="Cover Note No" filter={true}></Column>
                    <Column field="invoice_no" header="Invoice No" filter={true} filterMatchMode={'contains'}></Column>
                    <Column field="vehicle_no" header="Vehicle No" filter={true}></Column>
                    <Column field="insurer_name" header="Insurer Name" filter={true}></Column>
                    <Column field="policy_type_name" header="Policy Type" filter={true}></Column>
                    <Column field="insured_name" header="Insured Name" filter={true}></Column>
                    <Column field="insured_nic_no" header="Insured NIC" filter={true}></Column>  
                    <Column field="ibs_key"  style={{ width: '2em', display: "none" }} header="ibs_key" filter={true}></Column>
                    
                    <Column header=" " body={statusBodyTemplate}></Column>
                    <Column header=" " body={endorsementOpen}></Column>
                    <Column header=" " body={renewalOpen}></Column>
                    <Column header=" Delete " style={{ width: '10em' }}  body={statusBodyTemplateDelete}></Column>

                 
                </DataTable>
            </div>
        </div>
    );
}
export default DataTableBasicDemo