import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from "react-router-dom";

const VoucherDetails = (props) => {

    const deleteTemplateBody = (rowData) => {
        return (
            <div>
                <Link onClick={() => { if (window.confirm('Delete the item?')) { removeToCollection(rowData.receipt_ref_no, this) }; }}>Delete</Link>

            </div>);
    }

    function removeToCollection(key) {
        props.OnDelete({pr_key:key});
    }



    return (
        <div >
            <div className="card">
                Voucher Details
                <hr></hr>
                <DataTable value={props.DataSource} >
                    <Column field="receipt_ref_no" style={{ width: '12em' }} header="Voucher No" filter={false} filterMatchMode={'contains'}></Column>
                    <Column field="entry_id" style={{ width: '20em' }} header="Entry Id" filter={false} filterMatchMode={'contains'}></Column>
                    <Column field="insurer_invoice" style={{ width: '12em' }} header="Invoice No" filter={false} filterMatchMode={'contains'}></Column>
                    <Column field="premium_receipt_amount" header="Payment " style={{ width: '10em', textAlign: 'right' }} ></Column>
                    <Column field="entry_id" header="Delete " style={{ width: '10em',textAlign:'right' }}  body={deleteTemplateBody}></Column>
                </DataTable>
            </div>
        </div>
    )
}

export default VoucherDetails;
