import React from "react";
import { Col, Row } from "react-bootstrap";
import "./slide-list.css"

export default function ({
    TextMember,
    ValueMember
}) {

    return (

        <>
            <Row>
              <label className="slide-list-text"> {TextMember}</label> 
            </Row>
            <Row>
            <label className="slide-list-value"> {ValueMember}</label>   
            </Row>
        </>

    )


}