import React, { Component } from "react";
import axios from "axios"
import { ApiList } from '../../DataOperation/apilink'
import "./ContactDetailComCSS.css"
import ComboboxV1 from '../../component/combobox/ComboboxV1'
import AutoCompleteText from '../../component/AutoCompleteText/AutoCompleteText'
import '../policy/policybasic.css'
import {TitleList} from '../../ibs-constant/TitleList'


class ContactDetailCompCP extends Component {
    constructor() {
        super()
        this.state = {}
        //   this.autoCompleteHandleChange=this.autoCompleteHandleChange().bind(this);
        //  this.setInputValuesData= this.setInputValuesData.bind(this);

    }

    componentDidMount() {
       // this.getDataById();
    }
    getDataById() {

        //  console.log(ApiList.pd_md_nic_list);
        axios.post(ApiList.pd_md_nic_list, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1
        })
            .then((response) => {



                let array_nic_list = [];

              //  response.data.nic_list.map(x => {
//
         //           console.log(array_nic_list.push(x.insured_nic_no));
          //      });


                console.log(array_nic_list);
                this.setState({

                    nic_list: array_nic_list


                }, () => {
                    // console.log("load data ", this.state)

                })
            }, (error) => {
                console.log(error);
            });

    }


    setInputValuesData(_param_id) {

        //  console.log(ApiList.pd_md_nic_list);
        axios.post(ApiList.pd_md_insured_by_id, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1,
            param_id: _param_id
        })
            .then((response) => {

                console.log("response", response);


            }, (error) => {
                console.log(error);
            });

    }




    render() {
        const titleData = TitleList;
        //   const titleData1 = [ 'Mr',  'Mrs',  'Miss' ]
        //pd_md_nic_list


        return (
           
           <div>
           <div  className="css_section_title">  {this.props.legend_title} </div>    
           <div className="cls-np-main-container">
      
                <table>
                    <tbody>
                        {
                        /* <tr>


                            <td>NIC NO</td>
                            <td>
                                <AutoCompleteText items={this.state.nic_list}
                                    autoCompleteEventBind={this.props.autoCompleteHandleChange}
                                    hadlechangeUD={this.props.hadlechangeUD}
                                    name={this.props.name + '_nic_no'}
                                    value={this.props.data.nic_no}
                                >

                                </AutoCompleteText>
                                <div style={{ display: "none" }}>
                                    <input className="ibsinput" name={this.props.name + '_nic_no'}
                                        onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_nic_no')}
                                        value={this.props.data.nic_no}
                                        type="text" />
                                </div>
                            </td>

                        </tr> */
                        }
                        <tr style={{ display: this.props.name == 'insured' ? 'contents' : 'none' }}>
                            <td>Surname </td>
                            <td><input className="ibsinput" name={this.props.name + '_surname'}
                                onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_surname')}
                                value={this.props.data.surname}
                                type="text" /></td>
                        </tr>
                        <tr style={{ display: this.props.name == 'insured' ? '' : 'none' }}>
                            <td>Initials </td>
                            <td><input className="ibsinput" name={this.props.name + '_initials'}
                                onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_initials')}
                                value={this.props.data.initials}
                                type="text" /></td>
                        </tr>
                        <tr>
                            <td>Tittle</td>
                            <td>
                                <ComboboxV1
                                    hadlechangeUD={this.props.hadlechangeUD}

                                    selectedValue={this.props.data.title}
                                    options={titleData}
                                    name={this.props.name + '_title'}
                                ></ComboboxV1>

                            </td>
                        </tr>




                        <tr style={{ display: this.props.name != 'insured' ? 'contents' : 'none' }}  >
                            <td >Name</td>
                            <td><input className="ibsinput  uppercase" name={this.props.name + '_name'}
                                onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_name')}
                                value={this.props.data.name}
                                type="text" /></td>
                        </tr>





                        <tr>
                            <td>Email</td>
                            <td><input className="ibsinput"
                                name={this.props.name + '_email'}
                                onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_email')}
                                value={this.props.data.email}
                                type="text" /></td>
                        </tr>
                        <tr>
                            <td>Mobile No</td>
                            <td><input className="ibsinput  uppercase" name={this.props.name + '_contact_no'}
                                value={this.props.data.contact_no}
                                onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_contact_no')}
                                type="text" /></td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td><input className="ibsinput  uppercase" name={this.props.name + '_address_no'} onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_address_no')}
                                type="text"
                                value={this.props.data.address_no}
                                placeholder="No" /></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><input className="ibsinput  uppercase" name={this.props.name + '_address_road'}
                                value={this.props.data.address_road}
                                type="text" placeholder="Road" onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_address_road')} /></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><input name={this.props.name + '_address_city'} className="ibsinput  uppercase"
                                type="text"
                                placeholder="City"
                                value={this.props.data.address_city}
                                onChange={(event) => this.props.hadlechangeUD(event, this.props.name + '_address_city')} />
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
      </div>  )
    }


}

export default ContactDetailCompCP