
import React, { Component } from "react"
import ComboboxV1 from '../../component/combobox/ComboboxV1'
import NumericTextBox from "react-numeric-textbox"
import axios from "axios";
import { ApiList } from "../../DataOperation/apilink"
import RateTextBox from "../RateTextBox/RateTextBox";

var COMBR = [
    { value: '0.10', label: '10%' },
    { value: '0.13', label: '13%' },
    { value: '0.15', label: '15%' },
    { value: '0.20', label: '20%' },
    { value: '0.25', label: '25%' }

]

var COMSR = [
    { value: '0.050', label: '5.0%' },
    { value: '0.075', label: '7.5%' }

]


var COMTR = [
    { value: '0.050', label: '5.0%' },
    { value: '0.075', label: '7.5%' }

]



var COMAGBR = [
    { value: '0.050', label: '5%' },
    { value: '0.090', label: '9%' },
    { value: '0.10', label: '10%' },
    { value: '0.15', label: '15%' }
]



var COMAGSR = [
    { value: '0.025', label: '2.5%' },
    { value: '0.050', label: '5.0%' }

]

var COMAGTR = [
    { value: '0.025', label: '2.5%' },
    { value: '0.050', label: '5.0%' }

]

class CommissionBrakeDown extends Component {
    constructor(props) {
        super(props)
        this.handleChangeValue = this.handleChangeValue.bind(this);

        this.state = {
            COMBR: [],
            COMAGBR: [],
            COMSR: [],
            COMAGSR: []
        }
        console.log(this.props.COMAGBR);
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            COMBR: nextProps.COMBR,
            COMSR: nextProps.COMSR,
            COMTR: nextProps.COMTR,
            COMAGBR: nextProps.COMAGBR,
            COMAGSR: nextProps.COMAGSR,
            COMAGTR: nextProps.COMAGTR
        });
    }

    setFromOutside(msg) {
        console.log(msg)
    }

    getInsurerList() {
        axios.post(ApiList.pd_policy_rates, {
            user_id: 1, ins_class_id: this.props.ins_class_id
        })
            .then((response) => {
                this.setState({
                    COMBR: response.data.COMBR,
                    COMSR: response.data.COMSR,
                    COMTR: response.data.COMTR,
                    COMAGBR: response.data.COMAGBR,
                    COMAGSR: response.data.COMAGSR,
                    COMAGTR: response.data.COMAGTR


                }, () => {
                    // console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });
    }

    setUndefinedRates() {
        this.addNonExistingRates(this.props.premium_basic_rate);
    }
    calculatePremiumCommission(premium_basic, premium_basic_rate) {
        return (premium_basic * premium_basic_rate);
    }

    addNonExistingRates(rate) {

        const found = COMBR.some(el => eval(el.value) == eval(rate));
        if (!found) COMBR.push({ value: rate, label: [rate * 100 + '%'] });
    }

    handleChangeValue = e => {
        console.log(e)
        const rate = (e / 100);
        if (e) {
            this.addNonExistingRates(rate);
            //   COMBR.push({ value: rate, label: [e + '%'] });
        }

        this.setState({
            COMBR: COMBR
        });
    };



    render() {
        return (
            <div className="cls-np-main-container">
                {this.props.tittle}

                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="div_label">   Basic</div>
                                    {this.props.premium_basic_rate}
                                    <RateTextBox
                                        MaxValue={0.5}
                                        textBoxValue=  {this.props.premium_basic_rate}
                                        hadlechangeUD={this.props.hadlechangeUD}
                                        Name={this.props.name + '_comm_rate_br_basic'}
                                    ></RateTextBox>

                                </td>
                                <td>
                                    <div className="div_label">
                                        <ComboboxV1
                                            hadlechangeUD={this.props.hadlechangeUD}
                                            selectedValue={this.props.premium_basic_rate}
                                            options={this.props.name == "commission" ? this.state.COMBR : this.state.COMAGBR}

                                            name={this.props.name + '_comm_rate_br_basic'}
                                        ></ComboboxV1>
                                    </div>
                                </td>
                                <td>

                                    <NumericTextBox

                                        value={this.calculatePremiumCommission(this.props.premium_basic, this.props.premium_basic_rate)}
                                        disabled={true}
                                        className="input-numeric ibsinput"
                                        name={this.props.name + "_br_basic"}
                                        onChange={(event) => this.props.hadlechangeUD(event, this.props.name + "_br_basic")}


                                    >
                                    </NumericTextBox>
                                </td>


                            </tr>
                            <tr>
                                <td>
                                    S.R.C.C
                                </td>
                                <td>
                                    <ComboboxV1
                                        hadlechangeUD={this.props.hadlechangeUD}
                                        //    selectedValue={(this.props.name=="agent_commission"?premiumSRCCcValue.com_out_rate:  premiumSRCCcValue.com_in_rate)}
                                        selectedValue={this.props.premium_srcc_rate}
                                        options={this.props.name == "commission" ? this.state.COMSR : this.state.COMAGSR}
                                        name={this.props.name + '_comm_rate_br_srcc'}
                                    ></ComboboxV1>

                                </td>
                                <td>
                                    <NumericTextBox
                                        disabled={true}
                                        className="input-numeric ibsinput"
                                        name={this.props.name + "_br_srcc"}
                                        value={this.calculatePremiumCommission(this.props.premium_srcc, this.props.premium_srcc_rate)}
                                        onChange={(event) => this.props.hadlechangeUD(event, this.props.name + "_br_srcc")}
                                    >
                                    </NumericTextBox>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    Terrorisam
                                </td>
                                <td>
                                    <ComboboxV1
                                        hadlechangeUD={this.props.hadlechangeUD}
                                        // selectedValue={premiumTerrorismValue.com_in_rate}
                                        //    selectedValue={(this.props.name=="agent_commission"?premiumTerrorismValue.com_out_rate:  premiumTerrorismValue.com_in_rate)}
                                        options={this.props.name == "commission" ? this.state.COMTR : this.state.COMAGTR}
                                        selectedValue={this.props.premium_terror_rate}
                                        name={this.props.name + '_comm_rate_br_terr'}
                                    ></ComboboxV1>

                                </td>
                                <td>
                                    <NumericTextBox
                                        disabled={true}
                                        className="input-numeric ibsinput"
                                        name={this.props.name + "_br_terr"}
                                        value={this.calculatePremiumCommission(this.props.premium_terror, this.props.premium_terror_rate)}

                                        onChange={(event) => this.props.hadlechangeUD(event, this.props.name + "_br_terr")}>
                                    </NumericTextBox>
                                </td>

                            </tr>


                            <tr>
                                <td>

                                </td>
                                <td>
                                    Total
                                </td>
                                <td>
                                    <NumericTextBox
                                        className="input-numeric ibsinput"
                                        value={this.props.premium_commission_total}
                                        disabled={true}
                                        name={this.props.name + "_br_total"}
                                        onChange={(event) => this.props.hadlechangeUD(event, this.props.name + "_br_total")}
                                    >
                                    </NumericTextBox>
                                </td>

                            </tr>
                        </tbody>
                    </table>



                </div>

            </div>
        )
    }
}

export default CommissionBrakeDown