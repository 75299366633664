import axios from 'axios';
import { ApiList } from '../DataOperation/apilink';



export default class PolicyDetailsDataService{
    getPolicyDetailsKeyVal(requsted_data) {
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };   
        return axios.post(ApiList.pd_policy_details_key_val, request_json).then(res => res.data);
    }  

    getCommissionPaymentUploadData(requsted_data) {
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };   
        return axios.post(ApiList.pc_commission_in_upload_verify_post_select, request_json).then(res => res.data);
    }

}
