export const MenuItems = [
    {
        tittle: 'Payment Receipt',
        url: '/payment/payment-recipt',
        cName: 'nav-links'

    },
    {
        tittle: 'Insurer Receipt',
        url: '/payment/payment-recipt',
        cName: 'nav-links'

    },
    {
        tittle: 'Pending Payments',
        url: '/payment/payment-recipt',
        cName: 'nav-links'

    }
  

]