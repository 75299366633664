import React, { Component } from "react"


import { BrowserRouter as Router, Route } from "react-router-dom";

import MainPage from "./Ibs-pages/ibs-main";
import MainPageControl from "./Ibs-pages/ibs-main/ControlPage";
import MasterPageControl from "./Ibs-pages/ibs-main/MasterPageControl";
import Loginscreen from './Ibs-pages/ibs-login/LoginJsx'
import Insuere from './Ibs-pages/ibs-master-data/insurer'
import PolicyMain from './Ibs-pages/ibs-policy/Policy'
import NewPolicy from './Ibs-pages/ibs-policy/PolicyNew'
import PolicyNewClassType from './Ibs-pages/ibs-policy/PolicyNewClassType'

import InsurerDetails from './Ibs-pages/ibs-master-data/insurer-component/Insurer-Details'
import InsurerEdit from './Ibs-pages/ibs-master-data/insurer-component/InsurerEdit'
import OccupationEdit from './Ibs-pages/ibs-master-data/occupation-component/EditOccupation'
import AddInsurer from './Ibs-pages/ibs-master-data/insurer-component/AddInsurer'
import Example from './Ibs-pages/ibs-policy/PolicySearch'
import Occupation from './Ibs-pages/ibs-master-data/Occupation'
import PolicyType from './Ibs-pages/ibs-master-data/PolicyType'
import PolicyTypeEdit from './Ibs-pages/ibs-master-data/policy-type-component/EditPolicyType'
import AgentBr from './Ibs-pages/ibs-master-data/Agent'
import AgentEdit from './Ibs-pages/ibs-master-data/agent-component/EditAgent'
import AgentService from './Ibs-pages/ibs-master-data/AgentService'
import AgentServiceEdit from './Ibs-pages/ibs-master-data/agent-service-component/EditAgentService'
import PolicyClass from './Ibs-pages/ibs-master-data/PolicyClass'
import BrCommissionRate from './Ibs-pages/ibs-master-data/commision-rate/BrCommisionRate'
import EditCommisionRate from './Ibs-pages/ibs-master-data/commision-rate/EditCommisionRate'
import Endosement from './Ibs-pages/ibs-policy/endorsement/Endosement'
import MainReport from "./Ibs-pages/ibs-report/MainReport";
import MainReportPolicy from "./Ibs-pages/ibs-report/MainReportPolicy";
import MainReportBoard from './Ibs-pages/ibs-report/MainReportBoard';
import DebitPdf from './ReportPDF/DebitNote/DebitNote';
import PaymentMainPage from './Ibs-pages/ibs-payment/main/payment-main';
import PaymentRecipt from "./Ibs-pages/ibs-payment/payment-recipt/payment-recipt";
import DashBoard from "./Ibs-pages/ibs-main/DashBoard";
import MyDashBoard from "./ibs-dashboard/user-dashboard/DashBoard";
import Insured from './Ibs-pages/ibs-insured/Insured';
import PaymentUpdatePolicy from './Ibs-pages/ibs-payment/payment-update/PaymentUpdatePolicy'
import PolicyCovers from './Ibs-pages/ibs-master-data/cover/CoverMain'
import PrivateRoute from "./component/RoutePath/PrivateRoute";
import InsuredListMain from "./Ibs-pages/ibs-insured/InsuredList";
import PremiumPaymentUpdate from "./Ibs-pages/ibs-payment/payment-commission-update/PremiumPaymentUpdate";
import PremiumCommissionReceivedPaymentUpdate from "./Ibs-pages/ibs-payment/payment-commission-update/PremiumCommissionReceivedUpdate"
import CommissionOutPaidUpdate from "./Ibs-pages/ibs-payment/payment-commission-update/CommissionOutPaidUpdate" 
import PremiumOutstandingReport from "./Ibs-pages/ibs-report/premium/PremiumOutstandingReport"
import IBSLReportDashBoard from "./Ibs-pages/ibs-report/ibsl-report/DashBoard";
import CommissionInUploadMain from "./Ibs-pages/ibs-payment/payment-update-upload-doc/CommissionInUploadMain"
import PremiumCommissionReceivedUpdateUploadVerify from "./Ibs-pages/ibs-payment/payment-commission-update/PremiumCommissionReceivedUpdateUploadVerify"
import CommissionMainReport from './Ibs-pages/ibs-report/commission-report/MainReportPolicy'

class App extends Component {
  render() {

    const IsUserLogIn = (localStorage.getItem("UserIndex") > 0);

    const NewpolicyGeneral = (props) => (
      <PolicyNewClassType {...props} NoteType="D" EntryType="NP" TaskType="New Policy" ClassType="G" WorkFlowActionId={1} SaveMode="NEW" />
    )
    const NewpolicyLife = (props) => (
      <PolicyNewClassType {...props} NoteType="D" EntryType="NP" TaskType="New Policy" ClassType="L" WorkFlowActionId={1}  SaveMode="NEW"/>
    )

    const RenewalPolicyGenaral = (props) => (
      <PolicyNewClassType  {...props} NoteType="D" EntryType="RN" TaskType="Renewal Policy" WorkFlowActionId={2} ActionType="NewRenewal" SaveMode="NEW" ClassType="G"/>
    )

    const RenewalPolicyLife = (props) => (
      <PolicyNewClassType  {...props} NoteType="D" EntryType="RN" TaskType="Renewal Policy" WorkFlowActionId={2} ActionType="NewRenewal" SaveMode="NEW" ClassType="L"/>
    )

    const RenewalPolicyEditLife = (props) => (
      <PolicyNewClassType  {...props} NoteType="D" EntryType="RN" TaskType="Renewal Policy - Edit" WorkFlowActionId={2} ActionType="NewRenewal" SaveMode="UPDATE" ClassType="L"/>
    )

    const RenewalPolicyEditGeneral = (props) => (
      <PolicyNewClassType  {...props} NoteType="D" EntryType="RN" TaskType="Renewal Policy - Edit" WorkFlowActionId={2} ActionType="NewRenewal" SaveMode="UPDATE" ClassType="G"/>
    )
    

    return (

      <Router>


        <Route exact path="/" component={MainPage} />
        <Route exact path="/login" component={Loginscreen} />

        
        <PrivateRoute authed={IsUserLogIn} path='/report/commission/commission-report' component={CommissionMainReport} />
        <PrivateRoute authed={IsUserLogIn} path='/dashboard' component={DashBoard} />
        <PrivateRoute authed={IsUserLogIn} path='/my-dashboard' component={MyDashBoard} />
        <PrivateRoute authed={IsUserLogIn} path='/report/ibsl/main' component={IBSLReportDashBoard} />
        <PrivateRoute authed={IsUserLogIn} path="/policy/new-policy/general" component={NewpolicyGeneral} />
        <PrivateRoute authed={IsUserLogIn} path="/policy/new-policy/life" component={NewpolicyLife} />

        <PrivateRoute authed={IsUserLogIn} path="/renewal/policy/general/edit/:id/:eid" component={RenewalPolicyEditGeneral} />
        <PrivateRoute authed={IsUserLogIn} path="/renewal/policy/life/edit/:id/:eid" component={RenewalPolicyEditLife} />

      
        <PrivateRoute authed={IsUserLogIn} path="/policy/general/renewal/:id/:eid" component={RenewalPolicyGenaral} />
        <PrivateRoute authed={IsUserLogIn} path="/policy/life/renewal/:id/:eid" component={RenewalPolicyLife} />
        <PrivateRoute authed={IsUserLogIn} path="/new/policy/general/renewal/:id/:eid" component={RenewalPolicyLife} />
        <PrivateRoute authed={IsUserLogIn} path='/commission/commission-in/upload-commission-received' component={CommissionInUploadMain} />
        

        <Route
          path="/insured-list/individual"
          render={(props) => (
            <InsuredListMain {...props} isAuthed={true} insuredType="I" />
          )}
        />
        <Route
          path="/insured-list/cooperate"
          render={(props) => (
            <InsuredListMain {...props} isAuthed={true} insuredType="C" />
          )}
        />



<Route exact path="/report/premium/outstanding" component={PremiumOutstandingReport} />
        <Route exact path="/debit-note-print/:id" component={DebitPdf} />
        <Route exact path="/mainpage" component={MainPageControl} />
        <Route exact path="/master-data" component={MasterPageControl} />
        <Route exact path="/master-data/insurer" component={Insuere} />
        <Route exact path="/master-data/md-occupation" component={Occupation} />
        <Route exact path="/master-data/md-policy-type" component={PolicyType} />
        <Route exact path="/master-data/md-policy-cover" component={PolicyCovers} />
        <Route exact path="/master-data/policyclass" component={PolicyClass} />
        <Route exact path="/master-data/commission-rate" component={BrCommissionRate} />
        <Route exact path="/commission-rate/edit/:id" component={EditCommisionRate} />

        <Route exact path="/master-data/md-agent" component={AgentBr} />
        <Route exact path="/master-data/md-agent-service" component={AgentService} />
        <Route exact path="/policy" component={PolicyMain} />

        <Route exact path="/policy/search" component={Example} />


        <Route exact path="/insurer/edit/:id" component={InsurerEdit} />
        <Route exact path="/occupation/edit/:id" component={OccupationEdit} />
        <Route exact path="/policy-type/edit/:id" component={PolicyTypeEdit} />
        <Route exact path="/agent/edit/:id" component={AgentEdit} />
        <Route exact path="/agent-service/edit/:id" component={AgentServiceEdit} />
        <Route exact path="/insurer/add" component={AddInsurer} />
        <Route exact path="/insurer/details/:id" component={InsurerDetails} />
        <Route exact path="/report-age" component={MainReport} />
        <Route exact path="/report-policy" component={MainReportPolicy} />
        <Route exact path="/report" component={MainReportBoard} />

        <Route exact path="/payment" component={PaymentMainPage} />
        <Route exact path="/payment/payment-recipt" component={PaymentRecipt} />



        <Route
          path="/insured/individual-edit/:id"
          render={(props) => (
            <Insured {...props} isAuthed={true} insuredType="individual" TaskType="Edit individual client" ActionType="Edit" />
          )}
        />
        <Route
          path="/insured/cooperate-edit/:id"
          render={(props) => (
            <Insured {...props} isAuthed={true} insuredType="cooperate" TaskType="Edit cooperate client" ActionType="Edit" />
          )}
        />

        <Route
          path="/insured/individual"
          render={(props) => (
            <Insured {...props} isAuthed={true} insuredType="individual" TaskType="Add new individual client" />
          )}
        />
        <Route
          path="/insured/cooperate"
          render={(props) => (
            <Insured {...props} isAuthed={true} insuredType="cooperate" TaskType="Add new cooperate client" />
          )}
        />




        <Route
          path="/policy/general/endosement/:id/:mid"
          render={(props) => (
            <Endosement {...props} isAuthed={true} ClassType="G" />
          )}
        />


        <Route
          path="/policy/life/endosement/:id/:mid"
          render={(props) => (
            <Endosement {...props} isAuthed={true} ClassType="L" />
          )}
        />

     {

/* <Route
          exact path="/policy/general/renewal/:id/:eid"
          render={(props) => (
            <PolicyNewClassType {...props} NoteType="D" EntryType="RN" TaskType="Renewal Policy" ClassType="G" />
          )}
        />*/

     }  

        <Route
          exact path="/new/policy/life/renewal/:id/:eid"
          render={(props) => (
            <PolicyNewClassType {...props} NoteType="D" EntryType="RN" TaskType="Renewal Policy" ClassType="L" SaveMode="NEW" WorkFlowActionId={2} />
          )}
        />
        <Route
          exact path="/policy/general/newendorsement/debit/:id/:eid"
          render={(props) => (
            <PolicyNewClassType {...props} NoteType="D" EntryType="ED" TaskType="Endorsement - Debit Note" ClassType="G" ActionType="NewEndosement"
            WorkFlowActionId={3}
            SaveMode="NEW"/>
          )}
        />

        <Route
          exact path="/policy/life/newendorsement/debit/:id/:eid"
          render={(props) => (
            <PolicyNewClassType {...props} NoteType="D" EntryType="ED" TaskType="Endorsement - Debit Note" ClassType="L" ActionType="NewEndosement" 
            WorkFlowActionId={3}
            
            SaveMode="NEW"
            />
          )}
        />
        <Route
          exact path="/policy/general/newendorsement/credit/:id/:eid"
          render={(props) => (

            /* <NewPolicy {...props} NoteType="C" EntryType="EC" TaskType="Endorsement - Credit Note" ActionType="New" />*/
            <PolicyNewClassType {...props} NoteType="C" EntryType="EC" TaskType="Endorsement - Credit Note"  ClassType="G" ActionType="NewEndosement" WorkFlowActionId={3} SaveMode="NEW" />
          )}
        />

        <Route
          exact path="/policy/life/newendorsement/credit/:id/:eid"
          render={(props) => (

            /* <NewPolicy {...props} NoteType="C" EntryType="EC" TaskType="Endorsement - Credit Note" ActionType="New" />*/
            <PolicyNewClassType {...props} NoteType="C" EntryType="EC" TaskType="Endorsement - Credit Note" ClassType="L" ActionType="NewEndosement" WorkFlowActionId={3} SaveMode="NEW" />
          )}
        />
        <Route
          exact path="/new/policy/life/edit/:id/:eid"
          render={(props) => (         
            <PolicyNewClassType {...props} NoteType="D" EntryType="EP" TaskType="Edit Policy" ClassType="L" ActionId="NEW" SaveMode="UPDATE" WorkFlowActionId={1} />
          )}
        />

<Route
          exact path="/newpolicy/policy/life/edit/:id/:eid"
          render={(props) => (         
            <PolicyNewClassType {...props} NoteType="D" EntryType="EP" ActionId="NEW" TaskType="Edit Policy" ClassType="L" SaveMode="UPDATE" WorkFlowActionId={1} />
          )}
        />
   <Route
          exact path="/new/policy/general/edit/:id/:eid"
          render={(props) => (          
            <PolicyNewClassType {...props} NoteType="D" EntryType="EP" ActionId="NEW" TaskType="Edit Policy" ClassType="G"  SaveMode="UPDATE" WorkFlowActionId={1}  />
          )}
        />
<Route
          exact path="/endorsement/policy/life/edit/:id/:eid"
          render={(props) => (         
            <PolicyNewClassType {...props} NoteType="D" EntryType="EP" TaskType="Edit Policy" ClassType="L" ActionType="Endosement" SaveMode="UPDATE" WorkFlowActionId={3} />
          )}
        />
   <Route
          exact path="/endorsement/policy/general/edit/:id/:eid"
          render={(props) => (          
            <PolicyNewClassType {...props} NoteType="D" EntryType="EP" TaskType="Edit Policy" ClassType="G" ActionType="Endosement"  SaveMode="UPDATE" WorkFlowActionId={3} />
          )}
        />
{/**
 * <Route
          exact path="/renewal/policy/life/edit/:id/:eid"
          render={(props) => (         
            <PolicyNewClassType {...props} NoteType="D" EntryType="RN" TaskType="Edit Policy" ClassType="L" />
          )}
        />  <Route
          exact path="/renewal/policy/general/edit/:id/:eid"
          render={(props) => (          
            <PolicyNewClassType {...props} NoteType="D" EntryType="RN" TaskType="Edit Policy" ClassType="G"  />
          )}
        />
 */}

 
        <Route
          exact path="/policy/general/edit/:id/:eid"
          render={(props) => (          
            <PolicyNewClassType {...props} NoteType="D" EntryType="EP" TaskType="Edit Policy" ClassType="G"  ActionId="NEW" SaveMode="UPDATE" WorkFlowActionId={1} />
          )}
        />
 <Route
          exact path="/policy/life/view/:id/:eid"
          render={(props) => (         
            <PolicyNewClassType {...props} NoteType="D" EntryType="EP" TaskType="View Policy" ClassType="L" ActionType="ViewPolicy"  />
          )}
        />
        <Route
          exact path="/new/policy/general/view/:id/:eid"
          render={(props) => (          
            <PolicyNewClassType {...props} NoteType="D" EntryType="EP" TaskType="View Policy" ClassType="G" ActionType="ViewPolicy" />
          )}
        />
 <Route
          exact path="/policy/general/view/:id/:eid"
          render={(props) => (          
            <PolicyNewClassType {...props} NoteType="D" EntryType="EP" TaskType="View Policy" ClassType="G" ActionType="ViewPolicy" />
          )}
        />



        <Route
          //   exact path="/policy/edit/:id"
          exact path="/policy/payment/update-payment/:id/:eid"

          render={(props) => (
            /*  <NewPolicy {...props} NoteType="D" EntryType="EP" TaskType="Edit Policy" />*/
            <PaymentUpdatePolicy {...props} NoteType="D" EntryType="EP" TaskType="Update Payment" ClassType="G" />
          )}
        />

   
        <Route
          //   exact path="/policy/edit/:id"
          exact path="/policy/general/payment/update-commission-received/:id/:eid"

          render={(props) => (
            <PremiumCommissionReceivedPaymentUpdate {...props} isAuthed={true} insuredType="cooperate" TaskType="Update Commission Received "
              NoteType="C"
              ActionType="Edit" />
          )}
        />

<Route
          //   exact path="/policy/edit/:id"
          exact path="/policy/payment/comin/upload-verify/:id"

          render={(props) => (
            <PremiumCommissionReceivedUpdateUploadVerify {...props} isAuthed={true} insuredType="cooperate" TaskType="Update Commission Received "
              NoteType="C"
              ActionType="Edit" />
          )}
        />

  <Route
          //   exact path="/policy/edit/:id"
          exact path="/policy/life/payment/update-commission-received/:id/:eid"

          render={(props) => (
            <PremiumCommissionReceivedPaymentUpdate {...props} isAuthed={true} insuredType="cooperate" TaskType="Update Commission Received "
              NoteType="C"
              ActionType="Edit" />
          )}
        />

<Route
  
          exact path="/policy/general/payment/update-commission-out-paid/:id/:eid"
          render={(props) => (
            <CommissionOutPaidUpdate {...props} isAuthed={true} insuredType="cooperate" TaskType="Update Commission Paid"
              NoteType="C"
              ActionType="Edit" />
          )}
        />

        <Route
          exact path="/policy/endorsement/debit/:id/:eid"
          render={(props) => (
            <NewPolicy {...props} NoteType="D" EntryType="ED" TaskType="Endorsement - Debit Note" />
          )}
        />
        <Route
          exact path="/policy/endorsement/credit/:id/:eid"
          render={(props) => (
            <NewPolicy {...props} NoteType="C" EntryType="EC" TaskType="Endorsement - Credit Note" />
          )}
        />
        <Route
          path="/policy/general/payment/update-payment/:id/:eid"
          render={(props) => (
            <PremiumPaymentUpdate {...props} isAuthed={true} insuredType="cooperate" TaskType="Update Commission"
              NoteType="C"
              ActionType="Edit" />
          )}
        />

<Route
          path="/policy/life/payment/update-payment/:id/:eid"
          render={(props) => (
            <PremiumPaymentUpdate {...props} isAuthed={true} insuredType="cooperate" TaskType="Update Commission"
              NoteType="C"
              ActionType="Edit" />
          )}
        />

      </Router>
    )
  }
}
export default App;
