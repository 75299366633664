import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from "react-router-dom";
import InsuredDataService from '../../DataApiService/InusredService'


const DataTableBasicDemo = ({ insuredType, handleModal, sourcePage }) => {
    const [products, setProducts] = useState([]);
    const productService = new InsuredDataService();

    useEffect(() => {
        productService.getInsuredDataByType({ insuredType: insuredType }).then(data => setProducts(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const selectInsuredtemplate = (rowData) => {
        return (
            <div>
                <div className='btn btn-success' onClick={() => { selectInsured(rowData.insured_id, this); }}>Select</div>

            </div>);
    }
    const statusBodyTemplateDelete = (rowData) => {
        if (rowData.IsAssigned > 0) {

            return <><span style={{ color: '#808080' }}> Delete</span></>;
        }
        else {

            return (
                <div>
                    <Link onClick={() => { if (window.confirm('Delete the item?')) { removeToCollection(rowData.insured_id, this) }; }}>Delete</Link>

                </div>);
        }
    }

    function removeToCollection(key) {
        const InactiveInsurer = { insured_id: key, user_id: 1 }
        removeItem(InactiveInsurer);
    }

    function removeItem(inactive_Insurer) {
        productService.deleteInsured(inactive_Insurer)
            .then((response) => {
                if (response.data != undefined) {
                    console.log("update return value", response.data);
                    productService.getInsuredDataByType({ insuredType: insuredType }).then(data => setProducts(data));
                } else {
                    console.log(response.data.error);

                }
            }, (error) => {
                console.log('error', error);
            });
    }


    function selectInsured(id) {
        handleModal(id);
    }

    return (


        <div className='policy-search-main-container'>
            <div>{sourcePage}</div>
            <div className="card">
                <DataTable value={products} paginator rows={10} >

                    <Column field="insured_surname" style={{ width: '15em' }} header="Name" filter={true}></Column>
                    <Column field="insured_nic_no" style={{ width: '10em' }} header="Reg No\NIC NO" filter={true}></Column>
                    <Column field="Address" style={{ width: '40em' }} header="Address" filter={true}></Column>
                    <Column field="insured_email" style={{ width: '20em' }} header="Email" filter={true}></Column>
                    <Column field="insured_contact_no" style={{ width: '10em' }} header="Contact No" filter={true}></Column>
                    <Column field="insured_id" style={{ width: '2em', display: "none" }} header="insured_id" filter={true}></Column>
                    <Column header="  " style={{ width: '10em'  }} body={selectInsuredtemplate}></Column>
                    <Column header=" Delete " style={{ width: '10em', display: (sourcePage == 'Main' ? 'table-cell' : 'none') }} body={statusBodyTemplateDelete}></Column>

                </DataTable>
            </div>
        </div>
    );
}

{/*
   <Column field="IsAssigned"   style={{ width: '40em'}} header="IsAssigned" filter={true}></Column>
*/}
export default DataTableBasicDemo