import React from "react"
import Navbar from "../../component/Navbar/Navbar"
import '../../App.css';
import AgeReport from './AgeReport'


const  MainReport=()=> {

  return (  <div className="App">
     <Navbar/>

    
<div className="report-main">
  <center>
  <h2>Report</h2>



  <AgeReport></AgeReport>
     </center>
     </div>
     </div>
  );

}
export default MainReport;
