import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from "react-router-dom";
import "./policysearch.css"
import { Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import PolicyProertyView from '../../component/PolicyProperty/PolicyPropertyView'
import ProductService from './service/ProductService'
import { ConstantList } from '../../ibs-constant/CommissionConstant';

const PolicySearchPopupTransaction = ({ policy_class_type, policy_action }) => {
    const [products, setProducts] = useState([]);
    const [show, setShow] = useState(false);
    const [policyRefNo, setPolicyREfNo] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const productService = new ProductService();
    const _page_name = 'policy'

    useEffect(() => {
        productService.getPolicyTransactionData(policy_class_type).then(data => setProducts(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const policyActionURL = (policy_action) => {

        var returnUrl = '/edit/';
        if (policy_action == 'View') {

            returnUrl = '/view/'
        } else if (policy_action == 'Renewal') {

            returnUrl = '/renewal/'
        }else if (policy_action == 'Endorsement') {

            returnUrl = '/endosement/'
        }
        else if (policy_action == 'Update Payment') {

            returnUrl = '/payment/update-payment/'
        }  
        //Update Commission Received
        else if (policy_action == 'Update Commission Received') {

            returnUrl = '/payment/update-commission-received/'
        } 
        else if (policy_action == ConstantList.UPATE_COMMISSION_PAID) {

            returnUrl = '/payment/update-commission-out-paid/'
        } 
        
        return  `/${policy_class_type.toLowerCase()}${returnUrl}`;     
    }


    const statusBodyTemplate = (rowData) => {
        return (
            <div style={{textAlign:'center'}}>
             
                <Link to={`/${rowData.entry_type}/policy/${rowData.ins_class_type}/edit/${rowData.ibs_key}/${rowData.ibs_entry_id}`} target="_blank">{policy_action}</Link>
            </div>);
    }
    const statusVehicleTemplate = (rowData) => {
        return (
            <div>

                <div style={{ color: 'blue', cursor: 'pointer' }} onClick={() => {
                    handleShow();
                    setPolicyREfNo(rowData.ibs_key);

                }}>
                    {rowData.vehicle_no}
                </div>
            </div>);
    }

    const autharisedTemplate = (rowData) => {

        const IsAutherised=(rowData.action_ref_no.length>3?'css_autherise_row fa fa-check':'css_not_autherise_row')
                return (
            <div style={{'padding':'5px'}}>

                <div className={IsAutherised}>
                </div>
            </div>);
    }

   
    return (


        <div className='policy-search-main-container'>
            Transaction Data
            <div className="card">
                <DataTable value={products} paginator rows={15} >
                <Column body={autharisedTemplate}></Column>
                    <Column field="policy_ref_no" style={{ width: '2em', display: "none" }} header="Policy Ref No" filter={true}  filterMatchMode={'contains'}></Column>
                    <Column field="policy_no" header="Policy No" filter={true}  filterMatchMode={'contains'}></Column>
                    <Column field="entry_id" style={{ width: '20em' }}  header="Entry Id" filter={true}  filterMatchMode={'contains'}></Column>
                    <Column field="entry_type" header="Entry Type" filter={true}  filterMatchMode={'contains'}></Column>
                    <Column field="period_from" style={{ width: '10em' }} header="From" filter={true}  filterMatchMode={'contains'}></Column>
                    <Column field="period_to" style={{ width: '10em'}} header="To" filter={true}  filterMatchMode={'contains'}></Column>
                    <Column field="cover_note_no"   style={{ width: '2em', display: "none" }} header="Cover Note No" filter={true}  filterMatchMode={'contains'}></Column>
                    <Column field="invoice_no" style={{ width: '5em'}} header="Invoice No" filter={true}  filterMatchMode={'contains'}></Column>
                    <Column field="vehicle_no" header="Vehicle No" filter={true} style={{ width: '10em' }}  filterMatchMode={'contains'} body={statusVehicleTemplate}></Column>
                    <Column field="insurer_name" header="Insurer Name" filter={true}   filterMatchMode={'contains'}></Column>
                    <Column field="policy_type_name" header="Policy Type" filter={true}  filterMatchMode={'contains'}></Column>
                    <Column field="insured_name" style={{ width: '20em' }} header="Insured Name" filter={true} filterMatchMode={'contains'}></Column>
                    <Column field="insured_nic_no" header="Insured NIC"  filterMatchMode={'contains'} filter={true}></Column>
                    <Column field="ibs_key" style={{ width: '2em', display: "none" }} header="ibs_key" filter={true}></Column>
                    <Column field="ibs_entry_id" style={{ width: '2em', display: "none" }} header="ibs_entry_id" filter={true}  filterMatchMode={'contains'}></Column>
                    <Column field="ibs_entry_id" style={{ width: '2em', display: "none" }} header="ibs_entry_id" filter={true}  filterMatchMode={'contains'}></Column>
                    <Column header=" " style={{ width: '10em' }} body={statusBodyTemplate}></Column>
                
                    
                
                </DataTable>
            </div>
            <Modal show={show} onHide={handleClose} dialogClassName="cls_modal_small_pop" >
                <Modal.Header closeButton>
                    <Modal.Title>Policy Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PolicyProertyView pr_key={policyRefNo}></PolicyProertyView>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default PolicySearchPopupTransaction