import React, { useState, useEffect } from "react";
import NumericTextBox from "react-numeric-textbox"
import './rate-text.css'


export default function RateTextBox(props) {

    const [textBoxValue, setTextBoxValue] = useState(0);
    const [isValid, setIsValid] = useState(true);
    //props.hadlechangeUD(event, "_br_basic")


    const ValidationMessage = ({ IsValiad }) => {
        if (IsValiad) {
            return (
                <>

                </>);
        } else {

            return (
                <div>
                    Not valiad
                </div>);
        }

    }
   

    function hangeTextValue(event) {
        let IsValidComputed = (props.MaxValue >= event && 100 > event && props.RateBaseLimit > event);
        if (IsValidComputed) {
            props.hadlechangeUD(event, props.Name)
            props.getInputValidation(props.Name, "");
        } else {
            props.getInputValidation(props.Name, "Invalid rate");
            props.hadlechangeUD(0, props.Name)
        }
        setIsValid(IsValidComputed);
    }
    const validation_css = (isValid ? '' : 'invalid-rate');
    return (<div>



        <NumericTextBox
            className={`input-numeric ibsinput ${validation_css}`}
            value={props.textBoxValue * 100}
            onChange={(event) => hangeTextValue(eval(event / 100))}
        ></NumericTextBox>


    </div>)

}