import './flag-button.css'
const FlagButton = ({ Name }) => (
    <div className='fb-main-container'>

        <div class="btn-fb">
            <a href="#">
                {Name}
            </a>

        </div>

    </div>
);
export default FlagButton;