import React, { Component } from 'react'
import { MenuItems } from "../../../MenuData/MenuItemsPolicyDetails"
import {MenuItemsCommissionUpdate} from "../../../MenuData/MenuItemsPolicyDetails"
import ButtonNavigator from "../../../component/TabController/ButtonNavigator"
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import PolicySearch from '../../ibs-policy/PolicySearchPopup'
import { ConstantList } from '../../../ibs-constant/CommissionConstant';

class PaymentNavPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false
    }
    this.handleModal = this.handleModal.bind(this);
  }


  handleModal(itemtittle, policy_action) {
    this.setState({ show: !this.state.show, policy_class_type: (itemtittle.substring(0,1)=='G'?'general':'life'), policy_action: policy_action })
  }


  handleModalPaymentUpdate(itemtittle, policy_action) {
    this.setState({ show: !this.state.show, policy_class_type: itemtittle, policy_action: policy_action })
  }

  render() {
    return (
      <div>
        <ButtonNavigator headerName="Update Payment" policy_action="Update Payment" handleModal={this.handleModal} MenuItems={MenuItems}></ButtonNavigator>
        <ButtonNavigator headerName="Update Commission Received" policy_action="Update Commission Received" handleModal={this.handleModal} MenuItems={MenuItemsCommissionUpdate}></ButtonNavigator>
        <ButtonNavigator headerName="Update Commission Paid" policy_action={ConstantList.UPATE_COMMISSION_PAID} handleModal={this.handleModal} MenuItems={MenuItems}></ButtonNavigator>
       
        <ButtonNavigator headerName="Payment Outstanding" MenuItems={MenuItems}></ButtonNavigator>
        <ButtonNavigator headerName="Payment Details" MenuItems={MenuItems}></ButtonNavigator>

        <Modal show={this.state.show} onHide={() => this.handleModal('hide')} >
          <Modal.Header closeButton>Policy Details</Modal.Header>
          <Modal.Body>
            <PolicySearch policy_class_type={this.state.policy_class_type} policy_action={this.state.policy_action}></PolicySearch>


          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col>  <Button onClick={() => this.handleModal("Save")}>Ok</Button>   </Col>
              <Col></Col>
              <Col> <Button onClick={() => this.handleModal("Close")}>Cancel</Button>   </Col>
            </Row>


          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}


export default PaymentNavPage;