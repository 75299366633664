import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios'
import { ApiList } from '../../../DataOperation/apilink'
import Button from '@material-ui/core/Button';
import '../report.css'
import Navbar from '../../../component/Navbar/Navbar';
import ReportDataService from '../../../DataApiService/ReportDataService'
import { TableRow } from '@material-ui/core';

class PremiumOutstandingReport extends Component {
    constructor(props) {
        super(props)
        this.state={masterdata:[]}
        this.exportCSV = this.exportCSV.bind(this);
    }
    componentDidMount() {
        this.getMasterDataList();
    }

    exportCSV(selectionOnly) {
        this.dt.exportCSV({ selectionOnly });
    }

    getMasterDataList() {
        const reportDataService = new ReportDataService();
        reportDataService.getPremiumOutstandingData(this.state).then(data => {
            this.setState({ masterdata: data.data }
            );
        }
        );
    }

  

    render() {
        const statusBodyTemplateDelete = (rowData) => {
            return (
                <div>
                    <div  className='btn btn-success' onClick={() => { 
                      //  removeToCollection(rowData.insured_id, this); 
                         } }>+</div>
    
                </div>);
        }

        const representativeBodyTemplate = (rowData) => {
            return (
                <div>
                    <div  className='btn btn-success' onClick={() => { 
                      //  removeToCollection(rowData.insured_id, this); 
                         } }>+</div>
    
                </div>);
        }



        const list_table_source = this.state.masterdata;
        const current_date=''//new Date().now();
        return (
            <div>
                <Navbar />
                <div className="container">
                    <ul>
                        <div className="ig-table-container">
                            <div className="card">
                                <Button type="button" color="primary" onClick={() => this.exportCSV(false)} className="tb-export-button" data-pr-tooltip="CSV" >Export to CSV</Button>

                                <DataTable   exportFilename={`Premium Outstadning Report ${current_date}`} ref={(el) => { this.dt = el; }} value={list_table_source} id="id_data_report" tableClassName="tbl_css_data" >
                                    
                                <Column field="policy_no" body={statusBodyTemplateDelete}></Column>
                                    <Column field="insured_name" style={{ width: '50em' }} header="Client" filter={true}></Column>
                                    <Column field="policy_no" style={{ width: '40em' }} header="Policy No" filter={true}></Column>
                                    <Column field="Premium" style={{ width: '20em', textAlign: 'right' }} header="Premium" filter={true}></Column>
                                    <Column field="DueAmount" style={{ width: '40em', textAlign: 'right' }} header="Due Amount" filter={true}></Column>
                                    <Column field="premium_received_amount" style={{ width: '20em', textAlign: 'right' }} header="Premium Received" filter={true}></Column>
                                   
                                </DataTable>
                            </div>
                        </div>
                    </ul></div>



            </div>

        )
    }
}

export default PremiumOutstandingReport
/*

<TableToExcel

table="id_data_report"
filename="age report"
sheet="sheet 1"
buttonText="Export"

></TableToExcel>*/