import axios from 'axios';
import { ApiList } from '../DataOperation/apilink';



export default class PremiumPaymentDataService{


    updatePremiumPaymentReceiptData(requsted_data) {
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };       
        return axios.post(ApiList.pp_premium_payment_receipt_update, request_json).then(res => res.data);
    }

    updatePremiumPaymentCommissionReceivedReceiptData(requsted_data) {
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };       
        return axios.post(ApiList.pc_commission_payment_receipt_update, request_json).then(res => res.data);
    }
   
    
    updateCommissionOutPaidVoucherData(requsted_data) {
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };       
        return axios.post(ApiList.pc_commission_out_payment_update, request_json).then(res => res.data);
    }

    deleteCommissionOutPaidVoucherData(requsted_data) {
        console.log("delete commission out",requsted_data)
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };       
        return axios.post(ApiList.pc_commission_out_payment_delete, request_json).then(res => res.data);
    }

    validateUploadCommissionInData(requsted_data) {
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };       
        return axios.post(ApiList.pc_commission_in_upload_validator, request_json).then(res => res.data);
    }

    deletePremiumPaymentReceiptData(requsted_data) { 
        console.log("deletePremiumPaymentReceiptData",requsted_data)
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };       
        return axios.post(ApiList.pp_premium_payment_receipt_delete, request_json).then(res => res.data);
    }

    deletePremiumPaymentCommissionReceiptData(requsted_data) { 
        console.log("delete commission received data",requsted_data)
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };       
        return axios.post(ApiList.pc_premium_payment_receipt_delete, request_json).then(res => res.data);
    }

}
