import axios from 'axios';
import {ApiList} from '../DataOperation/apilink'


export default class PolicyProertyDataService{

  
    getPropertyListListByRefId(requsted_data) {
        return  axios.post(ApiList.pd_policy_property_data, requsted_data).then(res => res.data.data);  
    }
   
}

