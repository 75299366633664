import React from "react";



const Address = ({ CompanyData }) => {

    let Company ={Name:'',AddressLine1:'',AddressLine2:'',AddressLine3:'',Email:'',Telephone:'',Fax:''};
    
 if(CompanyData.length>0){

    Company=CompanyData[0];

 }

   
    return (
        <div>
         <div >
             
         <table>
                <tr>


                    <td>{Company.Name}


                    </td>
                </tr>
                <tr>
                    <td>{Company.AddressLine1}</td>
                </tr>
                <tr>
                    <td>{Company.AddressLine2}</td>
                </tr>
                <tr>
                    <td>{Company.AddressLine3}</td>
                </tr>
                <tr>
                    <td>{Company.Email}</td>
                </tr>


                <tr>
                    <td>{Company.Telephone}</td>
                </tr>
                <tr>
                    <td>{Company.Fax}</td>
                </tr>


            </table>
             
             
             
             
              </div>

               
         
        </div>

    )




}


export default Address;

/*
 <table>
                <tr>


                    <td>{Company.Name}


                    </td>
                </tr>
                <tr>
                    <td>{Company.AddressLine1}</td>
                </tr>
                <tr>
                    <td>{Company.AddressLine2}</td>
                </tr>
                <tr>
                    <td>{Company.AddressLine3}</td>
                </tr>
                <tr>
                    <td>{Company.Email}</td>
                </tr>


                <tr>
                    <td>{Company.Telephone}</td>
                </tr>
                <tr>
                    <td>{Company.Fax}</td>
                </tr>


            </table>*/