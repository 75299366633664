import React, { Component } from 'react'
import { MenuItems } from "../../MenuData/MenuItemsClientData"
import { MenuItemsClientList } from "../../MenuData/MenuItemsClientData"

import ButtonNavigator from "../../component/TabController/ButtonNavigator"
class InsuredType extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
              <br></br>
                <ButtonNavigator headerName="Create Client" MenuItems={MenuItems}></ButtonNavigator>
                <br></br>
                <ButtonNavigator headerName="Client List" MenuItems={MenuItemsClientList}></ButtonNavigator>
            </div>
        )
    }
}


export default InsuredType;