import React,{useState,useEffect} from "react";
import CheckList from './PropertyList'
import PropertyListItem from "./PropertyListItem";
import _ from "lodash";
import PolicyProertyDataService from '../../DataApiService/PolicyPropertyDataService'


export default function ({setCoverList,coverListData,policy_ref_no,setPropertyListData,ins_class_id}) {
    
    const [list, setList] = useState([]);
    const  [checkboxList, setcheckboxList]=  useState(coverListData);
const policyProertyDataService = new PolicyProertyDataService();
useEffect(() => {
  policyProertyDataService.getPropertyListListByRefId({ param_id:policy_ref_no,ins_class_id:ins_class_id  }).then(data => { 
      setList(data);  
      setPropertyListData(data);
  
    } );
   //   setcheckboxList(coverListData);
}, [coverListData,ins_class_id]); 

  function  handleCheck (data, id,value ){     
      let existElementObj = {... data.find(e => e.id === id) || {}};
      console.log("existElementObj",existElementObj)
      existElementObj.property_value=value;
      existElementObj.id=id;     
      let filteredElement= data.filter(e => e.id !== id);  
      filteredElement.push(existElementObj);
      console.log("filteredElement",filteredElement)      

      const ds= filteredElement.sort(function (a, b) {
        return a.order_id - b.order_id;
      });




        setList(ds);   
        setPropertyListData(ds)
    };
  
  function  renderCheckList (list){
 
      const element = list.map(e => (
        <div key={e.id} >
             <PropertyListItem
             Label={e.property_label}
             data={list}
             Id={e.id}
             Value={e.property_value}
             ControlType={e.control_type}
            onChange={handleCheck}  
             >



             </PropertyListItem>
        </div>
      ));
      return element;
    };
   
      return (
        <div> 
          {renderCheckList(list)}
        </div>
      );
   
  }