import React  from "react";
import { Col } from "react-bootstrap";
import CheckListItem from "./PropertyListItem";


export default class CheckList extends React.Component {
  render() {
    const {  data, handleCheck } = this.props;
    return (
      <div>
        <Col md={12}>
          <CheckListItem
            Label={data.CoverName}
            Value={data.CoverName}      
            Id={data.CoverId}  
            onChange={handleCheck}
          >
          </CheckListItem>
        </Col>
      </div>
    );
  }
}
