import React  from "react";
import { Col } from "react-bootstrap";
import CheckListItem from "./CheckListItem";


export default class CheckList extends React.Component {
  render() {
    const { isChecked, data, handleCheck } = this.props;
    return (
      <div>
        <Col md={12}>
          <CheckListItem
            Text={data.CoverName}
            Id={data.CoverId}
            Checked={isChecked}
            onChange={() => handleCheck(data)}
          >
          </CheckListItem>
        </Col>
      </div>
    );
  }
}
