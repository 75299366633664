import React from "react"
import Navbar from "../../component/Navbar/NavbarMasterData"
import './App.css';

const  MasterPageControl=()=> {

  return (  <div className="App">
     <Navbar/>
     </div>
  );

}
export default MasterPageControl;
