import React, { Component, useState } from "react"
import axios from "axios"
import { ApiList } from "../../../DataOperation/apilink"
import ComboboxV1 from "../../../component/combobox/ComboboxV1"

class AddAgent extends Component {


    constructor(props) {
        super(props)
        this.state = { agent_name: '', agent_title:'',validate_message: '' }
        this.onChange = this.onChange.bind(this);

    }
    onChange(e, val) {

        if (e.target != null && e.target.name) {
            this.setState({
                [e.target.name]: e.target.value,
                validate_message: (e.target.value.length > 0 ? '' : '*'),
                user_id: 1 
            })
        } else {
            //   this.calculateCommissionBrakeDown(e,val)
            this.setState({
                [val]: e, validate_message: (val.length > 0 ? '' : '*'),
                user_id: 1 
            })
        }
        console.log(this.state);



    }

    addInsurer(newInsurer, e) {
        axios.post(ApiList.pd_agent_create, newInsurer)
            .then((response) => {


                if (response.data.data != undefined) {
                    this.props.onListChange();
                    e.target.reset();

                } else {
                    this.setState({
                        validate_message: response.data.error
                    })
                }


            }, (error) => {
                console.log('error', error);
            });



    }



    onSubmit(e) {      

        if (this.state.agent_title.length > 0 && this.state.agent_name.length > 0) {
            const newInsurer = this.state;
            console.log(newInsurer);
            this.addInsurer(newInsurer, e);

        }
        else {
            this.setState({

                validate_message: '*'
            })

        }


        e.preventDefault();

    }


    render() {
        const titleData = [
            { value: 'Mr', label: 'Mr' },
            { value: 'Mrs', label: 'Mrs' },
            { value: 'Miss', label: 'Miss' }
        ]

        return (
            <div>
                <form onSubmit={this.onSubmit.bind(this)}>
                    <li className="list-group-item">
                        <div className="container">
                            <div className="row">
                                <div className="master-data-page-tittle"> <span>Add New {this.props.master_page_tittle}</span></div>
                            </div>
                            <div className="master-data-page-body">
                                <div className="row">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td> <label ></label></td>
                                                <td> <label > Tittle</label></td>
                                                <td>
                                                    <ComboboxV1
                                                        hadlechangeUD={this.onChange}
                                                        options={titleData}
                                                        name="agent_title"
                                                    ></ComboboxV1>
                                                </td>
                                                <td> <label >Name</label></td>
                                                <td>     <input
                                                    type="text" onChange={this.onChange}
                                                    name="agent_name" ref="agent_name"
                                                    placeholder="Name"
                                                    id="txt_agent_name"></input>
                                                </td>
                                                <td>    <button type="submit" value="save" className="btn btn-info">(+)Add</button></td>
                                                <td> <label ></label></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>    <div className="row">
                                    <div col="col">
                                        <spa>{this.state.validate_message}</spa>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </li>
                </form>
            </div>
        )

    }



}

export default AddAgent