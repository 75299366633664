import React, { Component } from "react"
import ComboBranch from '../../component/combobox/ComboboxV1'
import axios from "axios"
import { ApiList } from "../../DataOperation/apilink"
import './policybasic.css'
import ComboboxV1 from '../combobox/ComboboxV1'
import CoverList from "../../Ibs-pages/ibs-master-data/cover/CoverList"
import CheckBoxIndex from "../PropertyList/CheckBoxIndex"
import CustomTextArea from "../../component/CustomTextArea/CustomTextArea"
class PolicyBasic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            insurerData: [],
            branchData: [],
            insuranceClassData: [],
            occupationData: [],
            policyTypeData: []
        }
    }

    componentWillMount() {
        this.getInsurerList();

    }


    getInsurerList() {
        axios.post(ApiList.md_policy_md, {
            user_id: 1,
            insurance_class_type: this.props.insuranceClassType,
            ins_class_id: this.props.ins_class_id
        })
            .then((response) => {
                console.log("response pd", response)
                this.setState({
                    insurerData: response.data.insurer,
                    branchData: response.data.branch,
                    insuranceClassData: response.data.ins_class,
                    occupationData: response.data.occupation,
                    policyTypeData: response.data.policy_type

                }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });
    }
    render() {
        const paymentMode = [
            { value: '0', label: 'One Time' },
            { value: '1', label: 'Monthly' },
            { value: '2', label: 'Quarterly' },
            { value: '3', label: 'Annual' }
        ]


        const IsLife = (this.props.insuranceClassType == "L")

        const policy_ref_no = this.props.policy_ref_no;

        const coverListData = this.props.coverListData;

        return (
            <div className="cls-np-main-container">
                <div>
                    <div>
                        <table className="table-pd">
                            <tbody>
                                <tr>
                                    <td >
                                        <div className="div_label">Branch</div>

                                    </td>
                                    <td >

                                        <ComboBranch
                                            hadlechangeUD={this.props.hadlechangeUD}
                                            options={this.state.branchData}
                                            selectedValue={this.props.policydata.branch_id}
                                            name='branch_id'
                                            isDisabled={this.props.IsEndosement}

                                        ></ComboBranch>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Insurer
                                    </td>
                                    <td>
                                        <ComboBranch
                                            hadlechangeUD={this.props.hadlechangeUD}
                                            options={this.state.insurerData}
                                            selectedValue={this.props.policydata.insurer_id}
                                            name='insurer_id'
                                            isDisabled={this.props.IsEndosement}
                                        ></ComboBranch>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Class of Insurance
                                    </td>
                                    <td >
                                        <ComboBranch
                                            hadlechangeUD={this.props.hadlechangeUD}
                                            options={this.state.insuranceClassData}
                                            name='ins_class_id'
                                            selectedValue={this.props.policydata.ins_class_id}
                                            isDisabled={this.props.IsEndosement}
                                        ></ComboBranch>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Policy Type
                                    </td>
                                    <td >
                                        <ComboBranch

                                            hadlechangeUD={this.props.hadlechangeUD}
                                            // selectedValue={this.props.policy_type_id}
                                            selectedValue={this.props.policydata.policy_type_id}
                                            //  options={this.state.policyTypeData}
                                            options={this.props.policyTypes}
                                            isDisabled={this.props.IsEndosement}

                                            name='policy_type_id'

                                        ></ComboBranch>
                                    </td>
                                </tr>
                                <tr style={{ display: 'none' }}>
                                    <td>
                                        Occupation
                                    </td>
                                    <td >
                                        <ComboBranch
                                            hadlechangeUD={this.props.hadlechangeUD}
                                            options={this.state.occupationData}
                                            selectedValue={this.props.policydata.occupation_id}
                                            name='occupation_id'
                                        ></ComboBranch>
                                    </td>
                                </tr>

                                <tr style={{ display: ((IsLife || this.props.policydata.ins_class_id != 'MOT') ? 'none' : '') }}>
                                    <td>
                                        Vehicle No
                                    </td>
                                    <td >
                                        <div >
                                            <input type="text"
                                                className="ibsinput"
                                                value={this.props.policydata.vehicle_no}
                                                onChange={(event) => this.props.hadlechangeUD(event)}
                                                name="vehicle_no"
                                                placeholder="Vehicle No" ></input>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={2}>
                                        <CheckBoxIndex coverListData={this.props.propertyData} policy_ref_no={this.props.policy_ref_no}
                                            ins_class_id={this.props.ins_class_id}
                                            setPropertyListData={this.props.setProperListData}
                                            setCoverList={this.props.setCoverList}></CheckBoxIndex>
                                    </td>
                                </tr>





                                <tr style={{ display: ((IsLife || this.props.policydata.ins_class_id != 'MOT') ? 'none' : '') }}>
                                    <td colSpan={2}>
                                        {/*
                                         <MotorPropertyUI hadlechangeUD={this.props.hadlechangeUD}  propertyData={this.props.propertyData}></MotorPropertyUI>

                                        */}

                                    </td>

                                </tr>
                                <tr style={{ display: ((this.props.policydata.ins_class_id == 'MOT') ? 'none' : '') }}>
                                    <td colSpan={2}>  {/*
                                         <PropertyUI hadlechangeUD={this.props.hadlechangeUD}    propertyData={this.props.propertyData} policy_ref_no={policy_ref_no}></PropertyUI>

                                     */}

                                    </td>

                                </tr>
                                <tr style={{ display: ((IsLife) ? 'none' : '') }}>
                                    <td>
                                        Cover Note No
                                    </td>
                                    <td >
                                        <div >
                                            <input type="text"
                                                className="ibsinput"
                                                placeholder="Cover Note"
                                                value={this.props.policydata.cover_note_no}
                                                onChange={(event) => this.props.hadlechangeUD(event)}
                                                name="cover_note_no"></input>
                                        </div>
                                    </td>
                                </tr>

                                <tr style={{ display: ((IsLife) ? 'none' : '') }}>
                                    <td>
                                        <span style={{ top: "0" }}>Covers</span>
                                    </td>
                                    <td>

                                        <CoverList coverListData={coverListData} policy_ref_no={this.props.policy_ref_no} setCoverList={this.props.setCoverList}></CoverList>
                                    </td>
                                </tr>
                                <tr style={{ display: ((IsLife) ? 'none' : '') }}>
                                    <td >

                                    </td>
                                    <td >
                                        <div >
                                            <CustomTextArea
                                                ClassName="text-area"
                                                onChange={(event) => this.props.hadlechangeUD(event)}
                                                Name="covers"
                                                Value={this.props.policydata.covers}
                                                Placeholder="Covers"
                                            >
                                            </CustomTextArea>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Policy No
                                    </td>
                                    <td >
                                        <div >
                                            <input type="text" placeholder="Policy numnber"
                                                className="ibsinput"
                                                value={this.props.policydata.policy_no}
                                                onChange={(event) => this.props.hadlechangeUD(event)}
                                                name="policy_no"></input>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    Issue Date 
                                    </td>
                                    <td >
                                        <div >                                        
                                        <input
                                                style={{ width: '150px', height: '35px' }}
                                                type="date"
                                                name="issue_date"
                                                value={this.props.policydata.issue_date}
                                                onChange={this.props.hadlechangeUD}>


                                            </input>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {(IsLife ? 'commencement  Date' : 'Duration(from/to)')}

                                    </td>
                                    <td >
                                        <div >

                                            <input
                                                style={{ width: '150px', height: '35px' }}
                                                type="date"
                                                name="period_from"
                                                value={this.props.policydata.period_from}
                                                onChange={this.props.hadlechangeUD}>


                                            </input>
                                            {/*TO DO*/}

                                            {(IsLife ? 'Maturity Date' : '')}
                                            <input style={{
                                                width: '150px', height: '35px', display:
                                                    ((this.props.policydata.ins_class_id == "MAR" && this.props.policydata.policy_type_id != 36)
                                                        ? "none" : "inline")
                                            }} type="date"
                                                name="period_to"

                                                value={this.props.policydata.period_to}

                                                onChange={this.props.hadlechangeUD}></input>



                                        </div>
                                    </td>
                                </tr>

                                <tr style={{ display: (IsLife ? '' : 'none') }}>
                                    <td>
                                        Payment Mode
                                    </td>
                                    <td >
                                        <div >

                                            <ComboboxV1
                                                hadlechangeUD={this.props.hadlechangeUD}

                                                selectedValue={this.props.policydata.payment_mode}
                                                options={paymentMode}
                                                name={'payment_mode'}
                                            ></ComboboxV1>



                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>)
    }

}

export default PolicyBasic