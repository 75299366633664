export const MenuItems = [
    {
        tittle: 'My Dashboard',
        url: '/my-dashboard',
        cName: 'nav-links bar-alert'

    },
    {
        tittle: 'Get Started',
        url: '/dashboard',
        cName: 'nav-links bar-alert'

    },
    {
        tittle: 'Home',
        url: '/dashboard',
        cName: 'nav-links'

    }    ,
    {
        tittle: 'Master Data',
        url: '/master-data',
        cName: 'nav-links'

    }  
    ,
    {
        tittle: 'Contact Us',
        url: '#',
        cName: 'nav-links'

    }
    ,
    {
        tittle: 'Sing In',
        url: '/login',
        cName: 'nav-links-mobile'

    }
    

]