import React from "react";
import CustomTextArea from "../CustomTextArea/CustomTextArea";
import NumericTextBox from "react-numeric-textbox";

export default function ({
    Label,
    Value,
    Id,
    onChange,
    data,
    ControlType
}) {

    const textReturn = () => {
        return (
            <input
                className="ibsinput"
                onChange={(val) => onChange(data, Id, val.target.value)}
                value={Value}
                placeholder={Label}
            >
            </input>
        )
    }

    const textAreaReturn = () => {
        return (
            <CustomTextArea
                ClassName="text-area"
                onChange={(val) => onChange(data, Id, val.target.value)}
                Value={Value}
                Placeholder={Label}
            >
            </CustomTextArea>
        )
    }

    const datePickerReturn = () => {
        return (
            <input
                style={{ width: '150px', height: '35px' }}
                type="date"
                Value={Value}
                onChange={(val) => onChange(data, Id, val.target.value)}
                Placeholder={Label}
            >
            </input>
        )
    }
    const numericTextReturn = () => {
        return (
            <NumericTextBox
                value={Number(Value)}
                onChange={(val) => { console.log("NumericTextBox va", val); onChange(data, Id, val); }}
                className="input-numeric ibsinput">
            </NumericTextBox>)
    }

    const renderSwitch = () => {
        switch (ControlType) {
            case 'Text':
                return textReturn();
            case 'TextArea':
                return textAreaReturn();
            case 'DatePicker':
                return datePickerReturn();
            case 'NumericText':
                return numericTextReturn();
            default:
                return textReturn();
        }
    }


    return (

        <tr  >
            <td style={{ verticalAlign: 'middle' }}> <div className="div_label">{Label}</div> </td>
            <td >
                {renderSwitch()}
            </td>
        </tr>
    )
}

/*
  {(ControlType == 'Text') ?
                    (<input
                        className="ibsinput"
                        onChange={(val) => onChange(data, Id, val.target.value)}
                        value={Value}
                        placeholder={Label}
                    >
                    </input>
                    ) : (
                        <CustomTextArea
                            ClassName="text-area"
                            onChange={(val) => onChange(data, Id, val.target.value)}
                            Value={Value}
                            Placeholder={Label}
                        >
                        </CustomTextArea>)}
*/