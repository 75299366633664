import React from "react";
import { MenuItems } from "../../component/Navbar/MenuItems";

const  PremiumDetails=({premiumData})=>{

    if(premiumData.length>0)

{  return (
        <div  className="rpt-premium-detail">
        {premiumData.map((item, index) => {
            return (
               
              <div className="ig-cls-detail-table-coninaier">

                        
                        <table style={{width:"100%"}}>
<tbody>
    <tr className={`css-row-${item.premium_id}`}>

        <td><div>  {item.premium_id} </div></td>
        <td> <div style={{textAlign:"right"}}>  {item.Amount} </div></td>
    </tr>
</tbody>

                        </table>
                      


               </div>
             
            )
        })}

        <div className="css-payment-condition">Payment Terms and Conditions*</div>
    </div>


    )}

  else{

    return null;
  }



}


export default PremiumDetails;