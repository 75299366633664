import React, { Component } from 'react';

class CustomTextArea extends Component {
    constructor() {
        super();
    }

    render() {
        const textAreaValue = this.props.Value;
        const numberOfLines= ((textAreaValue) ? textAreaValue.split(/\r\n|\r|\n/).length : 0)
        const minmumNumnberOfLines=5;
        const effectiveNumnerOfLines=(minmumNumnberOfLines<numberOfLines?numberOfLines:minmumNumnberOfLines)
      //  const readOnlyTextArea=this.props.ReadOnly;
        return (
            <>
                <textarea
                    className={this.props.ClassName}
                    onChange={(event) => this.props.onChange(event)}
                    name={this.props.Name}
                    value={textAreaValue}
                    placeholder={this.props.Placeholder}
                    style={{ height: effectiveNumnerOfLines* 1.6 + "em" }}
                ></textarea>
            </>
        )
    }
}

export default CustomTextArea;