import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { Modal, Col, Row, Nav } from "react-bootstrap";




const DataTableBasicDemo = ({ dataSource }) => {


    const AutharisedTemplate = (rowData) => {



        const IsAutherised = (rowData.IsValid == 0 ? 'css_not_autherise_row' : 'css_autherise_row ')
        const IsExists = (rowData.IsExists == 0 ? 'css_not_exists_row' : IsAutherised)

        let IsValid = rowData.IsValid == 1;
       
        //fa fa-check
        return (
            <div style={{ 'padding': '5px' }}>



                <div className={IsExists}>
                    {rowData.IsExists == 0 ? '' : <Checkbox
                        //    disabled={rowData.PaidAmount==0}
                        checked={IsValid}


                    ></Checkbox>}

                </div>

            </div>);
    }



    const tableRef = useRef(null);

    const exportToExcel = () => {
        tableRef.current.exportCSV(true);
    }
    return (


        <div>

            <div className="card">
                <Button type="button" color="primary" onClick={() => { exportToExcel() }} className="tb-export-button" data-pr-tooltip="CSV" >Export to CSV</Button>
                <div>
                    <Row>

                        <Col sm={2}>
                            <table style={{ width: '150px' }}>
                                <tr>
                                    <td>    <div className='css_not_autherise_row'>Mismatched</div></td>
                                </tr>
                            </table>
                           
                        </Col>
                        <Col sm={2}> uploaded values do not match with the application</Col>
                        <Col sm={2}>
                            <table style={{ width: '150px' }}>
                                <tr ><td> <div className='css_autherise_row'>Identical</div></td>
                                </tr>
                            </table>
                         
                        </Col>
                        <Col sm={2}>   uploaded values match with the application</Col>
                        <Col sm={2}>
                            <table style={{ width: '150px' }}>
                                <tr>     
                                    <td>
                                    <div className='css_not_exists_row'>  No Record Found</div>
                                    </td>
                                    <td>
                                  
                                    </td>
                                                                 
                                </tr>
                            </table >
                            
                        </Col>
                        <Col sm={2}> Record does not exist in the database</Col>
                    </Row>
                    <div className='raw'>




                    </div>
                </div>

                <DataTable ref={tableRef} value={dataSource} paginator rows={50} >
                    <Column style={{ width: '4em' }} body={AutharisedTemplate} filter={false}></Column>
                    <Column field="RecordStatus" style={{ width: '8em' }} header="RecordStatus" filter={true}></Column>
                    <Column field="tranaction_date" style={{ width: '8em' }} header="Tranaction Date" filter={true}></Column>
                    <Column field="policy_no" header="Policy No" filter={true}></Column>
                    <Column field="insured_name" header="Insured Name" filter={true}></Column>

                    <Column field="effective_date" style={{ width: '8em' }} header="From" filter={true}></Column>
                    {

                        /*
                           <Column field="period_to" header="To" filter={true}></Column>
                        */
                    }

                    <Column field="currency_code" header="Currency" filter={true}></Column>
                    <Column field="debit_credit_no" header="Invoice No" filter={true} filterMatchMode={'contains'}></Column>
                    <Column field="premium_id" header="Runner" filter={true}></Column>
                    <Column field="Commission" style={{ width: '10em', textAlign: 'right' }} header="Commission (System Calculated)" filter={true}></Column>
                    <Column field="debit_credit_amount" style={{ width: '10em', textAlign: 'right' }} header="Commission (Upload Amount)" filter={true}></Column>

                    <Column field="premium_amount" style={{ width: '2em', display: "none" }} header="premium_amount" filter={true}></Column>
                    <Column field="ibs_key" style={{ width: '2em', display: "none" }} header="ibs_key" filter={true}></Column>




                </DataTable>
            </div>
        </div>
    );
}
export default DataTableBasicDemo
/*
  <Column header=" " body={statusBodyTemplate}></Column>
                    <Column header=" " body={endorsementOpen}></Column>
                    <Column header=" " body={renewalOpen}></Column>
                    <Column header=" Delete " style={{ width: '10em' }}  body={statusBodyTemplateDelete}></Column>
*/