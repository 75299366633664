import React, { Component } from 'react'
import '../AutoCompleteText/AutoCompleteText.css'

class AutoCompleteText extends Component {
    constructor(props) {
        super(props);


        const test = this.props.value;
        this.state = {
            suggestions: [],
            text: ''
        }

        //   this.suggestionSelected=this.suggestionSelected.bind(this);


    }


    onTextChange = (e) => {
        const { items } = this.props;
        const value = e.target.value;


        let _suggestions = [];
        if (value.length > 0) {
            const regex = new RegExp(`^${value}`, 'i');
            _suggestions = items.sort().filter(v => regex.test(v));
        }

        if (_suggestions.length == 0) {

           this.props.autoCompleteEventBind(value,false);
        }

        this.setState(() => ({ suggestions: _suggestions, text: value }));
    }

    suggestionSelected(value) {
        this.props.autoCompleteEventBind(value,true);
        this.setState(() => ({
            text: value,
            suggestions: []
        }))
    }



    renderSuggessions() {
        const { suggestions } = this.state;
        if (suggestions.length != 0) {
            return (<div className="css_ul_container">
                <ul>
                    {suggestions.map((item, i) => <li key={i} onClick={() => this.suggestionSelected(item)}>{item} </li>)}
                </ul>

            </div>);
        } else {
            return null;
        }

    }



    render() {

        let groupDescription;

        if (!this.state.text) {
            groupDescription = this.props.value
        } else {
            groupDescription = this.state.text
        }
        return (

            <div>
                <input value={groupDescription} onChange={this.onTextChange} type="text" className=' uppercase'/>
                {this.renderSuggessions()}


            </div>
        );
    }
}
export default AutoCompleteText;

