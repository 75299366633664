import React, { Component } from "react";
import axios from "axios"
import { ApiList } from '../../DataOperation/apilink'
import "./ContactDetailComCSS.css"
import '../policy/policybasic.css'


class InsuredDataKeyValTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        console.log("componentDidMount")
    }


    render() {

        const isIndividual = (this.props.insuredType == "individual");
        const insuredDataArray = this.props.insuredDataKeyVal;
        const boolIsDataAvailable=(this.props.insuredDataKeyVal.length>0);
        const insuredDataArrayProp = (this.props.insuredDataKeyValProp==undefined ||  boolIsDataAvailable)?[]:this.props.insuredDataKeyValProp;
        return (
            <div>
                                             
                <div>              
                    <table>
                        <tbody>

                            {insuredDataArray.map((item, index) => {
                                
                                return (
                                    <tr key={index}    style={{ display: (item.ValueColumn!= '' )? '' : 'none' }} >
                                        <td>
                                        <div className="div_label"> 
                                            {item.KeyColumn}
                                            </div>
                                            </td>
                                        <td>
                                        <div className="div_label_wide uppercase"> 
                                            {item.ValueColumn}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <table>
                        <tbody>

                            {insuredDataArrayProp.map((item, index) => {
                                
                                return (
                                    <tr key={index}    style={{ display: (item.ValueColumn!= '' )? '' : 'none' }} >
                                        <td>
                                        <div className="div_label"> 
                                            {item.KeyColumn}
                                            </div>
                                            </td>
                                        <td>
                                        <div className="div_label_wide uppercase"> 
                                            {item.ValueColumn}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }


}

export default InsuredDataKeyValTemplate