import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios'
import { ApiList } from '../../../DataOperation/apilink'
import Button from '@material-ui/core/Button';
import '../report.css'

class PlacementReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
           noOfDays:31
        }

        this.exportCSV = this.exportCSV.bind(this);
    }

    exportCSV(selectionOnly) {
        this.dt.exportCSV({ selectionOnly });
    }

    getMasterDataList() {
        axios.post(ApiList.rp_renewal_report, {
            user_id: 1,
            from_date: this.state.from_date,
            to_date: this.state.to_date
        })
            .then((response) => {              
                this.setState({ masterdata: response.data.data }, () => {
                    console.warn(this.state)
                })
            }, (error) => {
                console.log(error);
            });
    }


    render() {
        const list_table_source = this.state.masterdata;
        return (
            <div>
                <div className="container">
                    <span>Renewal Report</span>
                    <hr></hr>
                    <div className="report-control">
                        <table>
                            <tbody>
                            <tr>
                            <td>Number of days</td>
                            <td>  <input
                                style={{ width: '150px', height: '35px' }}
                                type="numeric"
                                name="period_from"
                                onChange={(e) => { this.setState({ noOfDays: e.target.value }) }}
                            >
                            </input></td>
                            <td>     </td>
                            <td>
                                <Button variant="contained" color="primary" style={{ width: "200px" }}
                                    onClick={(e) => { this.getMasterDataList() }}
                                >Submit</Button></td>
                        </tr>
                        </tbody>
                        </table>   </div>
                    <ul>
                        <div className="ig-table-container">
                            <div className="card">
                                <Button type="button" color="primary" onClick={() => this.exportCSV(false)} className="tb-export-button" data-pr-tooltip="CSV" >Export to CSV</Button>
                                <DataTable ref={(el) => { this.dt = el; }} value={list_table_source} id="id_data_report" tableClassName="tbl_css_data" >

                                    <Column field="insurer_name" style={{ width: '120em' }} header="Insurer" filter={false}></Column>
                                    <Column field="Individual Life" style={{ width: '20em', textAlign: 'right' }} header="Individual Life" filter={false}></Column>
                                    <Column field="Group Life" style={{ width: '10em', textAlign: 'right' }} header="Group Life" filter={false}></Column>

                                    <Column field="Others(Life)" style={{ width: '10em', textAlign: 'right' }} header="Others(Life)" filter={false}></Column>
                                    <Column field="Fire" style={{ width: '10em', textAlign: 'right' }} header="Fire" filter={false}></Column>
                                    <Column field="Marine" style={{ width: '10em', textAlign: 'right' }} header="Marine" filter={false}></Column>
                                    <Column field="Micallaneous" style={{ width: '10em', textAlign: 'right' }} header="Micallaneous" filter={false}></Column>
                                    <Column field="Others(Genaral)" style={{ width: '10em', textAlign: 'right' }} header="Others(Genaral)" filter={false}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </ul></div>
            </div>

        )
    }
}

export default PlacementReport
