import React, { Component } from "react";
import axios from "axios"
import { ApiList } from '../../DataOperation/apilink'
import { PropertyList } from "./MotorProperty"

class PolicyProertyView extends Component {
    constructor() {
        super()
        this.state = {
            propertyData: []
        }
    }

    componentDidMount() {
        console.log("componentDidMount prop key", this.props.pr_key);
        this.getInsuredDataByIdKeyValue(this.props.pr_key);
    }


    getInsuredDataByIdKeyValue(_param_id) {
        axios.post(ApiList.pd_policy_property_data, {
            user_id: 1,
            pr_key: 1,
            branch_id: 1,
            param_id: _param_id,
            page: 'assuser'
        })
            .then((response) => {
                var returnData = response.data.data;

                console.log(returnData);
                if (returnData != undefined) {
                    this.setState({
                        propertyData: returnData
                    })
                }

            }, (error) => {
                console.log(error);
            });

    }



    render() {


        return (
            <div>
    <table>
                        <tbody>
                            {this.state.propertyData.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td> <div className="div_label">{PropertyList.filter(k=>k.ControlName==item.property_name)[0].Label}</div> </td>
                                        <td> <div className="div_label">{item.property_value}</div> </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>         
            </div>
        )
    }


}

export default PolicyProertyView