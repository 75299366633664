import React, { Component } from "react"
import ContactDetailComp from "../../component/ContactDetail/ContactDetailCom"
import ContactDetailCompCP from "../../component/ContactDetail/ContactDetailComCP"
import NavbarSub from '../../component/Navbar/NavbarPolicy'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../ibs-policy/policynew.css'
import Tab from 'react-bootstrap/Tab'
import { Col, Row, Nav } from "react-bootstrap";
import axios from "axios"
import { ApiList } from "../../DataOperation/apilink"
import { isRequiredValidate } from "../../Validation/RequiredValidator";



class Insured extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.myHandleChange = this.myHandleChange.bind(this);
        //   this.comboxOnTextChange = this.comboxOnTextChange.bind(this);

        this.buttonHadleChange = this.buttonHadleChange.bind(this);
        this.autoCompleteHandleChange = this.autoCompleteHandleChange.bind(this);
        this.getCommissionRateByClass = this.getCommissionRateByClass.bind(this);
        this.clearData = this.clearData.bind(this);
    }


    clearData() {

        console.log('clearData');

        Array.from(document.querySelectorAll("input,textarea")).forEach(
            input => (input.value = "")
        );

        this.setState({
            insured_id: 0,
            insured_title: '',
            insured_name: '',
            insured_nic_no: '',
            insured_address_no: '',
            insured_address_road: '',
            insured_address_city: '',
            insured_contact_no: '',
            insured_email: '',
            insured_surname: '',
            insured_initials: '',
            occupation_id: '',
            contact_person_title: '',
            contact_person_name: '',
            contact_person_nic_no: '',
            contact_person_address_no: '',
            contact_person_address_road: '',
            contact_person_address_city: '',
            contact_person_contact_no: '',
            contact_person_email: '',
        })

    }
    componentDidMount() {
        let i_id = this.props.match.params.id;
        const InsuredType = this.props.insuredType;

        if (i_id && i_id.length > 0) {

            //    this.getDataById();
            this.autoCompleteHandleChange(i_id, true);


        } else {

            this.setState({
                policy_ref_no: '00-000-000-00',
                pd_policy_entry_id: '000-000-0000-000', user_id: '1', policy_no: '', policy_type_id: '',
                insured_id: 0,
                branch_id: '',
                insurer_id: '',
                ins_class_id: '',
                policy_type_id: '',
                period_from: '',
                period_to: '',
                sum_insured: '',
                vehicle_no: '',
                currency: '',
                invoice_no: '',
                cover_note_no: '',
                occupation_id: 0,
                agent_id: '',
                agent_service_id: '',
                is_active: '',
                contact_person_id: '',
                insured_title: '',
                insured_name: '',
                insured_id: 0,
                insured_nic_no: '',
                insured_address_no: '',
                insured_address_road: '',
                insured_address_city: '',
                insured_contact_no: '',
                insured_email: '',
                insured_surname: '',
                insured_initials: '',
                contact_person_title: '',
                contact_person_name: '',
                contact_person_nic_no: '',
                contact_person_email: '',
                contact_person_address_no: '',
                contact_person_address_road: '',
                contact_person_address_city: '',
                contact_person_contact_no: '',
                premium_basic: 0, premium_srcc: 0, premium_terror: 0, premium_total: 0, premium_svat: 0,
                commission_comm_rate_br_basic: 0,
                commission_comm_rate_br_srcc: 0,
                commission_comm_rate_br_terr: 0,
                agent_commission_comm_rate_br_basic: 0,
                agent_commission_comm_rate_br_srcc: 0,
                agent_commission_comm_rate_br_terr: 0,
                policy_entry: [],
                policy_com_rate: [],
                error_message: '',
                respons_label_class: '',
                user_id: 1,
                COMBR: [],
                COMSR: [],
                COMTR: [],
                COMAGBR: [],
                COMAGSR: [],
                COMAGTR: [],
                entry_type: this.props.EntryType,
                note_type: this.props.NoteType,
                Reasons: [],
                Reason: 0,
                Remark: '',
                insured_type: InsuredType,
                business_reg_no: ''


            });

        }



    }

    getDataById() {
        let i_id = this.props.match.params.id;
        let eid = this.props.match.params.eid;
        const ActionType = this.props.ActionType;

        //    console.log("this.props.match.params", this.props.match.params);
        axios.post(ApiList.pd_policy_by_id, {
            user_id: 1,
            pr_key: i_id,
            branch_id: 1,
            policy_entry_id: (ActionType == "New" ? -1 : eid)
        })
            .then((response) => {

                //   console.log("response", response);

                var p_data = response.data.data[0];
                var p_data_entry = response.data.data_entry;
                var p_data_com_rate = response.data.data_rate;
                var p_data_com_rate_basic = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "Basic")]
                var p_data_com_rate_srcc = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "SRCC")]
                var p_data_com_rate_terror = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "Terrorism")]
                var p_data_com_rate_tax = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "Tax")]
                var p_data_com_rate_tax_svat = p_data_com_rate[p_data_com_rate.findIndex(obj => obj.premium_id == "SVAT")]
                var m_premium_total = (
                    p_data_com_rate_basic.premium_debit +
                    p_data_com_rate_srcc.premium_debit +
                    p_data_com_rate_terror.premium_debit +
                    p_data_com_rate_tax.premium_debit +
                    p_data_com_rate_tax_svat

                );
                this.setState({
                    policy_ref_no: p_data.policy_ref_no,
                    pd_policy_entry_id: eid,
                    policy_no: p_data.policy_no,
                    insured_id: p_data.insured_id,
                    branch_id: p_data.branch_id,
                    insurer_id: p_data.insurer_id,
                    ins_class_id: p_data.ins_class_id,
                    policy_type_id: p_data.policy_type_id,
                    period_from: p_data.period_from,
                    period_to: p_data.period_to,
                    sum_insured: p_data.sum_insured,
                    vehicle_no: p_data.vehicle_no,
                    currency: p_data.currency,
                    invoice_no: p_data.invoice_no,
                    cover_note_no: p_data.cover_note_no,
                    // occupation_id: p_data.occupation_id,
                    agent_id: p_data.agent_id,
                    agent_service_id: p_data.agent_service_id,
                    is_active: p_data.is_active,
                    contact_person_id: p_data.contact_person_id,

                    insured_title: p_data.insured_title,
                    insured_name: p_data.insured_name,
                    insured_nic_no: p_data.insured_nic_no,
                    insured_address_no: p_data.insured_address_no,
                    insured_address_road: p_data.insured_address_road,
                    insured_address_city: p_data.insured_address_city,
                    insured_contact_no: p_data.insured_contact_no,
                    insured_email: p_data.insured_email,
                    insured_surname: p_data.insured_surname,
                    insured_initials: p_data.insured_initials,

                    business_reg_no: p_data.insured_name,

                    contact_person_title: p_data.contact_person_title,
                    contact_person_name: p_data.contact_person_name,
                    contact_person_nic_no: p_data.contact_person_nic_no,
                    contact_person_address_no: p_data.contact_person_address_no,
                    contact_person_address_road: p_data.contact_person_address_road,
                    contact_person_address_city: p_data.contact_person_address_city,
                    contact_person_contact_no: p_data.contact_person_contact_no,
                    contact_person_email: p_data.contact_person_email,

                    //  pd_policy_entry_id: p_data_entry[0].entry_id,
                    policy_entry: p_data_entry,
                    Reason: p_data_com_rate[0].Reason,
                    Remark: p_data_com_rate[0].Remark,
                    policy_com_rate: p_data_com_rate,
                    premium_basic: p_data_com_rate_basic.premium_debit,
                    premium_srcc: p_data_com_rate_srcc.premium_debit,
                    premium_terror: p_data_com_rate_terror.premium_debit,
                    premium_total: m_premium_total,
                    premium_svat: p_data_com_rate_tax_svat,

                    commission_comm_rate_br_basic: p_data_com_rate_basic.com_in_rate,
                    commission_comm_rate_br_srcc: p_data_com_rate_srcc.com_in_rate,
                    commission_comm_rate_br_terr: p_data_com_rate_terror.com_in_rate,
                    agent_commission_comm_rate_br_basic: p_data_com_rate_basic.com_out_rate,
                    agent_commission_comm_rate_br_srcc: p_data_com_rate_srcc.com_out_rate,
                    agent_commission_comm_rate_br_terr: p_data_com_rate_terror.com_out_rate,

                    entry_type: this.props.EntryType,
                    note_type: this.props.NoteType

                }, () => {
                    this.getCommissionRateByClass(this.state.ins_class_id);
                })
            }, (error) => {
                //console.log(error);
            });

    }

    getCommissionRateByClass(ins_class_id) {
        axios.post(ApiList.pd_policy_rates, {
            user_id: 1, ins_class_id: ins_class_id
        })
            .then((response) => {


                this.setState({
                    COMBR: response.data.COMBR,
                    COMSR: response.data.COMSR,
                    COMTR: response.data.COMTR,
                    COMAGBR: response.data.COMAGBR,
                    COMAGSR: response.data.COMAGSR,
                    COMAGTR: response.data.COMAGTR,
                    Reasons: response.data.endorsement_reason
                }, () => {
                    //   console.log(this.state)
                })
            }, (error) => {
                //console.log(error);
            });
    }
    buttonHadleChange(e, s) {
        console.log(this.state);
        if (this.IsFormValid()) {
            this.createPolicy(this.state);
        }


    }

    IsFormValid() {
        let validate_message = '';
        let isValid=true;
        let css_class_name = '';
        if(!isRequiredValidate('css_required')){
            validate_message = 'Please fill the required fields';
            isValid=false;
            css_class_name='css_lbl_error';
        }
        this.setState({
            error_message: validate_message,
            respons_label_class: css_class_name
        })
        return isValid;
    }

    autoCompleteHandleChange(value, isDataAvailable) {

        if (isDataAvailable) {
            axios.post(ApiList.pd_md_insured_by_id, {
                user_id: 1,
                pr_key: 1,
                branch_id: 1,
                nic_no: value,
                param_id: value
            })
                .then((response) => {
                    if (response.data.insured_data.length > 0) {
                        this.setState({
                            insured_id: response.data.insured_data[0].insured_id,
                            insured_title: response.data.insured_data[0].insured_title,
                            insured_name: response.data.insured_data[0].insured_name,
                            insured_nic_no: response.data.insured_data[0].insured_nic_no,
                            insured_address_no: response.data.insured_data[0].insured_address_no,
                            insured_address_road: response.data.insured_data[0].insured_address_road,
                            insured_address_city: response.data.insured_data[0].insured_address_city,
                            insured_contact_no: response.data.insured_data[0].insured_contact_no,
                            insured_email: response.data.insured_data[0].insured_email,
                            insured_surname: response.data.insured_data[0].insured_surname,
                            insured_initials: response.data.insured_data[0].insured_initials,
                            occupation_id: response.data.insured_data[0].occupation_id,

                            contact_person_title: response.data.insured_data[0].contact_person_title,
                            contact_person_name: response.data.insured_data[0].contact_person_name,
                            contact_person_nic_no: response.data.insured_data[0].contact_person_nic_no,
                            contact_person_address_no: response.data.insured_data[0].contact_person_address_no,
                            contact_person_address_road: response.data.insured_data[0].contact_person_address_road,
                            contact_person_address_city: response.data.insured_data[0].contact_person_address_city,
                            contact_person_contact_no: response.data.insured_data[0].contact_person_contact_no,
                            contact_person_email: response.data.insured_data[0].contact_person_email,



                        })
                    }
                    else {
                        this.setState({
                            //  insured_id: 0,
                            //  insured_nic_no: value

                        })
                    }



                    // //console.log(this.state);

                }, (error) => {
                    //console.log(error);
                });
        } else {
            this.setState({
                // insured_id: 0,
                // insured_nic_no: value

            })

        }
    }
    refreshPage() {
        window.location.href = window.location.href;

    }

    createPolicy(policy_json) {
        let i_id = this.props.match.params.id;
        let active_api = ((i_id > 0) ? ApiList.pd_md_insured_update : ApiList.pd_md_insured_create);


        axios.post(active_api, policy_json)
            .then((response) => {
                if (response.data.status == "1") {
                    console.log("response.data", response.data);
                    if (response.data.data[0]) {
                        this.setState({
                            policy_ref_no: response.data.data[0].insured_id,
                            insured_id: response.data.data[0].insured_id,
                            user_id: 1,
                            error_message: response.data.data[0].response_message,
                            respons_label_class: 'css_lbl_success'
                            //  policy_entry: response.data.entrylist
                        }, this.clearData)
                    }
                } else {
                    this.setState({
                        error_message: response.data.error,
                        respons_label_class: 'css_lbl_error'
                    });

                }

            }, (error) => {
                //console.log(error);
            });

    }

    myHandleChange(val, e) {
        if (val.target != null && val.target.name) {
            this.setState({ [val.target.name]: val.target.value, user_id: 1, error_message: '' })
        } else {
            this.setState({ [e]: val, user_id: 1, error_message: '' })
        }
        isRequiredValidate('css_required')
    }




    render() {

        const insuredData = {
            title: this.state.insured_title,
            name: this.state.insured_name,
            nic_no: this.state.insured_nic_no,
            address_no: this.state.insured_address_no,
            address_road: this.state.insured_address_road,
            address_city: this.state.insured_address_city,
            contact_no: this.state.insured_contact_no,
            email: this.state.insured_email,
            surname: this.state.insured_surname,
            initials: this.state.insured_initials,
            occupation_id: this.state.occupation_id

        }
        const insured_Conatact_Data = {
            title: this.state.contact_person_title,
            name: this.state.contact_person_name,
            nic_no: this.state.contact_person_nic_no,
            address_no: this.state.contact_person_address_no,
            address_road: this.state.contact_person_address_road,
            address_city: this.state.contact_person_address_city,
            contact_no: this.state.contact_person_contact_no,
            email: this.state.contact_person_email

        }




        const TaskType = this.props.TaskType;
        const IsEndosement = (this.props.EntryType == "ED" || this.props.EntryType == "EC")
        const defaultActiveKey = (IsEndosement ? "third" : "first");

        return (



            <div >

                <NavbarSub pagetitle="New Policy"></NavbarSub>


                <Tab.Container id="left-tabs-example" defaultActiveKey={defaultActiveKey}>
                    <Row>
                        <Col sm={3}>
                            <div className="css_task_title"> {TaskType} </div>

                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" disabled={IsEndosement}>Customer Details / Contact Person</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>

                                    <div className="div-tab-button-container">
                                        <div className="div-tab-data-view">
                                            <div className="row"></div>
                                            <div className="row"></div>


                                            <div className="row"></div>
                                            <div className="row"> <span></span></div>







                                            <div className={this.state.respons_label_class}>
                                                {this.state.error_message}
                                            </div>
                                        </div>

                                        <button className="btn btn-success" onClick={this.buttonHadleChange}>Save</button>

                                        <button className="btn btn-info" onClick={this.refreshPage}>Clear</button>
                                    </div>

                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="flex-container">
                                        {JSON.stringify(this.state.insured_name)}
                                        <div className="flex-child magenta">
                                            <ContactDetailComp name="insured"
                                                data={insuredData}
                                                hadlechangeUD={this.myHandleChange}
                                                insuredType={this.props.insuredType}
                                                legend_title="Customer Details"
                                            // autoCompleteHandleChange={this.autoCompleteHandleChange}
                                            ></ContactDetailComp>

                                        </div>

                                        <div className="flex-child green">
                                            <ContactDetailCompCP name="contact_person"
                                                data={insured_Conatact_Data} hadlechangeUD={this.myHandleChange}
                                                legend_title="Contact Person">

                                            </ContactDetailCompCP>

                                        </div>

                                    </div>
                                </Tab.Pane>

                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>



            </div>
        )

    }
}

export default Insured;
