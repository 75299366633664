import React from "react"
import { useHistory } from 'react-router-dom';
import "../Button.css";

const STYLES = [
  'btn--primary',
  'btn--outline'
]

const SIZES =
  [
    'btn--medium',
    'btn--large'
  ]
function Logfun() {
  let history = useHistory();

  const redirect = () => {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token")
      localStorage.removeItem("UserId")
      localStorage.removeItem("UserIndex")

      history.push('/')
    } else {
      history.push('/login')

    }


  }

  const checkButtonStyle = STYLES[0]

  const checkButtonSize = SIZES[0]

  return (
    <div className="nav-bar-login-button">
      <button 
className="btn btn-info login-navbar-button"
style={{fontSize:"medium",backgroundColor:"#17202A",width:"100px"}}
        onClick={redirect}>{localStorage.getItem("token") ? 'Logout' : 'Log In'}</button>

        
<br></br>     

                <span style={{color:"white"}}> {localStorage.getItem("token") ? ' Hi ' : ''}  {localStorage.getItem("UserId")}</span> 
    </div>
  )
}

export default Logfun;