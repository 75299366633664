
import React, { Component } from "react"
import NumericTextBox from "react-numeric-textbox"

class PremiumBrakeDown extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        const IsLife = (this.props.insuranceClassType == "L")

        return (
            <div className="cls-np-main-container">
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="div_label">   Basic</div>
                                </td>
                                <td>

                                    <NumericTextBox
                                        onChange={(event) => this.props.hadlechangeUD(event, 'premium_basic')}
                                        name="premium_basic"
                                      value={this.props.premium_basic}
                                        className="input-numeric ibsinput">
                                    </NumericTextBox>
                                </td>


                            </tr>
                            <tr style={{ display: ((IsLife) ? 'none' : '') }}>
                                <td>
                                    S.R.C.C
                                </td>
                                <td>
                                    <NumericTextBox
                                        onChange={(event) => this.props.hadlechangeUD(event, 'premium_srcc')}
                                        name="premium_srcc"
                                        value={this.props.premium_srcc}
                                        className="input-numeric ibsinput">

                                    </NumericTextBox>
                                </td>

                            </tr>
                            <tr style={{ display: ((IsLife) ? 'none' : '') }}>
                                <td>
                                    Terrorisam
                                </td>
                                <td>
                                    <NumericTextBox
                                        onChange={(event) => this.props.hadlechangeUD(event, 'premium_terror')}
                                        name="premium_terror"
                                        premium_terror
                                        value={this.props.premium_terror}
                                        //      value={premiumTerrorismValue.premium_debit}
                                        className="input-numeric ibsinput">

                                    </NumericTextBox>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    Premium Due
                                </td>
                                <td>
                                    <NumericTextBox

                                        onChange={(event) => this.props.hadlechangeUD(event, 'premium_due')}
                                        value={this.props.premium_due}
                                        name="premium_due"
                                        disabled={true}
                                        className="input-numeric ibsinput">

                                    </NumericTextBox>
                                </td>

                            </tr>
                            <tr style={{ display: (this.props.is_svat_registed ? '' : 'none') }}>
                                <td>

                                    SVAT
                                </td>
                                <td>
                                    <NumericTextBox

                                        onChange={(event) => this.props.hadlechangeUD(event, 'premium_svat')}
                                        value={this.props.premium_svat}
                                        name="premium_svat"
                                        className="input-numeric ibsinput">

                                    </NumericTextBox>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    Taxes
                                </td>
                                <td>
                                    <NumericTextBox

                                        onChange={(event) => this.props.hadlechangeUD(event, 'premium_tax')}
                                        value={this.props.premium_tax}
                                        name="premium_tax" disabled={true}
                                        className="input-numeric ibsinput">

                                    </NumericTextBox>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    Total
                                </td>
                                <td>
                                    <NumericTextBox

                                        onChange={(event) => this.props.hadlechangeUD(event, 'premium_total')}
                                        name="premium_total"
                                        value={this.props.premium_total}
                                        className="input-numeric ibsinput">

                                    </NumericTextBox>
                                </td>

                            </tr>
                        </tbody>
                    </table>



                </div>





            </div>
        )
    }
}

export default PremiumBrakeDown