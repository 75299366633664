import React, { Component} from "react"
import axios from "axios"
import { ApiList } from "../../../DataOperation/apilink"
import Navbar from "../../../component/Navbar/NavbarMasterData"
import ComboBranch from '../../../component/combobox/ComboboxV1'

class EditPolicyType extends Component {


    constructor(props) {
        super(props)
        this.state = { 
            policy_type_code: '', 
            policy_type_name:'',
            validate_message: '', 
            ins_class_id:'',
            insuranceClassData: [] }
        this.onChange = this.onChange.bind(this);

    }
    componentWillMount() {
        this.getPolicyClass();
        this.getInsurerList();
    }

    getPolicyClass() {
        axios.post(ApiList.pd_policy_class_data, {
            user_id: 1,
            insurance_class_type: "G"
        })
            .then((response) => {               
                this.setState({
                    insuranceClassData: response.data.data

                }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });
    }
    
    onChange(val, e) {
        if (val.target != null && val.target.name) {       
            this.setState({ [val.target.name]: val.target.value, user_id: 1 })       
        } else {
                     this.setState({ [e]: val, user_id: 1 })       
          
        } 
    }

    addInsurer(newInsurer,e) {
               axios.post(ApiList.pd_policy_type_update, newInsurer)
            .then((response) => {   
                
     
                if(response.data.data!=undefined){
                 //   this.props.onListChange();
                //    e.target.reset();
                console.log("update return value",response.data);
            this.props.history.push('/master-data/md-policy-type');
            
            }else{
                this.setState({                   
                    validate_message:response.data.error
                })
            }


            }, (error) => {
                console.log('error',error);
            });



    }



    getInsurerList() {
        let i_id = this.props.match.params.id;
        axios.post(ApiList.pd_policy_type_data_by_id, {
            user_id: 1,
            policy_type_id: i_id
        })
            .then((response) => {
                this.setState({ 
                    policy_type_code: response.data.data[0].policy_type_code,
                    policy_type_name: response.data.data[0].policy_type_name,
                    ins_class_id:response.data.data[0].ins_class_id,

                 }, () => {
                    console.log("load data ",this.state)
                })
            }, (error) => {
                console.log(error);
            });

    }

    onSubmit(e) {

        let m_policy_type_code = this.refs.policy_type_code.value
        let m_policy_type_name = this.refs.policy_type_name.value

        if (m_policy_type_code.length > 0 && m_policy_type_name.length > 0 ) {
            const newInsurer = {
                policy_type_id :this.props.match.params.id,
                policy_type_code: m_policy_type_code,
                policy_type_name:m_policy_type_name,
                ins_class_id:this.state.ins_class_id,
                user_id: 1 }
            this.addInsurer(newInsurer,e);
          
        }
        else {
            this.setState({
              
                validate_message:  '*'
            })

        }


        e.preventDefault();

    }


    render() {


        return (
            <div>
                             <Navbar></Navbar>
                <h1>Edit Policy Type</h1>
                <form onSubmit={this.onSubmit.bind(this)}>
                <li className="list-group-item">
                    <div className="container">
                    <div className="row">
                                <div className="master-data-page-tittle"> <span>Edit Policy Type </span></div>
                            </div>
                            <div className="master-data-page-body">
                                <div className="row">
                                <table>
                                        <tbody>
                                            <tr>
                                                <td> <label ></label></td>
                                                <td style={{display:'none'}}> <label > Code</label></td>
                                                <td style={{display:'none'}}>     <input
                                                value={this.state.policy_type_code}
                                                    type="text" onChange={this.onChange}
                                                    name="policy_type_code" ref="policy_type_code"
                                                    placeholder= "Code"                                           
                                                    id="txt_policy_type_code"></input>
                                                </td>
                                                <td> <label >Name</label></td>
                                                <td>     <input
                                                 value={this.state.policy_type_name}
                                                    type="text" onChange={this.onChange}
                                                    name="policy_type_name" ref="policy_type_name"
                                                    placeholder= "Name"                                              
                                                    id="txt_policy_type_name"></input>
                                                </td>
                                                <td> <label >Class</label></td>
                                                <td>

                                                    <ComboBranch
                                                        hadlechangeUD={this.onChange}
                                                        options={this.state.insuranceClassData}
                                                        name='ins_class_id'
                                                        selectedValue={this.state.ins_class_id}

                                                    ></ComboBranch>
                                                </td>
                                                <td>    <button type="submit" value="save" className="btn btn-info">Update</button></td>
                                                <td> <label ></label></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>    <div className="row">
                                    <div col="col">
                                        <spa>{this.state.validate_message}</spa>
                                    </div>

                                </div>
                            </div>





                  
                    </div>
                    </li>
                </form>
            </div>
        )

    }



}

export default EditPolicyType