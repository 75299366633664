
import axios from 'axios';
import {ApiList} from '../../../DataOperation/apilink'


export default class ProductService {

    getProductsSmall(policy_class_type) {
       // return axios.get( a).then(res => res.data.data);
       return  axios.post(ApiList.pd_policy_data, { user_id: 1,branch_code:"HO",policy_class_type:policy_class_type}).then(res => res.data.data);
  
    }

    getPolicyTransactionData(policy_class_type) {      
        return  axios.post(ApiList.pd_policy_transaction_data, { user_id: 1,branch_code:"HO",policy_class_type:policy_class_type}).then(res => res.data.data);
   
     }
}