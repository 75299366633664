import axios from 'axios';
import {ApiList} from '../DataOperation/apilink'


export default class CoverDataService{

    getCoverList() {
        return  axios.post(ApiList.pd_cover_data, { user_id: 1,branch_code:"HO",policy_class_type:"G"}).then(res => res.data.data);  
    }
    getCoverListByRefId(requsted_data) {
        return  axios.post(ApiList.pd_cover_data_by_ref_id, requsted_data).then(res => res.data.data);  
    }
   
}

