import React, { Component, useState } from "react"
import axios from "axios"
import { ApiList } from "../../../DataOperation/apilink"
import ComboboxV1 from "../../../component/combobox/ComboboxV1"
import Navbar from "../../../component/Navbar/NavbarMasterData"

const titleData = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Miss', label: 'Miss' }
]


class EditCover extends Component {
    
    constructor(props) {
        super(props)
        this.state = { CoverName: '', agent_title: '', validate_message: '',agent_title_index:0 }
        this.onChange = this.onChange.bind(this);

    }
    onChange(e, val) {
        let i_id = this.props.Id;
        if (e.target != null && e.target.name) {
            this.setState({
                [e.target.name]: e.target.value,
                validate_message: (e.target.value.length > 0 ? '' : '*'),
                UserId: 1,
                CoverId: i_id
            })
        } else {
            //   this.calculateCommissionBrakeDown(e,val)
            this.setState({
                [val]: e, validate_message: (val.length > 0 ? '' : '*'),
                UserId: 1,
                CoverId: i_id
            })
        }
        console.log('onchange',this.state);



    }


    componentWillMount() {
        this.getDataById();
    }
    getDataById() {
        let i_id = this.props.Id;
        axios.post(ApiList.pd_cover_data_by_id, {
            UserId: 1,
            CoverId: i_id
        })
            .then((response) => {

                console.log(response);
                this.setState({
                    CoverName: response.data.data[0].CoverName
                  //  agent_title_index:titleData.findIndex(obj => obj.value ==  response.data.data[0].agent_title)

                }, () => {
                    console.log("load data ", this.state)
                })
            }, (error) => {
                console.log(error);
            });

    }

    addItem(newItem, e) {
        axios.post(ApiList.pd_cover_update, newItem)
            .then((response) => {


                if (response.data.data != undefined) {
                  //  e.target.reset();
                    console.log("update return value", response.data);
                   this.props.getMasterDataList();
                 

                   this.setState({
                    validate_message:'Data updated successfully'
                })
                  //  this.props.history.push('/master-data/md-agent');

                } else {
                    this.setState({
                        validate_message: response.data.error
                    })
                }


            }, (error) => {
                console.log('error', error);
            });



    }


    onSubmit(e) {

        if (this.state.CoverName.length > 0) {
            const newItem = this.state;
            console.log(newItem);
            this.addItem(newItem, e);

        }
        else {
            this.setState({

                validate_message: '*'
            })

        }


    //    e.preventDefault();

    }


    render() {
        let c_agent_title ="Mr"
        if(this.state.agent_title_index==0)
        {
            c_agent_title="Mr"
        }else{

            c_agent_title="Mrs"
        }
console.log("render",this.state.agent_title_index)
        return (
            <div>
          
             
                    <li className="list-group-item">
                        <div className="container">
                            <div className="row">
                                <div className="master-data-page-tittle"> <span>Add New {this.props.master_page_tittle}</span></div>
                            </div>
                            <div className="master-data-page-body">
                                <div className="row">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td> <label ></label></td>                                             
                                                <td> <label >Name</label></td>
                                                <td>     <input
                                                    type="text" onChange={this.onChange}
                                                    name="CoverName" ref="CoverName"
                                                    placeholder="Name"
                                                    value={this.state.CoverName}
                                                    id="txt_CoverName"></input>
                                                </td>
                                                <td>    <button onClick={()=>{this.onSubmit();}} value="save" className="btn btn-info">Update</button></td>
                                                <td> <label ></label></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>    <div className="row">
                                    <div col="col">
                                        <spa>{this.state.validate_message}</spa>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </li>
             
            </div>
        )

    }



}

export default EditCover