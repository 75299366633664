import React,{useState,useEffect} from "react";
import CheckList from './CheckList'
import _ from "lodash";
import CoverDataService from '../../DataApiService/CoverDataService'


export default function ({setCoverList,coverListData}) {
    
    const [list, setList] = useState([]);
    const  [checkboxList, setcheckboxList]=  useState(coverListData);
const coverService = new CoverDataService();
useEffect(() => {
    coverService.getCoverList({ insuredType: 'insuredType' }).then(data => { 
      setList(data);  
    } );
      setcheckboxList(coverListData);
}, [coverListData]); 

  function  handleCheck ( data ){     
      const existElement = checkboxList.find(e => e.id === data.id) || {};
      const filteredElement = checkboxList.filter(e => e.id !== data.id);  
      // check if the selected checklist inside the our checkboxList
      const insertedElement = _.isEmpty(existElement)
        ? [data, ...checkboxList]
        : filteredElement;  
        setcheckboxList(insertedElement)
        setCoverList(insertedElement)
    };
  
  function  renderCheckList (list){
      const theCheckedList = list.map(e => ({
        ...e,
        isChecked: checkboxList.some(c => e.id === c.id)
      }));
      const element = theCheckedList.map(e => (
        <div key={e.id} className="checkListWrapper">
          <CheckList
            isChecked={e.isChecked}
            handleCheck={() => handleCheck(e)}
            data={e}
          />         
        </div>
      ));
      return element;
    };
   
      return (
        <div> 
          {renderCheckList(list,coverListData)}
        </div>
      );
   
  }