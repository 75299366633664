import React, { Component } from 'react'
import { MenuItems } from "../../MenuData/MenuItemsReport"
import ButtonNavigator from "../../component/TabController/ButtonNavigator"
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import PolicySearch from  '../ibs-policy/PolicySearchPopup'

class ReportNavPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
          }
          this.handleModal= this.handleModal.bind(this);
    }   


    handleModal(e) {  
      this.setState({ show: !this.state.show })
    }


    render() {
        return (
            <div>                 
                <ButtonNavigator headerName="Reports" handleModal={this.handleModal}  layoutType="V" MenuItems={MenuItems}></ButtonNavigator>                 
                <Modal show={this.state.show} onHide={() => this.handleModal('hide')} >
            <Modal.Header closeButton>Select Policy Details</Modal.Header>
            <Modal.Body>
              <PolicySearch></PolicySearch>


            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Col>  <Button onClick={() => this.handleModal("Save")}>Ok</Button>   </Col>
                <Col></Col>
                <Col> <Button onClick={() => this.handleModal("Close")}>Cancel</Button>   </Col>
              </Row>


            </Modal.Footer>
          </Modal>

            </div>
        )
    }
}


export default ReportNavPage;