import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from "react-router-dom";



import TaskFlowService from '../DataApiService/TaskFlowService'

const PendingWorksGrid = ({ policy_class_type, policy_action }) => {
    const [products, setProducts] = useState([]);
    const [policyRefNo, setPolicyREfNo] = useState('');




    const taskFlowService = new TaskFlowService();
    const _page_name = 'policy'

    useEffect(() => {
        taskFlowService.getPendingWorks(policy_class_type).then(data => setProducts(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const policyActionURL = (policy_action,type) => {

        var returnUrl = '/edit/';
        if (policy_action == 'View') {

            returnUrl = '/edit/'
        } else if (policy_action == 'Renewal') {

            returnUrl = '/renewal/'
        } else if (policy_action == 'Endorsement') {

            returnUrl = '/endosement/'
        }
        else if (policy_action == 'Update Payment') {

            returnUrl = '/payment/update-payment/'
        }
        //

        let link_policy_class_type =(type=="G"?"general":"life")


        return `/${link_policy_class_type.toLowerCase()}${returnUrl}`;


    }


    const statusBodyTemplate = (rowData) => {

        const actionNameTemp=rowData.action_name.toLowerCase().replace(' ','');

        let actionName= (actionNameTemp=="newpolicy"? "new":actionNameTemp);
        return (
            <div>

                <Link to={`/${actionName}/${_page_name.toLowerCase()}${policyActionURL(policy_action,rowData.ins_class_type)}${rowData.ibs_key}/${rowData.ibs_entry_id}`} >{rowData.action_type_name}</Link>
            </div>);
    }

    return (
        <div className='policy-search-main-container'>
            <div className="card">
                <DataTable value={products}  >
                
                <Column field="policy_no" style={{ width: '10em',textAlign:'left'}} header="Policy No"  filter={true} filterMatchMode={'contains'} ></Column>
                    <Column field="policy_ref_no" style={{ width: '20em', textAlign:'left' }} header="Policy Ref No" filter={true} filterMatchMode={'contains'}></Column>
                    <Column field="action_ref_no"  style={{ width: '20em',textAlign:'left'}}   header="Transaction Reference No"  filter={true} filterMatchMode={'contains'}></Column>
                    <Column field="action_name" style={{ width: '10em',textAlign:'left'}} header="Task"  filter={true} filterMatchMode={'contains'} ></Column>
                    <Column field="ibs_key" style={{ width: '10em', display: "none" }} header="ibs_key"  ></Column>
                    <Column field="ins_class_type" style={{ width: '10em' }} header="Type(G/L)"   filter={true} filterMatchMode={'contains'} ></Column>
                    <Column field="action_datetime" style={{ width: '20em',textAlign:'left' }} header="Details" filterMatchMode={'contains'}  filter={true} ></Column>
                    <Column field="ibs_entry_id" style={{ width: '2em', display: "none" }} header="ibs_entry_id" filter={true} filterMatchMode={'contains'}></Column>
                    <Column header=" " style={{ width: '10em' }} body={statusBodyTemplate}></Column>
                </DataTable>
            </div>
        </div>
    );
}
export default PendingWorksGrid