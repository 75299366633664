import React, { Component } from "react";
import { Link } from "react-router-dom"
import PolicySchedule from "../../../ReportPDF/DebitNote/DebitNote"


class EntryListPayment extends Component {
  constructor(props) {
    super(props)
  
  }





  render() {

    const StatusBodyTemplate = ({ Id }) => {
      return (
        <div>
          <Link className="btn btn-primary" to={`/debit-note-print/${Id}`} target="_blank">Print</Link>
        </div>);
    }

    const DebitNote = ({ Id, Eid }) => {
      return (
        <div>
          <Link className="btn btn-info" to={`/policy/endorsement/debit/${Id}/${Eid}`} target="_blank">Edit</Link>
        </div>);
    }


    const CreditNote = ({ Id, Eid }) => {
      return (
        <div>
          <Link className="btn btn-info" to={`/policy/endorsement/credit/${Id}/${Eid}`} target="_blank">Edit</Link>
        </div>);
    }




    return (
      <div style={{ width: "100%", height: "200px", overflowY: "scroll" }}>
        {this.props.EntryList != undefined &&
          <div className="entry-row">
            {this.props.EntryList.map((item, index) => {
              return (
                <div  className="entry-row-details" onClick={()=>{this.props.onTransactionIdClicked(item.entry_id)}}>
                  <div style={{ display: "inline" }}>
                    <table width="100%" className="css-entry-list-table">
                      <tbody><tr><td>
                        <div   >{item.entry_id} </div>
                      </td>

                        <td>

                        </td>
                        <td>

                          {
                            /*
                                {(item.note_type=='C'?<CreditNote Id={item.policy_ref_no}  Eid={item.entry_id}></CreditNote>: <DebitNote Id={item.policy_ref_no}  Eid={item.entry_id}></DebitNote>)} 
                                                 
                            <StatusBodyTemplate Id={item.entry_id}  ></StatusBodyTemplate>
                            */

                          }

                        </td>
                      </tr>
                        <tr><td colSpan="3"><span className="css_e_time">{item.e_time}</span></td></tr>
                      </tbody></table>




                  </div>
                </div>
              )
            })}
          </div>
        }

    
      </div>
    )
  }
}

export default EntryListPayment

