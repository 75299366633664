import React from "react"
import Navbar from "../../../component/Navbar/Navbar"
import '../../../App.css';
import CommissionReport from './CommissionReport'

const  CommissionMainReport=()=> {

  return (  <div className="App">
     <Navbar/>

    
<div  className="report-main">
  <center>
  <h2>Commission Report</h2>

  <CommissionReport></CommissionReport>
     </center>
     </div>
     </div>
  );

}
export default CommissionMainReport;
