import React from "react"
import Navbar from "../../component/Navbar/Navbar"
import PendingWorks from "../../ibs-taskflow/PendingWork";

import '../../App.css';

const MyDashBoard = () => {

  return (<div className="App">
    <Navbar></Navbar>
   <PendingWorks></PendingWorks>
  </div>
  );

}
export default MyDashBoard;
