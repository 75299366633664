import React, { Component } from 'react'
import Select from 'react-select'



class MyComponent extends Component {
    constructor() {
        super()
    }
    handleInputChange = (newValue) => {

    };
    render() {
        const ides = this.props.selectedValue
        const length=this.props.options
        return (
            <div>
         { length!=undefined &&
            <Select
            isDisabled={this.props.isDisabled}
                className={'rct-rct-select-container'}
                options={this.props.options}
                onInputChange={this.props.comboxOnTextChange}
                onChange={(event) => this.props.hadlechangeUD(event.value, this.props.name)}
                name={this.props.name}
                value={this.props.options[this.props.options.findIndex(obj => obj.value == ides)]}
                placeholder={this.props.placeholder}
            ></Select>
        }
        </div>
        )

    }





}




export default MyComponent