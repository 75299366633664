import axios from 'axios';
import {ApiList} from '../DataOperation/apilink'


export default class InsuredDataService {

    getInsuredDataByType(requsted_data) {
        return  axios.post(ApiList.pd_md_insured_by_type, 
        requsted_data
        ).then(res => res.data.insured_data);
  
    }

    deleteInsured(requsted_data) {
        return  axios.post(ApiList.pd_md_insured_delete, 
        requsted_data
        ).then(res => res.data);
  
    }
   
}