import React, { Component } from "react"
import ComboboxV1 from "../combobox/ComboboxV1"
import axios from "axios"
import {ApiList} from "../../DataOperation/apilink"

class AgentDetails extends Component {
    constructor() {
        super()

        this.state = {
            agentData: [],
            serviceByData: []
        }
    }

    componentWillMount() {
        this.getData();
    }


    getData() {
        axios.post(ApiList.md_agent_data, {
            user_id: 1
        })
            .then((response) => {
                this.setState({
                    agentData: response.data.agentData,
                    serviceByData: response.data.serviceByData
                }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });
    }
    render() {
      
        return (
            <div className="cls-np-main-container">
                <table>
                    <tbody>
                        <tr>
                            <td>
 <div className="div_label">   Agent</div>
                            </td>
                            <td>
                            <div className="div_label_wide"> 
                            <ComboboxV1
                                            hadlechangeUD={this.props.hadlechangeUD}
                                            options={this.state.agentData}
                                            selectedValue={this.props.agent_id}
                                            name={'agent_id'}
                                        ></ComboboxV1> 
                                        </div>
                            </td>
                        </tr><tr>
                            <td>
Service By 
                            </td>
                            <td>
                            <ComboboxV1
                                            hadlechangeUD={this.props.hadlechangeUD}
                                            options={this.state.serviceByData}
                                            selectedValue={this.props.servervice_by_id}
                                            name={'agent_service_id'}
                                        ></ComboboxV1> 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

}

export default AgentDetails