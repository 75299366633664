export const MenuItems = [
    {
        tittle: 'Age Report',
        url: '/report-age',
        cName: 'nav-links'

    },
    {
        tittle: 'Age Report - Policy',
        url: '/report-policy',
        cName: 'nav-links'

    }
   
    

]