
import { API_IP } from './ApiConfig'

const SERVER_IP = API_IP.SERVER_IP;

export const ApiList =
{

    user_validate: `${SERVER_IP}/jigsawapi/user-management/validate-user-credentials`,
    policy_add: `${SERVER_IP}/ibs-gloab/policy/add-policy`,
    insurer_list: `${SERVER_IP}/api/ibs/md/insurer-list`,
    insurer_details: `${SERVER_IP}/api/ibs/md/insurer`,
    insurer_add_new: `${SERVER_IP}/api/ibs/md/insurer-create`,
    insurer_edit: `${SERVER_IP}/api/ibs/md/insurer-update`,
    insurer_inactive: `${SERVER_IP}/api/ibs/md/insurer-inactive`,
    md_policy_md: `${SERVER_IP}/api/ibs/md/policy-detail-md`,
    md_agent_data: `${SERVER_IP}/api/ibs/md/policy-detail-agent-md`,

    pd_policy_create: `${SERVER_IP}/api/ibs/pd/policy-create`,
    pd_policy_update: `${SERVER_IP}/api/ibs/pd/policy-update`,

    ///api/ibs/pd/policy-create-partial
    pd_policy_create_partial: `${SERVER_IP}/api/ibs/pd/policy-create-partial`,
    ///api/ibs/md/insured-nic-list`
    pd_md_nic_list: `${SERVER_IP}/api/ibs/md/insured-nic-list`,
    pd_md_insured_by_id: `${SERVER_IP}/api/ibs/md/insured-by-id`,
    pd_md_insured_by_id_key_value: `${SERVER_IP}/api/ibs/md/insured-by-id-key-value`,

    pd_md_insured_by_type: `${SERVER_IP}/api/ibs/md/insured-by-type`,

    pd_md_insured_update: `${SERVER_IP}/api/ibs/md/insured-update`,
    pd_md_insured_delete: `${SERVER_IP}/api/ibs/md/insured-delete`,


    pd_policy_delete: `${SERVER_IP}/api/ibs/pd/policy-delete`,

    pd_policy_data: `${SERVER_IP}/ibs-gloab/policy/policy-data`,
    pd_policy_transaction_data: `${SERVER_IP}/ibs-gloab/policy/policy-transaction-data`,

    pd_occupation_create: `${SERVER_IP}/api/ibs/md/occupation-create`,
    pd_occupation_update: `${SERVER_IP}/api/ibs/md/occupation-update`,
    pd_occupation_delete: `${SERVER_IP}/api/ibs/md/occupation-inactive`,
    pd_occupation_data_by_id: `${SERVER_IP}/api/ibs/md/occupation`,
    pd_occupation_data: `${SERVER_IP}/api/ibs/md/occupation-list`,

    pd_policy_type_create: `${SERVER_IP}/api/ibs/md/policy-type-create`,
    pd_policy_type_update: `${SERVER_IP}/api/ibs/md/policy-type-update`,
    pd_policy_type_delete: `${SERVER_IP}/api/ibs/md/policy-type-inactive`,
    pd_policy_type_data_by_id: `${SERVER_IP}/api/ibs/md/policy-type`,
    pd_policy_type_data: `${SERVER_IP}/api/ibs/md/policy-type-list`,
    pd_policy_property_data: `${SERVER_IP}/api/ibs/pd/policy-property`,


    pd_policy_class_data: `${SERVER_IP}/api/ibs/md/policy-class-list`,

    pd_agent_create: `${SERVER_IP}/api/ibs/md/agent-create`,
    pd_agent_update: `${SERVER_IP}/api/ibs/md/agent-update`,
    pd_agent_delete: `${SERVER_IP}/api/ibs/md/agent-inactive`,
    pd_agent_data_by_id: `${SERVER_IP}/api/ibs/md/agent`,
    pd_agent_data: `${SERVER_IP}/api/ibs/md/agent-list`,



    pd_agent_service_create: `${SERVER_IP}/api/ibs/md/agent-service-create`,
    pd_agent_service_update: `${SERVER_IP}/api/ibs/md/agent-service-update`,
    pd_agent_service_delete: `${SERVER_IP}/api/ibs/md/agent-service-inactive`,
    pd_agent_service_data_by_id: `${SERVER_IP}/api/ibs/md/agent-service`,
    pd_agent_service_data: `${SERVER_IP}/api/ibs/md/agent-service-list`,


    pd_policy_by_id: `${SERVER_IP}/ibs-gloab/policy/policy-data-byid`,

    pd_policy_rates: `${SERVER_IP}/api/ibs/md/policy-detail-rates`,
    pd_policy_rates_master_data: `${SERVER_IP}/api/ibs/md/policy-detail-rates-master-data`,
    pd_commision_rate_list: `${SERVER_IP}/api/ibs/md/commision-rate-list`,
    pd_commision_rate_insert: `${SERVER_IP}/api/ibs/md/commision-rate-insert`,
    pd_policy_rates_by_id: `${SERVER_IP}/api/ibs/md/commision-rate-byid`,
    pd_commision_rate_update: `${SERVER_IP}/api/ibs/md/commision-rate-update`,
    pd_policy_endorsement: `${SERVER_IP}/api/ibs/pd/policy-create-endorsement`,

    rp_premium_report: `${SERVER_IP}/api/ibs/report/premium-report`,
    rp_renewal_report: `${SERVER_IP}/api/ibs/report/renewal-report`,
    rp_ibsl_placement_report: `${SERVER_IP}/api/ibs/report/rbsl-placement-report`,

    rp_ibsl_brokers_quarterly_returns_report: `${SERVER_IP}/api/ibs/report/ibsl-brokers-quarterly-returns-report`,

    rp_premium_report_with_policy: `${SERVER_IP}/api/ibs/report/premium-report-with-policy`,
    rp_print_debit_note: `${SERVER_IP}/api/ibs/report/print-report-debit-note`,
    rp_premium_outstanding_report: `${SERVER_IP}/api/ibs/report/premium-report-outstanding`,
  



    pd_md_insured_create: `${SERVER_IP}/api/ibs/md/insured-create`,
    pd_cover_create: `${SERVER_IP}/api/ibs/md/cover-create`,
    pd_cover_update: `${SERVER_IP}/api/ibs/md/cover-update`,
    pd_cover_delete: `${SERVER_IP}/api/ibs/md/cover-inactive`,
    pd_cover_data_by_id: `${SERVER_IP}/api/ibs/md/cover`,
    pd_cover_data: `${SERVER_IP}/api/ibs/md/cover-list`,
    pd_cover_data_by_ref_id: `${SERVER_IP}/api/ibs/md/cover-list-by-ref-id`,

    wf_pending_works_for_user: `${SERVER_IP}/api/ibs/wf/pending-work-user`,
    wf_pending_works_for_user_by_task: `${SERVER_IP}/api/ibs/wf/pending-work-by-user-by-task`,
    wf_task_complete_insert: `${SERVER_IP}/api/ibs/wf/pending-work-complete-insert`,

    pd_policy_details_key_val: `${SERVER_IP}/api/ibs/pd/policy-data/get-details`,

    pp_premium_payment_receipt_update: `${SERVER_IP}/api/ibs/pd/policy-payment/premium-update`,
    pp_premium_payment_receipt_delete: `${SERVER_IP}/api/ibs/pd/policy-payment/premium-receipt-delete`,
    
    pc_commission_payment_receipt_update: `${SERVER_IP}/api/ibs/pd/policy-payment/commission-received-update`,
    pc_premium_payment_receipt_delete: `${SERVER_IP}/api/ibs/pd/policy-payment/commission-receipt-delete`,

    com_payment_update: `${SERVER_IP}/api/ibs/commission-payment/commission-payment-update`,
    pc_commission_out_payment_update: `${SERVER_IP}/api/ibs/pd/policy-payment/commission-out-paid-update`,
    pc_commission_out_payment_delete: `${SERVER_IP}/api/ibs/pd/policy-payment/commission-out-paid-delete`,
  
   pc_commission_in_upload_validator :`${SERVER_IP}/api/ibs/pd/policy-payment/commission-in-upload-validator`,
   pc_commission_in_upload_verify_post_select:`${SERVER_IP}/api/ibs/pd/policy-payment/commission-in-upload-verify-post-select`

}
