import React from "react";
import Navbar from "../../../component/Navbar/Navbar";
import PaymentDataUpload from './PaymentDataUpload'

import '../../../App.css';

const CommissionInUploadMain = () => (<div className="App">
  <Navbar></Navbar>
  <PaymentDataUpload></PaymentDataUpload>
</div>
)
export default CommissionInUploadMain;