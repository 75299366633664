import React, { Component, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios'
import { ApiList } from '../../../DataOperation/apilink'
import CoverAdd from './CoverAdd'
import Navbar from "../../../component/Navbar/NavbarMasterData"
import { Link } from "react-router-dom"
import { Modal, Container, Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import EditCover from './CoverEdit';

class CoverMain extends Component {

    constructor(props) {
        super(props)
        this.state = {
            masterdata: [],
            show: false,
            selectedId: 0
        }
        this.handleClose = this.handleClose.bind(this);
        this.getMasterDataList = this.getMasterDataList.bind(this);


    }
    componentWillMount() {
        this.getMasterDataList();
    }

    setSeletedId(id) {
        this.setState({ selectedId: id });
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    getMasterDataList() {
        axios.post(ApiList.pd_cover_data, {
            user_id: 1
        })
            .then((response) => {
                this.setState({ masterdata: response.data.data }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });
    }

    removeItem(inactive_Insurer) {
        axios.post(ApiList.pd_cover_delete, inactive_Insurer)
            .then((response) => {
                if (response.data.data != undefined) {
                    this.getMasterDataList();
                } else {
                    this.setState({
                        validate_message: response.data.error
                    })
                }
            }, (error) => {
                console.log('error', error);
            });
    }
    removeToCollection(key) {
        this.removeItem({ CoverId: key, UserId: 1 });
    }

    render() {
        const master_page_name = 'Cover';

        const statusBodyTemplate = (rowData) => {
            return (
                <div>
                    {/*
                   <Link to={`/${master_page_name.toLowerCase()}/edit/${rowData.CoverId}`}>Edit</Link>
                   */}
                    <Link onClick={() => {
                        this.setSeletedId(rowData.CoverId);
                        this.handleShow();

                    }} >Edit</Link>

                </div>);
        }

        const statusBodyTemplateView = (rowData) => {
            return (
                <div>
                    <Link to={`/${master_page_name.toLowerCase()}/details/${rowData.CoverId}`}>View</Link>
                </div>);
        }

        const statusBodyTemplateDelete = (rowData) => {
            return (
                <div>
                    <Link onClick={() => {
                        if (window.confirm('Delete the item?')) {
                            this.removeToCollection(rowData.CoverId, this)
                        };
                        //   this.handleShow();

                    }}>Delete</Link>

                </div>);
        }

        const list_table_source = this.state.masterdata;

        return (
            <div>
                <Navbar></Navbar>
                <div className="container">
                    <CoverAdd master_page_tittle={master_page_name} onListChange={this.getMasterDataList.bind(this)}></CoverAdd>
                </div>

                <div className="container">
                    <ul>
                        <div className="ig-table-container">
                            <div className="card">
                                <DataTable value={list_table_source}>
                                    <Column field="CoverId" style={{ width: '2em', display: "none" }} header="Id" filter={true}></Column>
                                    <Column field="CoverName" style={{ width: '40em' }} header="Name" filter={true}></Column>
                                    <Column header=" View" style={{ width: '2em', display: "none" }} body={statusBodyTemplateView}></Column>
                                    <Column header=" Edit " style={{ width: '10em' }} body={statusBodyTemplate}></Column>
                                    <Column header=" Delete " style={{ width: '10em' }} body={statusBodyTemplateDelete}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </ul></div>


                <Modal show={this.state.show} onHide={this.handleClose} dialogClassName="cls_modal_small_pop" >
                    <Modal.Header closeButton>
                        <Modal.Title>Policy Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditCover Id={this.state.selectedId} getMasterDataList={this.getMasterDataList}></EditCover>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default CoverMain