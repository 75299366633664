function isRequiredValidate(cssClassName) {  

    let isValid=true;
  
    Array.from(document.querySelectorAll("input,textarea")).forEach(
        (input) => {

            if(input.required){

                if( input.value=='')
                {
                    input.className=input.className+' '+cssClassName;            
                    isValid=false;
                }else{
                    input.classList.remove(cssClassName)
                }
            }
        }
    );

    return isValid;
}

exports.isRequiredValidate=isRequiredValidate