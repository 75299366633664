import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios'
import { ApiList } from '../../DataOperation/apilink'
import Button from '@material-ui/core/Button';
import './report.css'

class AgeReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
           from_date:'',to_date:''
        }

        this.exportCSV = this.exportCSV.bind(this);
    }
    componentDidMount() {
 
    }
   
    exportCSV(selectionOnly) {
        this.dt.exportCSV({ selectionOnly });
    }

    getMasterDataList() {


        axios.post(ApiList.rp_premium_report, {
            user_id: 1,
            from_date:this.state.from_date,
            to_date:this.state.to_date
        })
            .then((response) => {

                console.log("master response", response);
                this.setState({ masterdata: response.data.data }, () => {
                    console.log(this.state)
                })
            }, (error) => {
                console.log(error);
            });

    }

    removeItem(inactive_Insurer) {
        axios.post(ApiList.pd_agent_delete, inactive_Insurer)
            .then((response) => {
                if (response.data.data != undefined) {
                    console.log("update return value", response.data);
                    this.getMasterDataList();
                } else {
                    this.setState({
                        validate_message: response.data.error
                    })
                }
            }, (error) => {
                console.log('error', error);
            });
    }

    removeToCollection(key) {
        const InactiveInsurer = { pr_key: key, user_id: 1 }
        console.log(InactiveInsurer);
        this.removeItem(InactiveInsurer);
    }

    render() {




        const list_table_source = this.state.masterdata;



        return (
            <div>

            

                    <div  className="report-control">
                        <table><tr>
                            <td>Date Range</td>
                            <td>  <input
                            style={{ width: '150px', height: '35px' }}
                            type="date"
                            name="period_from"
                           // value={this.props.policydata.period_from}
                           // onChange={this.props.hadlechangeUD}
                           onChange={(e)=>{this.setState({from_date:e.target.value})}}
                           >


                        </input></td>
                            <td>     <input style={{ width: '150px', height: '35px' }} type="date"
                            name="period_to"

                            //value={this.props.policydata.period_to}

                           onChange={(e)=>{this.setState({to_date:e.target.value})}}
                            
                            ></input></td>
                            <td>  
                                 <Button variant="contained" color="primary"  style={{width:"200px"}}
                                 onClick={(e)=>{this.getMasterDataList()}}
                                 
                                 >Submit</Button></td>
                            </tr></table>
                 

                </div>


                <div className="container">
                    <ul>
                        <div className="ig-table-container">
                            <div className="card">
                            <Button type="button"  color="primary"   onClick={() => this.exportCSV(false)}  className="tb-export-button" data-pr-tooltip="CSV" >Export to CSV</Button>
                              
                                <DataTable    ref={(el) => { this.dt = el; }}      value={list_table_source} id="id_data_report" tableClassName="tbl_css_data" >
                                    <Column field="ins_class_id" style={{ width: '10em' }} header="Component" filter={true}></Column>
                                    <Column field="insurer_name" style={{ width: '40em' }} header="Insurer" filter={true}></Column>
                                    <Column field="Premium" style={{ width: '20em',textAlign:'right' }} header="Premium" filter={true}></Column>
                                    <Column field="Broker_Commission" style={{ width: '40em',textAlign:'right' }} header="Broker Commission" filter={true}></Column>
                                    <Column field="Agent_Commission" style={{ width: '20em' ,textAlign:'right'}} header="Agent Commission" filter={true}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </ul></div>



            </div>

        )
    }
}

export default AgeReport
/*

<TableToExcel

table="id_data_report"
filename="age report"
sheet="sheet 1"
buttonText="Export"

></TableToExcel>*/