import React from "react"
import Navbar from "../../component/Navbar/Navbar"
import './App.css';
import CompanyLogoAddress from '../../ibs-images/company_logo_address.jpg'

const  MainPage=()=> {

  return (  <div className="App">
     <Navbar/>

     <h1></h1>
<div>
  <center>
     <img src={CompanyLogoAddress}   alt="Logo" />
     </center>
     </div>
     </div>
  );

}
export default MainPage;
