import CompanyLogo from '../../ibs-images/company_logo.png'
import Address from './ReportAddress'

const ReportHeader = ({ CompanyData }) => {
    return (
        <div style={{ paddingRight: "100px" }}>

            <table style={{width:"100%"}}>
                <tr>

                    <td style={{width:"60%"}}> <img src={CompanyLogo}  className="css-note-logo" alt="Logo" /></td>
                    <td style={{width:"40%"}}> 
                        <Address CompanyData={CompanyData}></Address>
                    </td>
                </tr>


            </table>
            <div style={{ display: "none" }}>
                {JSON.stringify(CompanyData)}</div>

        </div>



    )


}

export default ReportHeader;