import React from "react";


const PolicyDetails=({policyData})=>{


let policy='';
let policyClass='';
let debitNote='';
let debitNoteNoFromInsurer='';//inoive no
let NoteTypeLabel='';
let insured_name='';
let insured_address_no=''
let insured_address_road=''
let insured_address_city=''
let e_time=''
let from_date=''
let to_date='', currency='',agent_name='',sum_insured='',insurer='',vehicleNo=''


if(policyData.length>0){
    policy=policyData[0].policy_no
    policyClass=policyData[0].policy_type_name
    debitNote=policyData[0].entry_id
    insured_name=policyData[0].insured_name
    insured_address_no=policyData[0].insured_address_no
    insured_address_road=policyData[0].insured_address_road
    insured_address_city=policyData[0].insured_address_city
    e_time=policyData[0].e_time
    from_date=policyData[0].from_date
    to_date=policyData[0].to_date
    currency=policyData[0].currency
    agent_name=policyData[0].agent_name
    sum_insured=policyData[0].sum_insured
    debitNoteNoFromInsurer=policyData[0].invoice_no
    NoteTypeLabel=policyData[0].NoteTypeLabel
    insurer=policyData[0].insurer_name
    vehicleNo=policyData[0].vehicle_no
}




    return (
        <div className="body-section-one">
        <table >
            <tbody>
            <tr>
                <td rowSpan={2} className="css-important-text" style={{width:"40%"}}>{policyClass}</td>
                <td className="css-important-text"  style={{width:"20%"}}> Policy No


                </td>
                <td   style={{width:"40%"}}>{policy}</td>
            </tr>
            <tr>

                <td className="css-important-text">{NoteTypeLabel}</td>
                <td>{debitNoteNoFromInsurer}</td>


            </tr>
</tbody>

        </table>
        <br></br>
        <table >
            <tr>
                <td rowSpan={4} style={{width:"40%"}}>
                    <div className="css-important-text"> Name and Address of the Policy Holder</div>
                    <div>{insured_name}</div>
                    <div>{insured_address_no}</div>
                    <div>{insured_address_road}</div>
                    <div>{insured_address_city}</div>
                    </td>
                <td className="css-important-text" style={{width:"20%"}}> Date</td>
                <td style={{width:"40%"}}>{e_time}</td>
            </tr>
            <tr>

                <td className="css-important-text">Period of Cover</td>
                <td>{from_date} - {to_date}  </td>


            </tr>
            <tr>

                <td className="css-important-text">Vehicle No</td>
                <td>{vehicleNo}  </td>


            </tr>
           


        </table>
        <br></br>
        <table><tr><td className="css-important-text" style={{width:"40%"}}>Insurance Company</td>
        <td>{insurer}</td>
        </tr></table>

        <br></br>
        <table><tr><td className="css-important-text" style={{width:"40%"}}>Sum Insured</td>
        <td className="css_text_align_right">{sum_insured}</td>
        </tr></table>
        <div className="css-dbnote-currance">Amount in {currency}</div>
        <br></br>
    </div>


    )



}


export default PolicyDetails;