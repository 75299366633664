import React, { Component } from "react"
import ComboboxV1 from '../../component/combobox/ComboboxV1'
import NumericTextBox from "react-numeric-textbox"
import '../policy/policybasic.css'

class InvoiceDetails extends Component {
    constructor() {
        super()
       
    }

    render() {
        const currancyData = [
            { value: 'LKR', label: 'LKR' },
            { value: 'USD', label: 'USD' },
            { value: 'AUD', label: 'AUD' }
        ]
    

        return (
            <div>
               
                    <div className="cls-np-main-container">
                        <table className="table-pd"> 
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="div_label">   Currency</div>
                                     
                                    </td>
                                    <td>

                                        <ComboboxV1
                                            hadlechangeUD={this.props.hadlechangeUD}                                           
                                            options={currancyData}
                                            selectedValue={this.props.data.currency}
                                            name={'currency'}
                                        ></ComboboxV1>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        Sum Insured
                                    </td>
                                    <td>
                                 
                                    <NumericTextBox 
                                            onChange={(event) => this.props.hadlechangeUD(event, 'sum_insured')} 
                                             name="sum_insured"
                                             value={this.props.data.sum_insured}
                                            className="input-numeric ibsinput"> 
                                        
                                        </NumericTextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                      Invoice Number
                                    </td>
                                    <td>

                                        <input 
                                            name={'invoice_no'}
                                            className="ibsinput"
                                            type="text"
                                            placeholder="Insurer's Invoice No"
                                            onChange={(event) => this.props.hadlechangeUD(event, 'invoice_no')} 
                                            value={this.props.data.invoice_no}
                                            />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

             
            </div>
        )
    }
}


export default InvoiceDetails