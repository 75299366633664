export const MenuItems = [
    {
        tittle: 'Age Report',
        url: '/report-policy',
        cName: 'nav-links bar-alert',
        color:'primary'

    },
    {
        tittle: 'IBSL Reports',
        url: '/report/ibsl/main',
        cName: 'nav-links bar-alert',
        color:'primary'

    },
    {
        tittle: 'Outstanding Report',
        url: '/report/premium/outstanding',
        cName: 'nav-links bar-alert',
        color:'secondary'

    },
    {
        tittle: 'Renewal Report',
        url: '',
        cName: 'nav-links bar-alert',
        color:'primary'

    },
    {
        tittle: 'Commission Report',
        url: '/report/commission/commission-report',
        cName: 'nav-links bar-alert',
        color:'primary'

    },
    {
        tittle: 'Pending Doc Report',
        url: '',
        cName: 'nav-links bar-alert',
        color:'primary'

    }
///report-age
]

