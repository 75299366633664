import React, { Component } from 'react'
import ButtonNavigator from "../../component/TabController/ButtonNavigator"
import InsuredList from "./InsuredSearchPopup"
import Navbar from "../../component/Navbar/NavbarMasterData"
class InsuredListMain extends Component {

    constructor(props) {
        super(props);
        this.onClickRow = this.onClickRow.bind(this);
    }

    onClickRow(value){

       let locationUrl=(this.props.insuredType=="I"?"/insured/individual":"/insured/cooperate")
        window.location=`${locationUrl}-edit/${value}`
    }



    render() {
        return (

            <div>
                <Navbar></Navbar>          


                <div className="container">
                    <ul>
                        <div className="ig-table-container">
                            <div className="card">
                                <InsuredList insuredType={this.props.insuredType} handleModal={this.onClickRow} sourcePage={'Main'}></InsuredList>
                            </div>
                        </div>
                    </ul></div>
            </div>

        )
    }
}


export default InsuredListMain;