import React from "react"
import Navbar from "../../component/Navbar/Navbar"
import './App.css';

const  ControlPage=()=> {

  return (  <div className="App">
     <Navbar/>
     <h1>Main Control Page</h1>
     </div>
  );

}
export default ControlPage;
