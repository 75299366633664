import React, { Component, useState } from "react"
import axios from "axios"
import { ApiList } from "../../../DataOperation/apilink"


class AddCover extends Component {

    constructor(props) {
        super(props)
        this.state = { CoverName: '', validate_message: '' }
        this.onChange = this.onChange.bind(this);

    }
    onChange(e, val) {
        if (e.target != null && e.target.name) {
            this.setState({
                [e.target.name]: e.target.value,
                validate_message: (e.target.value.length > 0 ? '' : '*'),
                UserId: 1
            })
        } else {
            this.setState({
                [val]: e, validate_message: (val.length > 0 ? '' : '*'),
                UserId: 1
            })
        }
    }

    addMasterData(newMasterObj, e) {
        axios.post(ApiList.pd_cover_create, newMasterObj)
            .then((response) => {
                if (response.data.data != undefined) {
                    this.props.onListChange();
                    e.target.reset();
                } else {
                    this.setState({
                        validate_message: response.data.error
                    })
                }
            }, (error) => {
                console.log('error', error);
            });
    }



    onSubmit(e) {

        if (this.state.CoverName.length > 0) {
            this.addMasterData(this.state, e);
        }
        else {
            this.setState({
                validate_message: 'Fill required fields'
            })
        }
        e.preventDefault();

    }


    render() {
        return (
            <div>
                <form onSubmit={this.onSubmit.bind(this)}>
                    <li className="list-group-item">
                        <div className="container">
                            <div className="row">
                                <div col="col">
                                    <span>{this.state.validate_message}</span>
                                </div>

                            </div>
                            <div className="row">
                                <div className="master-data-page-tittle"> <span>Add New {this.props.master_page_tittle}</span></div>
                            </div>
                            <div className="master-data-page-body">
                                <div className="row">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td> <label >Cover</label></td>
                                                <td>     <input
                                                    type="text" onChange={this.onChange}
                                                    name="CoverName" ref="CoverName"
                                                    placeholder="Name"
                                                    id="txt_CoverName"></input>
                                                </td>
                                                <td>    <button type="submit" value="save" className="btn btn-info">(+)Add</button></td>
                                                <td> <label ></label></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </li>
                </form>
            </div>
        )

    }



}

export default AddCover