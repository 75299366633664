export const PropertyList = [
    {
        Label: 'Make',
        ControlName:'Make',
        Type:'Text'

    },
    {
        Label: 'Model',
        ControlName:'Model',
        Type:'Text'

    }
    ,
    {
        Label: 'Engine No',
        ControlName:'EngineNo',
        Type:'Text'

    },
    ,
    {
        Label: 'Chassis no',
        ControlName:'ChassisNo',
        Type:'TextNumber'

    },
    {
        Label: 'Vehicle type',
        ControlName:'Vehicletype',
        Type:'Text'

    },
    {
        Label: 'Year of manufacture ',
        ControlName:'YearOfManufacture',
        Type:'TextNumber'

    }
    ,
    {
        Label: 'Covers',
        ControlName:'Covers',
        Type:'Text'

    },
    ,
    {
        Label: 'Remark',
        ControlName:'Remark',
        Type:'TextNumber'

    }

   

]

