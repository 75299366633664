import axios from 'axios';
import { ApiList } from '../DataOperation/apilink'


export default class TaskFlowService {


    getPendingWorks(requsted_data) {
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };
        return axios.post(ApiList.wf_pending_works_for_user, request_json).then(res => res.data.data);
    }
    getPendingWorkForUserByTask(requsted_data) {
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };

        console.log("requsted_data",requsted_data);
        return axios.post(ApiList.wf_pending_works_for_user_by_task, request_json).then(res => res.data.data);
    }
    setTaskComplete(requsted_data) {
        let request_json = { ...requsted_data, user_index: localStorage.getItem("UserIndex") };
        return axios.post(ApiList.wf_task_complete_insert, request_json).then(res => res.data.data);
    }
    
}

