import React from "react"
import Navbar from "../../../component/Navbar/Payment/PaymentMenu"
import '../../../App.css'


const  PaymentMainPage=()=> {

  return (  <div className="App">
     <Navbar/>




     </div>
  );

}
export default PaymentMainPage;
