import React from "react";

const ReportFooter=( {CompanyData,fileName})=>{


    let footerLine1='',footerLine2='',footerLine3='',footerLine4=''
    if(CompanyData.length>0){

        footerLine1=CompanyData[0].footerLine1;
        footerLine2=CompanyData[0].footerLine2;
        footerLine3=CompanyData[0].footerLine3;
       // footerLine4=CompanyData[0].Name;
    
     }
  

return (
    <div className="css-debit-note-footer">
        <div>
           
        </div>
        <div>
        {footerLine1}<br></br>
        </div>
        <div>
        {footerLine2}<br></br>
        </div>

        <div>
       
      
        {footerLine3}<br></br>
        </div>
      <div className="css-dbnote-refno">Ref No : {fileName}</div>
    </div>
)
}


export default ReportFooter;